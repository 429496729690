import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItem, Money } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { DialogPopup, DialogWrapper } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { CartMath } from '@lego/b2b-unicorn-ui-utils';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { SummaryPrice } from '../SummaryPrice';

const summaryBackgroundStyle = (loading: boolean) =>
  css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: loading ? designToken.skeleton.secondary : designToken.background.blue,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 8,
    minHeight: 160,
    paddingTop: baseSpacing * 2,
    paddingRight: baseSpacing * 4,
    paddingBottom: baseSpacing * 2,
    paddingLeft: baseSpacing * 4,
  });

const summaryStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: 0,
  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  '> button': {
    width: '100%',

    [media.medium]: {
      minWidth: 160,
      maxWidth: 200,
    },
  },
});

const valuesContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  [media.medium]: {
    justifyContent: 'center',
  },
});

const summaryItemStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  color: designToken.text.default,
  marginTop: 0,
  marginBottom: 0,
  marginRight: baseSpacing,
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  [media.small]: {
    marginRight: baseSpacing * 2,
  },
  [media.medium]: {
    marginRight: baseSpacing * 2.5,
  },

  '> span:first-of-type': {
    fontSize: font.size.tiny,
    fontWeight: font.weight.normal,
    [media.small]: {
      fontSize: font.size.small,
    },
    [media.medium]: {
      marginRight: baseSpacing,
    },
  },
  '> span:nth-of-type(2)': {
    fontWeight: font.weight.bold,
    lineHeight: 1,
    marginTop: baseSpacing,
    [media.small]: {
      marginTop: 0,
    },
  },
});

const subHeaderStyle = css(summaryItemStyle, {
  [media.medium]: {
    maxWidth: '20%',
  },
});

const totalAmountStyle = css({
  color: designToken.text.muted,
  fontWeight: font.weight.bold,
  lineHeight: 1,
  marginTop: baseSpacing,
  [media.small]: {
    marginTop: 0,
  },

  '> span:first-of-type': {
    fontSize: font.size.tiny,
    fontWeight: font.weight.normal,
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    marginTop: 2,
    padding: 0,
    display: 'block',
    '> span': {
      display: 'inline-block',
      zIndex: 10,
      transform: 'translateY(3px)',
    },
  },
});

const warningAmountStyle = css(totalAmountStyle, {
  color: designToken.warning.default,
  '> span': {
    color: designToken.text.muted,
  },
});

const dialogPopupContentStyle = css({
  fontSize: font.size.small,
  marginRight: 20,
});

const infoIconStyle = css({
  height: 14,
  width: 14,
  marginLeft: baseSpacing / 2,
  [media.small]: {
    marginTop: -4,
  },

  '> svg': {
    height: 14,
    width: 14,
    fill: tokens.color.core.gray[700],
  },
});

const warningStyle = css({
  color: designToken.warning.default,
  fontSize: font.size.tiny,
  fontWeight: font.weight.bold,
  margin: 0,
});

interface OrderPreviewSummaryProps {
  children: React.ReactNode;
  minimumOrderValue: Money;
  cartItems: CartItem[];
  loading?: boolean;
}

const OrderPreviewSummary: React.FC<OrderPreviewSummaryProps> = ({
  children,
  minimumOrderValue,
  cartItems,
  loading = false,
}) => {
  const selectedCustomer = useSelectedCustomer();

  const sumPrice = CartMath.sumPrice(cartItems);
  const sumGrossPrice = CartMath.sumGrossPrice(cartItems, true);
  const sumNetPrice =
    CartMath.sumNetInvoicedPrice(cartItems, true) ||
    CartMath.sumEstimatedNetInvoicedPrice(cartItems);
  const isMinValueMet = sumNetPrice.netInvoicedPrice
    ? sumNetPrice.netInvoicedPrice > minimumOrderValue.amount
    : sumGrossPrice.grossPrice > minimumOrderValue.amount;

  const sumCases = CartMath.sumCases(cartItems);
  const sumUnits = CartMath.sumUnits(cartItems);

  const {
    order_summary,
    total_cases,
    total_pieces,
    total_order_price,
    reference_price,
    reference_price_dialog_content,
    total_order_price_minimum_value,
  } = useLabels();

  const renderMinOrderValue = () => (
    <Price
      locale={selectedCustomer.locale}
      fallbackValue={''}
    >
      {minimumOrderValue}
    </Price>
  );

  const renderInitialPrice = () => {
    return (
      <div
        css={
          isMinValueMet ||
          sumPrice.grossPrice === 0 ||
          sumPrice.estimatedNetInvoicedPrice === 0 ||
          sumPrice.netInvoicedPrice === 0
            ? totalAmountStyle
            : warningAmountStyle
        }
      >
        <SummaryPrice locale={selectedCustomer.locale}>{sumPrice}</SummaryPrice>
        {selectedCustomer.showSimulationDetails && (
          <span>
            {reference_price}
            <DialogWrapper styles={infoIconStyle}>
              <Icon type={IconType.INFO} />
              <DialogPopup
                icon
                direction="bottom"
              >
                <p css={dialogPopupContentStyle}>{reference_price_dialog_content}</p>
              </DialogPopup>
            </DialogWrapper>
          </span>
        )}
        {(sumPrice.grossPrice !== 0 ||
          sumPrice.netInvoicedPrice !== 0 ||
          sumPrice.estimatedNetInvoicedPrice !== 0) &&
          !isMinValueMet && (
            <p css={warningStyle}>
              {total_order_price_minimum_value} {renderMinOrderValue()}
            </p>
          )}
      </div>
    );
  };

  return (
    <section css={summaryBackgroundStyle(loading)}>
      <div css={summaryStyle}>
        {!loading && (
          <>
            <p css={subHeaderStyle}>{order_summary}</p>
            <div css={[valuesContainerStyle]}>
              <div css={summaryItemStyle}>
                <span>{total_cases}</span>
                <span>{sumCases}</span>
              </div>
              <div css={summaryItemStyle}>
                <span>{total_pieces}</span>
                <span>{sumUnits}</span>
              </div>
              <div css={summaryItemStyle}>
                <span>{total_order_price}</span>
                {renderInitialPrice()}
              </div>
            </div>
            {children}
          </>
        )}
      </div>
    </section>
  );
};

export default OrderPreviewSummary;
