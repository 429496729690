import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';

export const summaryBackgroundStyle = (open: boolean) =>
  css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    backgroundColor: designToken.background.blue,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 8,
    height: open ? 380 : 80,
    paddingTop: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
    paddingBottom: baseSpacing * 4,
    paddingLeft: baseSpacing * 2,
    input: {
      width: '100%',
    },
    [media.small]: {
      paddingRight: baseSpacing * 6,
      paddingLeft: baseSpacing * 6,
    },
    [media.medium]: {
      height: 380,
    },
  });

export const paymentBackgroundStyle = (open: boolean) =>
  css(summaryBackgroundStyle(open), {
    backgroundColor: 'transparent',
    [media.medium]: {
      paddingLeft: 0,
    },
  });

export const skeletonSummaryStyle = css({
  height: 380,
});

export const totalAmountStyle = css({
  color: designToken.text.muted,
  fontWeight: font.weight.bold,
  lineHeight: 1,
});
