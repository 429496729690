import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { tokens } from '@lego/core-colors';
import { useHistory } from 'react-router-dom';

import LogoGraphic from './LogoGraphic';

const Logo = () => {
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const handleOnClick = () => {
    history.push('/');
    trackEvent({
      event: 'clickOnMainBrandLogo',
      name: 'User clicked main brand logo',
    });
  };

  return (
    <button
      css={css({
        color: designToken.text.inverse,
        '&:hover, :focus': {
          color: tokens.color.core.azur['100'],
          outline: 'none',
        },
      })}
      onClick={handleOnClick}
    >
      <LogoGraphic />
    </button>
  );
};

export default Logo;
