import React from 'react';

import {
  ProductFilterSelectors,
  ProductListContainer,
  ProductListFilterTags,
  ProductListSorting,
  RareItemsSwitch,
  RecommendedProductsSwitch,
} from './';
import { sortingAndSwitchWrapperStyle, switchWrapperStyle } from './styles';

export const ProductListFiltersAndSorting: React.FC = () => {
  return (
    <>
      <ProductListContainer>
        <ProductFilterSelectors />
        <ProductListFilterTags />
      </ProductListContainer>
      <ProductListContainer>
        <div css={sortingAndSwitchWrapperStyle}>
          <div css={switchWrapperStyle}>
            <RecommendedProductsSwitch />
            <RareItemsSwitch />
          </div>
          <ProductListSorting />
        </div>
      </ProductListContainer>
    </>
  );
};
