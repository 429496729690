import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { formatCurrency } from '@lego/b2b-unicorn-shared/helpers';
import { AvailabilityStatusType } from '@lego/b2b-unicorn-shared/ui';
import { ClearAllTag, FilterTag } from '@lego/b2b-unicorn-ui-components';

import { FilterType, LifecycleStatus } from '../../../../../../../constants';
import { useProductListContext } from '../../../ProductListContext';

export const ProductListFilterTags = () => {
  const selectedCustomer = useSelectedCustomer();
  const {
    available,
    not_available,
    low_stock,
    out_of_stock,
    next_possible_delivery,
    coming_soon,
    new: newLabel,
    retiring_soon,
    retired,
    carry_over,
    filter_clear_all,
    filters_no_theme,
  } = useLabels();
  const { filtersList, selectedFilters, setSelectedFilters, removeAllTags } =
    useProductListContext();

  const tagCount = selectedFilters
    ? (selectedFilters.query ? 1 : 0) +
      (selectedFilters.availabilities ? selectedFilters.availabilities.length : 0) +
      (selectedFilters.lifecycles ? selectedFilters.lifecycles.length : 0) +
      (selectedFilters.priceRanges ? selectedFilters.priceRanges.length : 0) +
      (selectedFilters.themes ? selectedFilters.themes.length : 0)
    : 0;

  const getTagName = (key: string) => {
    return {
      [AvailabilityStatusType.AVAILABLE]: available,
      [AvailabilityStatusType.NOT_AVAILABLE]: not_available,
      [AvailabilityStatusType.LOW_STOCK]: low_stock,
      [AvailabilityStatusType.NEXT_POSSIBLE_DELIVERY]: next_possible_delivery,
      [AvailabilityStatusType.OUT_OF_STOCK]: out_of_stock,
      [LifecycleStatus.COMING_SOON]: coming_soon,
      [LifecycleStatus.NEW]: newLabel,
      [LifecycleStatus.RETIRING_SOON]: retiring_soon,
      [LifecycleStatus.RETIRED]: retired,
      [LifecycleStatus.CARRY_OVER]: carry_over,
    }[key];
  };

  const removeQueryTag = () => {
    const updatedFilters = { ...selectedFilters };
    delete updatedFilters.query;
    setSelectedFilters(updatedFilters);
  };

  const removeThemeTag = (value: string) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.themes = updatedFilters?.themes?.filter((theme) => theme !== value);
    !updatedFilters.themes?.length && delete updatedFilters.themes;
    setSelectedFilters(updatedFilters);
  };

  const removePriceRangeTag = (value: string) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.priceRanges = updatedFilters?.priceRanges?.filter(
      (priceRange) => priceRange !== value
    );
    !updatedFilters.priceRanges?.length && delete updatedFilters.priceRanges;
    setSelectedFilters(updatedFilters);
  };

  const removeAvailabilityTag = (value: string) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.availabilities = updatedFilters?.availabilities?.filter(
      (availability) => availability !== value
    );
    !updatedFilters.availabilities?.length && delete updatedFilters.availabilities;
    setSelectedFilters(updatedFilters);
  };

  const removeLifecycleTag = (value: string) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.lifecycles = updatedFilters?.lifecycles?.filter(
      (lifecycle) => lifecycle !== value
    );
    !updatedFilters.lifecycles?.length && delete updatedFilters.lifecycles;
    setSelectedFilters(updatedFilters);
  };

  return selectedFilters ? (
    <div>
      {tagCount > 0 && (
        <ClearAllTag
          onClickHandler={removeAllTags}
          text={filter_clear_all}
        />
      )}
      {selectedFilters.query && (
        <FilterTag
          onClickHandler={removeQueryTag}
          text={selectedFilters.query}
          value={selectedFilters.query}
          option={FilterType.QUERY}
          key={selectedFilters.query}
        />
      )}
      {selectedFilters.themes?.map((theme) => {
        return (
          <FilterTag
            onClickHandler={() => removeThemeTag(theme)}
            text={theme || filters_no_theme}
            value={theme}
            option={FilterType.THEMES}
            key={theme}
          />
        );
      })}
      {selectedFilters.priceRanges?.map((priceRange, index) => {
        const priceRangeItem = filtersList[FilterType.PRICE_RANGES].find(
          (item) => item.id === priceRange
        );
        const tagText = priceRangeItem
          ? `${formatCurrency(
              selectedCustomer.locale,
              priceRangeItem?.from,
              selectedCustomer.currency || ''
            )} - ${
              priceRangeItem?.to
                ? formatCurrency(
                    selectedCustomer.locale,
                    priceRangeItem?.to,
                    selectedCustomer.currency || ''
                  )
                : '*'
            } `
          : '';

        return (
          <FilterTag
            onClickHandler={() => removePriceRangeTag(priceRange)}
            text={tagText}
            value={priceRange}
            option={FilterType.PRICE_RANGES}
            key={index}
          />
        );
      })}
      {selectedFilters.availabilities?.map((availability) => {
        return (
          <FilterTag
            onClickHandler={() => removeAvailabilityTag(availability)}
            text={getTagName(availability) || ''}
            value={availability}
            option={FilterType.AVAILABILITIES}
            key={availability}
          />
        );
      })}
      {selectedFilters.lifecycles?.map((lifecycle) => {
        return (
          <FilterTag
            onClickHandler={() => removeLifecycleTag(lifecycle)}
            text={getTagName(lifecycle) || ''}
            value={lifecycle}
            option={FilterType.LIFECYCLES}
            key={lifecycle}
          />
        );
      })}
    </div>
  ) : null;
};
