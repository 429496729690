import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

export const pagePaddingStyle = {
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
};

export const pagePaddingDesktopStyle = {
  [media.small]: {
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
  },
};

export const commonBodyStyle = {
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: baseSpacing * 4,
  maxWidth: maxDesktopWidth,
  color: designToken.text.default,
  fontSize: font.size.small,
  [media.medium]: {
    marginBottom: 120,
  },
};

export const commonSubHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 80,
  colors: designToken.text.default,
  fontSize: font.size.medium,
  fontWeight: font.weight.normal,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: baseSpacing * 2,
  [media.medium]: {
    margin: 0,
  },
};

export const labelTextStyle = css({
  fontSize: font.size.small,
  fontWeight: font.weight.normal,
  margin: 0,
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 3,
  [media.medium]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const bodyStyle = css(commonBodyStyle, {
  display: 'flex',
  flexDirection: 'column',

  [media.medium]: {
    display: 'block',
  },
});

export const formWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column-reverse',
  [media.medium]: {
    flexDirection: 'row',
    width: '100%',
    gap: baseSpacing * 4,
    '> *': {
      minWidth: 500,
    },
  },
});

export const actionsStyle = css({
  width: '100%',
  '> button': {
    marginBottom: baseSpacing,
  },
  '> span': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.3,
    fontSize: font.size.tiny,
    '> svg': {
      minHeight: baseSpacing * 3,
      minWidth: baseSpacing * 3,
      height: baseSpacing * 3,
      width: baseSpacing * 3,
      marginRight: baseSpacing,
    },
  },
  [media.medium]: {
    '> span': {
      fontSize: font.size.small,
      '> svg': {
        minHeight: baseSpacing * 4,
        minWidth: baseSpacing * 4,
        height: baseSpacing * 4,
        width: baseSpacing * 4,
      },
    },
  },
});

export const submittingAnimationStyle = css({
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  color: tokens.color.core.azur[800],
  columnGap: baseSpacing / 2,
  alignItems: 'baseline',
});

export const mobileCollapseStyle = (isOpen: boolean) =>
  css({
    display: isOpen ? 'block' : 'none',
    width: '100%',
    [media.medium]: {
      display: 'block',
    },
  });
