export enum StockStatusTag {
  Available = 'AVAILABLE',
  NextPossibleDelivery = 'NEXT_POSSIBLE_DELIVERY',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE',
  OutOfStock = 'OUT_OF_STOCK',
}

export interface IStockStatus {
  status: StockStatusTag;
  nextPossibleDeliveryDate?: string | null;
}
