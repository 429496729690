import { css } from '@emotion/react';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { CartReferenceCartType, PaymentMethod } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { CheckoutPage, CheckoutStep } from '@lego/b2b-unicorn-ui-checkout-flow';
import { createDocumentTitle, GoToTop } from '@lego/b2b-unicorn-ui-components';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ReplenishmentCartPreview from '../components/ReplenishmentCartPreview/ReplenishmentCartPreview';
import { DOCUMENT_TITLE } from '../constants';
import paymentState from '../utils/paymentState';

const backgroundStyle = css({
  color: colors.klik.slate700,
  width: '100%',
  height: '100%',
});

const CheckoutFlowLayout = () => {
  const selectedCustomer = useSelectedCustomer();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const { startTransaction, endTransaction } = useApmTransaction();
  const { customTrackPageView } = useAnalytics();

  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.PREVIEW);
  const history = useHistory();

  useEffect(() => {
    let currentDocumentTitle = document.title;
    switch (checkoutStep) {
      case CheckoutStep.PREVIEW:
        currentDocumentTitle = createDocumentTitle([
          DOCUMENT_TITLE.CART.PREVIEW,
          DOCUMENT_TITLE.MAIN,
        ]);
        break;
      case CheckoutStep.CHECKOUT:
        currentDocumentTitle = createDocumentTitle([
          DOCUMENT_TITLE.CART.CHECKOUT,
          DOCUMENT_TITLE.MAIN,
        ]);
        break;
      case CheckoutStep.CONFIRMATION:
        currentDocumentTitle = createDocumentTitle([
          DOCUMENT_TITLE.CART.ORDER_CONFIRMATION,
          DOCUMENT_TITLE.MAIN,
        ]);
        break;
    }

    if (currentDocumentTitle !== document.title) {
      document.title = currentDocumentTitle;
    }
    customTrackPageView(currentDocumentTitle);
    startTransaction && startTransaction();
  }, [checkoutStep, customTrackPageView, startTransaction]);

  const handleOnOrderCreationSuccessHandler = (orderNumber: number) => {
    const confirmationPath = `/checkout/${orderNumber}/confirmation`;
    const state = paymentState(confirmationPath, orderNumber, selectedCustomer.id);
    if (userPaysWithCard) {
      GlobalLoadingService.add('checkout-redirect');
      setCheckoutStep(CheckoutStep.PAYMENT);
      setTimeout(() => (window.location.href = `/payment/${orderNumber}?state=${state}`));
    } else {
      history.replace(confirmationPath);
    }
  };

  const handleOnLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Replenish,
    }),
    []
  );

  return (
    <Fragment>
      <Topbar>
        <Logo />
      </Topbar>
      <div css={backgroundStyle}>
        {checkoutStep == CheckoutStep.PREVIEW && (
          <ReplenishmentCartPreview
            onGoToCheckoutClick={() => setCheckoutStep(CheckoutStep.CHECKOUT)}
            onCartLoaded={handleOnLoaded}
          />
        )}
        {checkoutStep == CheckoutStep.CHECKOUT && (
          <CheckoutPage
            goBackHandler={() => setCheckoutStep(CheckoutStep.PREVIEW)}
            orderCreationSuccessHandler={handleOnOrderCreationSuccessHandler}
            cookieDomain={COOKIE_DOMAIN}
            cartReference={cartReference}
            onCheckoutLoaded={handleOnLoaded}
          />
        )}
      </div>
      <GoToTop />
    </Fragment>
  );
};

export default CheckoutFlowLayout;
