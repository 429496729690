import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ContentSystemFeedback } from '@lego/b2b-unicorn-shared/ui';
import { GoToTop, LoadMoreButton } from '@lego/b2b-unicorn-ui-components';
import React, { useCallback } from 'react';

import { useProductListContext } from '../../ProductListContext';
import { ProductListFiltersAndSorting, ProductListTile, SkeletonProductList } from './';
import {
  loadButtonMediaAdjustmentStyle,
  productListContainerStyle,
  productListStyle,
  wrapperStyle,
} from './styles';

export const ProductList: React.FC = () => {
  const selectedCustomer = useSelectedCustomer();
  const locale = selectedCustomer?.locale;

  const { content_system_feedback_no_data, button_load_more } = useLabels();
  const {
    productsResponse,
    productsLoading,
    productListItems,
    loadMoreVisible,
    materialIdMarker,
    loadMore,
  } = useProductListContext();

  const scrollRestorationRef = useCallback((node: null | HTMLDivElement) => {
    if (node !== null) {
      // If the user navigated back from PDP, scroll down to that product
      node.scrollIntoView({ block: 'center' });
    }
  }, []);

  return (
    <>
      <div css={wrapperStyle}>
        <ProductListFiltersAndSorting />
        <div css={productListContainerStyle(loadMoreVisible)}>
          {productsLoading && <SkeletonProductList />}
          {productsResponse &&
            !productsLoading &&
            (productListItems.length > 0 ? (
              <div css={productListStyle}>
                {productListItems.map((product) => {
                  return (
                    <ProductListTile
                      key={product.materialId}
                      product={product}
                      locale={locale}
                      onRendered={
                        product.materialId === materialIdMarker ? scrollRestorationRef : undefined
                      }
                    />
                  );
                })}
              </div>
            ) : (
              <ContentSystemFeedback text={content_system_feedback_no_data} />
            ))}
          {loadMoreVisible && (
            <LoadMoreButton
              handleLoadMoreClick={loadMore}
              text={button_load_more}
              customStyle={loadButtonMediaAdjustmentStyle}
            />
          )}
        </div>
        {productsResponse && <GoToTop loadMoreVisible={loadMoreVisible} />}
      </div>
    </>
  );
};
