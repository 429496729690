import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { SelectBox } from '@lego/b2b-unicorn-shared/ui';
import React, { useEffect, useState } from 'react';

import { useProductListContext } from '../../../ProductListContext';
import { ITheme } from '../../../types';
import { inputContainerStyle } from '../styles';

const sortThemes = (a: { title: string }, b: { title: string }) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }

  return 0;
};

const sortedFlatArrayOfThemesAndSubthemes = (themes: ITheme[]) => {
  const all: ITheme[] = [];
  [...themes].sort(sortThemes).forEach((theme) => {
    all.push(theme);
    if (theme.subThemes) {
      [...theme.subThemes].sort(sortThemes).forEach((subTheme) => {
        all.push(subTheme);
      });
    }
  });
  return all;
};

const areAllSubThemesSelected = (theme: ITheme, filters: string[] | undefined) => {
  if (filters && theme.subThemes && theme.subThemes.length > 0) {
    return theme.subThemes.every((subTheme) => filters.includes(subTheme.title));
  }
};

export const ThemesFilter: React.FC = () => {
  const { filter_themes, filters_no_theme } = useLabels();
  const { filtersList, selectedFilters, setSelectedFilters } = useProductListContext();

  const [allThemes, setAllThemes] = useState<ITheme[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<ITheme[]>([]);

  useEffect(() => {
    /** Create list of all themes for the select box. */
    const createValues = () => {
      const values = sortedFlatArrayOfThemesAndSubthemes(filtersList?.themes);
      setAllThemes(values);
    };
    if (filtersList?.themes) {
      createValues();
    }
  }, [filtersList]);

  useEffect(() => {
    /** Create list of selected themes based on the filters. */
    const selected = allThemes.filter((theme) => {
      return (
        selectedFilters?.themes?.includes(theme.title) ||
        areAllSubThemesSelected(theme, selectedFilters?.themes)
      );
    });
    setSelectedThemes(selected);
  }, [selectedFilters, allThemes]);

  const handleThemesFilterChange = (themes: string[]) => {
    let updatedFilters = { ...selectedFilters };
    if (themes.length) {
      updatedFilters = {
        ...selectedFilters,
        themes: themes as string[],
      };
    } else {
      delete updatedFilters.themes;
    }
    setSelectedFilters(updatedFilters);
  };

  const handleThemeSelectionChange = (themes: ITheme[]) => {
    let updatedThemes = [...themes];
    const markedForRemoval: ITheme[] = [];
    updatedThemes.forEach((theme) => {
      if (theme.subThemes && theme.subThemes?.length > 0) {
        if (!selectedThemes.some((seletedTheme) => seletedTheme.title === theme.title)) {
          updatedThemes.push(
            ...theme.subThemes.filter((subTheme) => !selectedThemes.includes(subTheme))
          );
        }
        if (theme.subThemes) {
          markedForRemoval.push(theme);
        }
      }
    });
    const parentThemeThatWasJustRemoved = selectedThemes.find((theme) => {
      return !updatedThemes.includes(theme) && theme.subThemes && theme.subThemes?.length > 0;
    });
    if (parentThemeThatWasJustRemoved) {
      /* When removing a parent theme, also remove all subthemes: */
      markedForRemoval.push(...(parentThemeThatWasJustRemoved?.subThemes as ITheme[]));
    }
    updatedThemes = updatedThemes.filter((theme) => !markedForRemoval.includes(theme));
    handleThemesFilterChange(updatedThemes.map((theme) => theme.title));
  };

  return (
    <div css={inputContainerStyle}>
      <SelectBox
        label={filter_themes}
        values={allThemes.map((option) => {
          const hitsCount = `[${option.hitsCount}]`;
          return {
            displayText: `${option.title || filters_no_theme} ${
              option.hitsCount > 0 ? hitsCount : ''
            }`,
            value: option,
            indent: option.__typename === 'SubThemeFacet' ? 1 : 0,
          };
        })}
        selectedValues={selectedThemes}
        allowMultiple={true}
        optionsChangeHandler={handleThemeSelectionChange as (themes: ITheme | ITheme[]) => void}
      />
    </div>
  );
};
