import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { css } from '@emotion/react';
import { colors, font, baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { AvailabilityStatus } from '@lego/b2b-unicorn-shared/ui';
import {
  arrowKeypressNavigation as onKeyDownHandler,
  arrowKeypressNavigationPreventingDefault as onKeyDownHandlerPreventingDefault,
  CUSTOM_MIN_DROPDOWN_MEDIA,
} from './searchCommons';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';

const productWrapStyle = css({
  fontSize: font.size.small,
  textAlign: 'left',
  display: 'flex',
  padding: baseSpacing,
  marginTop: 0,
  marginRight: baseSpacing,
  marginBottom: 0,
  marginLeft: baseSpacing,
  borderBottomWidth: 1,
  borderBottomColor: colors.klik.slate100,
  borderBottomStyle: 'solid',
  minHeight: baseSpacing * 8,
  color: colors.klik.lightBlue400,
  textDecoration: 'none',
  outline: 'none',
  scrollSnapAlign: 'start',
  scrollMarginTop: 62,
  position: 'relative',
  [CUSTOM_MIN_DROPDOWN_MEDIA]: {
    scrollMarginTop: 62,
  },

  '&:last-of-type': {
    borderBottomColor: 'transparent',
  },
  '&:before': {
    position: 'absolute',
    content: '""',
    backgroundColor: colors.klik.lightBlue400,
    top: 0,
    bottom: -1,
    left: -baseSpacing,
    right: -baseSpacing,
    opacity: 0,
  },

  '&:hover, &:focus': {
    '&:before': {
      opacity: 0.15,
    },
  },
});

const productImgWrapperStyle = css({
  width: '20%',
  minWidth: 40,
  maxWidth: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: baseSpacing,
  overflow: 'hidden',
  svg: {
    width: '100%',
    height: 'auto',
  },
});

const productDataStyle = css({
  width: '80%',
  display: 'flex',
  flexWrap: 'wrap',
});

const productTextStyle = css({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
});

const productNameStyle = css({
  width: '100%',
  marginTop: baseSpacing / 2,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const availabilityStatusStyle = css({
  marginLeft: baseSpacing,
  marginRight: baseSpacing,
  lineHeight: 0.5,
});

const SearchItem = ({
  product,
  locale,
  isLengthScrollable,
  onClickHandler,
  fetchProductImageUrl,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const { itemNumber, materialId, name, stockStatus } = product;

  useEffect(() => {
    if (fetchProductImageUrl) {
      const loadImageUrl = async () => {
        const url = await fetchProductImageUrl(materialId);
        setImageUrl(url);
      };
      loadImageUrl();
    }
  }, [materialId]);

  const content = (
    <AppLink
      app="shop"
      to={`/catalog/${materialId}`}
      css={productWrapStyle}
      onKeyDown={isLengthScrollable ? onKeyDownHandlerPreventingDefault : onKeyDownHandler}
      onClick={onClickHandler}
    >
      <div css={productImgWrapperStyle}>
        <ProductImage
          src={imageUrl}
          alt={name}
        />
      </div>
      <div css={productDataStyle}>
        <div css={productTextStyle}>
          <span>{itemNumber}</span>

          <span css={availabilityStatusStyle}>
            <AvailabilityStatus
              status={stockStatus?.status}
              nextPossibleDeliveryDate={stockStatus?.nextPossibleDeliveryDate}
              locale={locale}
              type="simple"
            />
          </span>
        </div>
        <div
          css={productNameStyle}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </div>
    </AppLink>
  );
  return content;
};

const StockStatus = {
  status: PropTypes.string,
  nextPossibleDeliveryDate: PropTypes.string,
};

const Product = {
  name: PropTypes.string.isRequired,
  materialId: PropTypes.number.isRequired,
  itemNumber: PropTypes.number,
  stockStatus: PropTypes.shape(StockStatus),
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SearchItem.propTypes = {
  product: PropTypes.shape(Product).isRequired,
  locale: PropTypes.string.isRequired,
  isLengthScrollable: PropTypes.bool,
  onClickHandler: PropTypes.func,
  fetchProductImageUrl: PropTypes.func,
};

export default SearchItem;
