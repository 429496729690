import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import { useClaim } from '@lego/b2b-unicorn-shared/components/Claim';
import { FileUpload } from '@lego/b2b-unicorn-shared/components/FileUpload';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Section, Spacer, Textarea } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { deepEqual } from 'fast-equals';
import React, { useCallback, useState } from 'react';

import { ContainerHeader } from '../../../../components/ContainerHeader';
import { getUploadUrl } from '../helpers';

const collapseContainerStyle = (display: boolean) =>
  css({
    display: display ? 'block' : 'none',
    marginTop: baseSpacing * 2,
    marginBottom: baseSpacing * 6,
    [media.medium]: {
      display: 'block',
    },
  });

type Files = Parameters<React.ComponentProps<typeof FileUpload>['onChange']>[0];

export const AdditionalFields: React.FC = () => {
  const {
    file_upload_drag_and_drop,
    file_upload_browse_files,
    upload_additional_documentation,
    additional_comments,
    claims_additional_comments_helper_text,
    file_upload_file_status_cancelled,
    file_upload_file_status_failed,
    file_upload_file_status_filesizeexceeded,
    file_upload_file_status_unsupported,
  } = useLabels();
  const {
    invoiceDetails,
    setDocumentationFiles,
    documentationFiles,
    fileService,
    comment,
    setComment,
  } = useClaim();
  const [showComment, setShowComment] = useState(true);
  const [showUpload, setShowUpload] = useState(true);

  const { id: customerId } = useSelectedCustomer();
  const dataAccessLayer = useDataAccessLayer();

  const handlePreFileUpload = useCallback(
    getUploadUrl(dataAccessLayer, customerId, invoiceDetails.invoiceNumber),
    [dataAccessLayer, customerId, invoiceDetails.invoiceNumber]
  );

  const handleFilesOnChange = useCallback(
    (files: Files) => {
      const nextDocumentationFiles = files.map((file) => ({
        id: file.metadata['uuid'],
        status: file.status,
        fileSize: file.file.size,
      }));

      if (!deepEqual(nextDocumentationFiles, documentationFiles)) {
        setDocumentationFiles(nextDocumentationFiles);
      }
    },
    [documentationFiles, setDocumentationFiles]
  );

  return (
    <Section>
      <ContainerHeader
        text={additional_comments}
        subText={claims_additional_comments_helper_text}
        open={showComment}
        setOpen={() => setShowComment(!showComment)}
        withLargeText={false}
        mobileOnlyToggle={true}
      />
      <div css={collapseContainerStyle(showComment)}>
        <Textarea
          value={comment ?? ''}
          onChange={(e) => setComment(e.target.value)}
          rows={6}
        />
      </div>

      <Spacer multiplier={6} />

      <ContainerHeader
        text={upload_additional_documentation}
        open={showUpload}
        setOpen={() => setShowUpload(!showUpload)}
        withLargeText={false}
        mobileOnlyToggle={true}
      />
      <div css={collapseContainerStyle(showUpload)}>
        <FileUpload
          fileService={fileService}
          fileServiceStack={'claim-file-upload'}
          onChange={handleFilesOnChange}
          getFileUploadUrl={handlePreFileUpload}
          translations={{
            file_upload_drag_and_drop: file_upload_drag_and_drop,
            file_upload_browse_files: file_upload_browse_files,
            status: {
              cancelled: file_upload_file_status_cancelled,
              failed: file_upload_file_status_failed,
              unsupported: file_upload_file_status_unsupported,
              fileSizeExceeded: file_upload_file_status_filesizeexceeded,
            },
          }}
          acceptedFileTypes={['image/*', '.heic', '.heif', 'application/pdf', 'application/zip']}
          multiple
        />
      </div>
    </Section>
  );
};
