import { stringifyValue } from '@lego/b2b-unicorn-shared/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type URLState<T> = { [key in keyof T]: T[key] };

const convertToNumberIfPossible = (v: string) => {
  const possibleNumber = parseInt(v);

  if (!isNaN(possibleNumber) && !isNaN(Number(v))) {
    return possibleNumber;
  }
  return v;
};

const convertStringToPrimitiveType = (value: string) => {
  let result;
  if (value.includes('[') && value.includes(']')) {
    result = value.slice(1, -1).split(',').map(convertToNumberIfPossible);
  } else {
    result = convertToNumberIfPossible(value);
  }
  return result;
};

const getParamsFromUrl = <T>(searchLocation: string): URLState<T> => {
  const queryParams = new URLSearchParams(searchLocation);
  const queryParamsKeys = Array.from(queryParams.keys()) as Array<keyof T>;

  const obj = queryParamsKeys.reduce<URLState<T>>((result, currentKey) => {
    const currentKeyValue = queryParams.get(currentKey as string) as unknown as string;

    return Object.assign(result, {
      [currentKey]: convertStringToPrimitiveType(currentKeyValue),
    });
  }, {} as URLState<T>);

  return obj;
};

const removeInvalidParams = <T>(
  params: URLState<T>,
  arrayOnlyParams?: Array<keyof T>,
  validateValues?: (key: keyof T, value: unknown) => boolean
) => {
  if (arrayOnlyParams) {
    let changed = false;
    for (const key of Object.keys(params) as Array<keyof T>) {
      const value = params[key];
      // Check if array-only params are arrays
      if (arrayOnlyParams.includes(key) && !Array.isArray(value)) {
        changed = true;
        delete params[key];
      }
      // Validate value using the provided function
      else if (validateValues && !validateValues(key, value)) {
        delete params[key as keyof T];
        changed = true;
      }
    }

    return {
      changed,
      params,
    };
  }

  return {
    changed: false,
    params,
  };
};

type UseStateWithUrlOptions<T> = {
  arrayOnlyParams?: Array<keyof T>;
  validateValues?: (key: keyof T, value: unknown) => boolean;
};

export function useStateWithUrl<T>(opts?: UseStateWithUrlOptions<T>) {
  const location = useLocation();
  const history = useHistory();

  const setUrlQueryState = useCallback(
    (value: T) => {
      const queryParams = new URLSearchParams();
      for (const key in value) {
        const stringValue = stringifyValue(value[key]);
        if (stringValue !== '' && stringValue !== '[]') {
          queryParams.set(key, stringValue);
        }
      }
      history.push({ search: queryParams.toString() });
    },
    [history]
  );

  const [value, setValue] = useState(() => {
    const paramsFromUrl = getParamsFromUrl<T>(location.search);
    const cleanParams = removeInvalidParams(
      paramsFromUrl,
      opts?.arrayOnlyParams,
      opts?.validateValues
    );

    if (cleanParams.changed) {
      setUrlQueryState(cleanParams.params);
    }

    return cleanParams.params;
  });

  useEffect(() => {
    const paramsFromUrl = getParamsFromUrl<T>(location.search);
    const cleanParams = removeInvalidParams(paramsFromUrl, opts?.arrayOnlyParams);
    if (cleanParams.changed) {
      setUrlQueryState(cleanParams.params);
    } else {
      setValue(cleanParams.params);
    }
  }, [location.search]);

  return [value, setUrlQueryState] as ReturnType<typeof useState<T>>;
}
