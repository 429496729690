import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Lifecycle } from '@lego/b2b-unicorn-data-access-layer';
import { SelectBox } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { LifecycleStatus } from '../../../../../../../constants';
import { useProductListContext } from '../../../ProductListContext';
import { inputContainerStyle } from '../styles';

export const LifecyclesFilter: React.FC = () => {
  const labelsContext = useLabels();
  const { getLabelByKey } = labelsContext;
  const {
    filtersList: { lifecycles },
    selectedFilters,
    setSelectedFilters,
  } = useProductListContext();

  const handleLifecycleSelect = (value: string | string[]) => {
    if (Array.isArray(value)) {
      let updatedFilters = { ...selectedFilters };
      if (value.length) {
        updatedFilters = {
          ...selectedFilters,
          lifecycles: value as Lifecycle[],
        };
      } else {
        delete updatedFilters.lifecycles;
      }
      setSelectedFilters(updatedFilters);
    }
  };

  return (
    <div css={inputContainerStyle}>
      <SelectBox
        label={labelsContext.filter_lifecycle}
        values={Object.keys(LifecycleStatus).map((lifecycle) => {
          const foundOption = lifecycles.find((option) => option.lifecycle === lifecycle);
          const hitsCount = `[${foundOption?.hitsCount}]`;
          return {
            displayText: `${getLabelByKey(lifecycle.toLowerCase())} ${
              foundOption && foundOption.hitsCount > 0 ? hitsCount : ''
            }`,
            value: lifecycle,
          };
        })}
        selectedValues={selectedFilters?.lifecycles || []}
        optionsChangeHandler={handleLifecycleSelect}
        allowMultiple={true}
      />
    </div>
  );
};
