import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Input } from '@lego/b2b-unicorn-shared/ui';
import { useState } from 'react';

import { labelTextStyle } from '../../../common/styles/checkoutFlowStyles';

interface IOrderName {
  value?: string;
  onChange: (isValid: boolean, value: string) => void;
}

const restrictedCharacters = ['\\', '|', '"'];

const OrderName = ({ value, onChange }: IOrderName) => {
  const { order_name, order_name_hint } = useLabels();
  const [isInvalid, setIsInvalid] = useState(false);

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isInvalid = restrictedCharacters.some((char) => e.target.value.includes(char));
    setIsInvalid(isInvalid);
    onChange(!isInvalid, e.target.value);
  };

  return (
    <>
      <label css={labelTextStyle}>{order_name}</label>
      <Input
        onChange={handleInputOnChange}
        value={value}
        // maxLength in SAP is 35 chars
        maxLength={35}
        isInvalid={isInvalid}
        invalidMessage={`${order_name_hint}: ${restrictedCharacters.join(', ')}`}
      />
    </>
  );
};

export default OrderName;
