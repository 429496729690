import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { IMenuLink } from './MenuItem';

const menuIconStyle = css({
  color: designToken.text.inverse,
  textDecoration: 'none',
  padding: 0,
  display: 'flex',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxSizing: 'border-box',

  [media.small]: {
    alignItems: 'center',
    boxShadow: 'none',
    borderWidth: 0,
    height: 72,
  },
  '&:hover, &:focus': {
    outline: 'none',
    color: tokens.color.core.azur['100'],
    '> div': {
      '> div:first-of-type': {
        outline: 'none',
      },
    },
  },
});

const MenuIconLink: FunctionComponent<IMenuLink> = ({ children, path, dataE2e }) => {
  return (
    <Link
      to={path}
      data-e2e={dataE2e}
      css={menuIconStyle}
      tabIndex={0}
    >
      {children}
    </Link>
  );
};

export default MenuIconLink;
