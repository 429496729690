import { useState } from 'react';

// used to throw error that is catchable by componentDidCatch inside error boundary component
export const useFatalErrorInComponentScope = () => {
  const [fatalError, setFatalError] = useState();

  if (fatalError) {
    throw fatalError;
  }

  return { setFatalError };
};
