import { css } from '@emotion/react';
import { baseSpacing, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import React from 'react';

import construction from '../../../../../src/shop/assets/contruction-workers.svg';
import { Container, designToken, Paragraph } from './../';

const emptyPreviewStyle = (backgroundSize: 'fixed' | 'auto') =>
  css({
    width: '100%',
    height: 600,
    color: designToken.primary.default,
    backgroundColor: tokens.color.core.blue['100'],
    borderRadius: 8,
    marginTop: baseSpacing * 2,
    marginBottom: baseSpacing * 2,
    backgroundImage: `url(${construction})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: `bottom -20px left 20px`,
    '> *': {
      marginRight: 'auto',
      marginLeft: 'auto',
      textAlign: 'center',
      display: 'block',
    },
    '> p': {
      width: '90%',
      paddingTop: baseSpacing * 10,
      fontWeight: font.weight.medium,
    },

    [media.small]: {
      backgroundPosition: `top 140px left 20px`,
      '> p': {
        fontSize: font.size.medium,
        fontWeight: font.weight.bold,
        paddingTop: baseSpacing * 6,
      },
    },
    [media.medium]: {
      backgroundSize: backgroundSize === 'fixed' ? 'auto 770px' : 'contain',
      backgroundPosition: `top 150px center`,
      height: 640,
      maxWidth: maxDesktopWidth,
      marginTop: baseSpacing * 6,
      marginRight: 'auto',
      marginLeft: 'auto',
      '> p': {
        paddingTop: baseSpacing * 8,
        width: '60%',
      },
    },
  });

interface WebshopNotReadyFeedbackProps {
  message: string;
  backgroundSize?: 'fixed' | 'auto';
  children?: React.ReactNode;
}
export const WebshopNotReadyFeedback: React.FC<WebshopNotReadyFeedbackProps> = ({
  message,
  backgroundSize = 'fixed',
  children,
}) => {
  return (
    <Container>
      <div css={emptyPreviewStyle(backgroundSize)}>
        <Paragraph>{message}</Paragraph>
        {children}
      </div>
    </Container>
  );
};
