import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useAuthentication } from '@lego/b2b-unicorn-bootstrap/hooks';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { SearchCustomerInput } from '@lego/b2b-unicorn-shared/components/SearchCustomerInput';
import { SelectCustomer } from '@lego/b2b-unicorn-shared/components/SelectCustomer';
import { Button, ButtonType, Spacer, WebshopNotReadyFeedback } from '@lego/b2b-unicorn-shared/ui';
import { SimpleLayout } from '@lego/b2b-unicorn-shared/ui/layouts';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

type SelectCustomerPageProps = {
  customers: Customer[];
  onCustomerClick: (customer: Customer) => void;
};

const listTitleStyle = css({
  margin: 0,
  padding: baseSpacing,
  fontSize: font.size.large,
  fontWeight: font.weight.light,
});

const listSubTitleStyle = css({
  marginTop: 0,
  marginBottom: 0,
  paddingRight: baseSpacing,
  paddingLeft: baseSpacing,
});

const listBoxOuterContainer = (numberOfCustomers: number) =>
  css({
    boxSizing: 'border-box',
    height: 84 + numberOfCustomers * 5 * baseSpacing,
    position: 'relative',
    maxHeight: 440,
    overflowY: 'hidden',

    '&:after': {
      pointerEvents: 'none',
      content: '""',
      display: 'block',
      width: `100%`,
      height: '100%',
      position: 'sticky',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      boxShadow: `0 ${baseSpacing * 3}px ${baseSpacing}px -${baseSpacing}px ${
        colors.klik.slate10
      } inset,
                0 -${baseSpacing * 7}px ${baseSpacing * 3}px -${baseSpacing * 3}px ${
                  colors.klik.slate10
                } inset`,
    },
  });

const listScrollBoxStyle = () =>
  css({
    paddingLeft: baseSpacing,
    paddingRight: baseSpacing,
    paddingTop: baseSpacing * 2,
    paddingBottom: baseSpacing * 5,
    overflowY: 'auto',
    height: '100%',
  });

const inputWrapperCss = css({
  paddingTop: baseSpacing,
  paddingRight: baseSpacing,
  paddingLeft: baseSpacing,
  marginBottom: 2,
});

const SelectCustomerPage = ({ onCustomerClick, customers }: SelectCustomerPageProps) => {
  const {
    login_select_customer,
    login_select_customer_info,
    content_system_feedback_no_active_customers,
    button_sign_out,
  } = useLabels();
  const { logout } = useAuthentication();

  const [filteredCustomers, setFilteredCustomers] = useState(() => customers);
  const handleOnSearchCustomerInputChange = useCallback((results: Customer[]) => {
    setFilteredCustomers(results);
  }, []);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const handleOnLogoutClick = () => {
    logout().then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    GlobalLoadingService.remove(window.firstLoaderId);
  }, []);

  useLayoutEffect(() => {
    if (filterInputRef.current) {
      filterInputRef.current?.focus();
    }
  }, []);

  return (
    <SimpleLayout>
      {customers.length === 0 && (
        <WebshopNotReadyFeedback
          message={content_system_feedback_no_active_customers}
          backgroundSize={'auto'}
        >
          <>
            <Spacer multiplier={6} />
            <Button
              size="medium"
              type={ButtonType.PRIMARY_INVERTED}
              onClick={handleOnLogoutClick}
            >
              {button_sign_out}
            </Button>
          </>
        </WebshopNotReadyFeedback>
      )}
      {customers.length > 0 && (
        <div
          css={css({
            width: 'auto',
            [media.medium]: {
              width: 500,
            },
          })}
        >
          <h2 css={listTitleStyle}>{login_select_customer}</h2>
          <p css={listSubTitleStyle}>{login_select_customer_info}</p>
          {customers.length > 5 && (
            <div css={inputWrapperCss}>
              <SearchCustomerInput
                customers={customers}
                onChange={handleOnSearchCustomerInputChange}
                searchInputRef={filterInputRef}
              />
            </div>
          )}
          <div css={listBoxOuterContainer(filteredCustomers.length)}>
            <div css={listScrollBoxStyle}>
              <SelectCustomer
                customers={filteredCustomers}
                onCustomerClick={onCustomerClick}
              />
            </div>
          </div>
        </div>
      )}
    </SimpleLayout>
  );
};

export default SelectCustomerPage;
