import { logger } from '@lego/b2b-unicorn-shared/logger';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { CatalogErrorFeedback } from './CatalogErrorFeedback';
import { EmptyCatalogFeedback } from './EmptyCatalogFeedback';

const productListErrorLogger = logger.createLogger('ProductListErrorBoundary');

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  hasForbiddenError: boolean;
}

export class ProductListErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    hasForbiddenError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    const hasForbiddenError = error.message.includes('403');
    return { hasError: true, hasForbiddenError };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    productListErrorLogger.error(error, { ...errorInfo });
  }

  render() {
    if (this.state.hasError && this.state.hasForbiddenError) {
      return <EmptyCatalogFeedback />;
    }
    if (this.state.hasError && !this.state.hasForbiddenError) {
      return <CatalogErrorFeedback />;
    }

    return this.props.children;
  }
}
