import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useState } from 'react';

import { useProductListContext } from '../../ProductListContext';
import { ITheme } from '../../types';
import { SkeletonThemes } from './SkeletonThemes';
import { backgroundStyle, buttonWrapperStyle, wrapperStyle } from './styles';
import { ThemeTile } from './ThemeTile';

const isThemeSelected = (theme: ITheme, themes: string[]) =>
  themes.includes(theme.title) ||
  theme.subThemes?.some((subTheme) => themes.includes(subTheme.title)) ||
  false;

const blacklistedThemes = ['ERROR ENTRY'];

const removeBlacklistedThemes = (rawThemes: ITheme[]) =>
  rawThemes.filter((theme) => !blacklistedThemes.includes(theme.title.toUpperCase()));

export const Themes: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const [showAllTiles, setShowAllTiles] = useState<boolean>(false);
  const { button_show_more, button_show_less } = useLabels();
  const {
    filtersList: { themes: rawThemes },
    selectedFilters,
    setSelectedFilters,
  } = useProductListContext();
  const themes = removeBlacklistedThemes(rawThemes);

  const handleThemesFilterChange = (themes: string[]) => {
    let updatedFilters = { ...selectedFilters };
    if (themes.length) {
      updatedFilters = {
        ...selectedFilters,
        themes: themes as string[],
      };
    } else {
      delete updatedFilters.themes;
    }
    setSelectedFilters(updatedFilters);
  };
  const handleThemeSelectionChange = (theme: ITheme) => {
    const updatedThemes = [...(selectedFilters?.themes || [])];
    const themeSelected = isThemeSelected(theme, selectedFilters?.themes || []);
    const titles = theme.subThemes?.length
      ? theme.subThemes.map((subTheme) => subTheme.title)
      : [theme.title];
    if (themeSelected) {
      trackEvent({
        event: 'themeTileDeselecting',
        name: theme.title,
      });
      titles.map((title) => {
        updatedThemes.splice(updatedThemes.indexOf(title), 1);
      });
    } else {
      trackEvent({
        event: 'themeTileSelecting',
        name: theme.title,
      });
      updatedThemes.push(...titles);
    }
    handleThemesFilterChange(updatedThemes);
  };
  const handleToggleShowAllTiles = () => {
    if (showAllTiles) {
      trackEvent({
        event: 'themeTilesShowLess',
        name: 'Show less',
      });
      window.scrollTo(0, 0);
    } else {
      trackEvent({
        event: 'themeTilesShowMore',
        name: 'Show more',
      });
    }
    setShowAllTiles(!showAllTiles);
  };
  return (
    <section css={backgroundStyle(showAllTiles, themes?.length)}>
      <div css={wrapperStyle(showAllTiles, themes?.length)}>
        {themes?.length ? (
          themes.map((theme) => (
            <ThemeTile
              onChange={handleThemeSelectionChange}
              theme={theme}
              isHighlighted={
                selectedFilters?.themes?.length
                  ? isThemeSelected(theme, selectedFilters.themes)
                  : true
              }
              key={theme.title}
            />
          ))
        ) : (
          <SkeletonThemes />
        )}
      </div>
      <div css={buttonWrapperStyle}>
        <Button
          size={'tiny'}
          type={ButtonType.GHOST}
          onClick={handleToggleShowAllTiles}
        >
          {showAllTiles ? button_show_less : button_show_more}
        </Button>
      </div>
    </section>
  );
};
