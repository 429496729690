import React from 'react';

import EmptyBox from '../../../../../src/shop/components/common/EmptyBox';
import { skeletonImageStyle } from './skeletonCss';

export const SkeletonImage: React.FC = () => {
  return (
    <div css={skeletonImageStyle}>
      <EmptyBox />
    </div>
  );
};
