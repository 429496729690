import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useAuthentication } from '@lego/b2b-unicorn-bootstrap/hooks';
import { UserService } from '@lego/b2b-unicorn-bootstrap/services';
import { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { SearchCustomerInput } from '@lego/b2b-unicorn-shared/components/SearchCustomerInput';
import { SelectCustomer } from '@lego/b2b-unicorn-shared/components/SelectCustomer';
import { useSelectedCustomer, useUser } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Button,
  designToken,
  Icon,
  IconType,
  ToolTip,
  ToolTipDirection,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '../../ui';

const mainButtonContentStyle = css({
  display: 'none',
  [media.medium]: {
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    textAlign: 'left',
    display: 'flex',
    fontSize: font.size.tiny,
    height: baseSpacing * 4,
    '> div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      fontWeight: font.weight.medium,
      '> svg': {
        width: 12,
        height: 12,
        fill: designToken.text.inverse,
        transform: 'translateY(4px)',
      },
    },
  },
});

const buttonContentUserStyle = css({
  display: 'inline-block',
  height: 2 + baseSpacing * 2,
  fontSize: font.size.small,
  fontWeight: font.weight.medium,
  maxWidth: 120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const mainButtonStyle = css({
  width: baseSpacing * 4,
  height: baseSpacing * 4,
  border: 0,
  padding: 0,
  marginRight: 5, //adjusted for search input magglas
  marginLeft: baseSpacing,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: designToken.text.inverse,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  transitionDuration: '150ms',
  position: 'relative',
  outline: 0,
  borderRadius: baseSpacing * 2,
  '> svg': {
    width: 20,
    height: 20,
    fill: designToken.text.inverse,
    transitionDuration: '150ms',
  },
  '&:active': {
    outline: 'none',
  },
  '&:hover, &:focus': {
    color: tokens.color.core.azur['100'],
    '> svg': {
      fill: tokens.color.core.azur['100'],
    },
  },
});

const customerMenuHeaderStyle = (isSingleCustomer: boolean) =>
  css({
    color: designToken.text.subdued,
    fontWeight: isSingleCustomer ? font.weight.normal : font.weight.light,
    maxWidth: '100%',
    padding: baseSpacing * 2,
    paddingBottom: isSingleCustomer ? 0 : baseSpacing,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [media.medium]: {
      display: isSingleCustomer ? 'none' : undefined,
      fontSize: isSingleCustomer ? font.size.medium : undefined,
      fontWeight: font.weight.normal,
    },
    h2: {
      [media.medium]: {
        display: 'none',
      },
      margin: 0,
      marginBottom: baseSpacing,
      fontSize: font.size.large,
      fontWeight: font.weight.light,
    },
    div: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
    },
  });

const inlineBlockStyle = css({
  display: 'inline-block',
});

const scrollBaseStyle = css({
  width: `calc(100% + (${baseSpacing} * 4)px)`,
  marginLeft: -baseSpacing * 2,
  marginRight: -baseSpacing * 2,
  display: 'block',
  position: 'relative',

  [media.medium]: {
    margin: 0,
    width: '100%',
  },
});

const scrollUpStyle = css(scrollBaseStyle, {
  zIndex: 2,
  '&::before': {
    content: '""',
    width: '100%',
    height: baseSpacing * 2,
    position: 'absolute',
    background: `linear-gradient(${designToken.white} 0%,${designToken.transparent.default} 100%)`,
    pointerEvents: 'none',
  },
});

const scrollDownStyle = css(scrollBaseStyle, {
  height: 36,
  display: 'block',
  '&::before': {
    content: '""',
    width: '100%',
    height: baseSpacing * 4,
    position: 'absolute',
    top: -baseSpacing * 3,
    background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.white} 60%, ${designToken.white} 100%)`,
    pointerEvents: 'none',
  },
});

const triggerContentWrapper = css({
  display: 'flex',
  flexWrap: 'nowrap',
});

const inputWrapperDiv = css({
  paddingRight: 0,
  paddingLeft: 0,
  paddingBottom: baseSpacing,
  [media.medium]: {
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
  },
});

const selectCustomerExtraCss = css({
  paddingRight: baseSpacing * 4,
  paddingLeft: baseSpacing * 4,
  [media.medium]: {
    paddingRight: baseSpacing * 2,
    paddingLeft: baseSpacing * 2,
  },
});

const logoutWrapperStyle = (isSingleCustomer: boolean) =>
  css({
    padding: baseSpacing * 2,
    borderTopWidth: isSingleCustomer ? 0 : 2,
    borderTopStyle: 'solid',
    borderTopColor: designToken.border.subdued,
    display: 'flex',
    justifyContent: 'flex-end',
    [media.medium]: {
      position: 'initial',
    },
    a: {
      width: '100%',
    },
    button: {
      width: '100%',
      [media.medium]: {
        height: 40,
      },
    },
  });

export const SelectCustomerPopover = () => {
  const [userService] = useState(() => UserService.getInstance());
  const [selectCustomerPopoverOpened, setSelectCustomerPopoverOpened] = useState(false);
  const selectedCustomer = useSelectedCustomer();
  const user = useUser();
  const { logout } = useAuthentication();

  const { welcome, customer_switch_title, button_sign_out } = useLabels();

  const customers = useMemo(() => user.customers, [user.customers]);
  const [filteredCustomers, setFilteredCustomers] = useState(() => customers);
  const [triggerFilterClear, setTriggerFilterClear] = useState(false);
  const handleOnSearchCustomerInputChange = useCallback((results: Customer[]) => {
    setFilteredCustomers(results);
  }, []);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const isSingleCustomer = user.customers.length === 1;

  const handleOnCustomerClick = useCallback(
    (customer: Customer) => {
      userService.selectCustomer(customer);
      setSelectCustomerPopoverOpened(false);
      setTriggerFilterClear(true);
    },
    [userService]
  );

  const handleOnOpened = useCallback(() => {
    setSelectCustomerPopoverOpened(true);
    filterInputRef.current?.focus();
  }, []);

  const handleOnClearedFilter = useCallback(() => {
    setTriggerFilterClear(false);
  }, []);

  const handleOnClosed = useCallback(() => {
    setSelectCustomerPopoverOpened(false);
  }, []);

  const handleOnSignOutClick = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Popover
      open={selectCustomerPopoverOpened}
      onOpened={handleOnOpened}
      onClosed={handleOnClosed}
    >
      <PopoverTrigger>
        <ToolTip
          message={`${selectedCustomer.id} ${selectedCustomer.name}`}
          direction={ToolTipDirection.DownLeft}
        >
          <div css={triggerContentWrapper}>
            <div css={mainButtonStyle}>
              <Icon type={IconType.USER} />
            </div>
            <div css={mainButtonContentStyle}>
              <div>{welcome}</div>
              <div>
                <span css={buttonContentUserStyle}>
                  {selectedCustomer.id} {selectedCustomer.name}
                </span>
                &nbsp;
                <Icon type={IconType.CHEVRON_DOWN} />
              </div>
            </div>
          </div>
        </ToolTip>
      </PopoverTrigger>
      <PopoverContent>
        <div css={customerMenuHeaderStyle(isSingleCustomer)}>
          {isSingleCustomer ? (
            <Fragment>
              <span css={inlineBlockStyle}>{selectedCustomer.id}</span>{' '}
              <span css={inlineBlockStyle}>{selectedCustomer.name}</span>
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <h2>
                  <span css={inlineBlockStyle}>{selectedCustomer.id}</span>{' '}
                  <span css={inlineBlockStyle}>{selectedCustomer.name}</span>
                </h2>
              </div>
              <span>{customer_switch_title}:</span>
            </Fragment>
          )}
        </div>
        {customers.length > 5 && (
          <div css={inputWrapperDiv}>
            <SearchCustomerInput
              customers={customers}
              onChange={handleOnSearchCustomerInputChange}
              searchInputRef={filterInputRef}
              triggerClear={triggerFilterClear}
              onCleared={handleOnClearedFilter}
            />
          </div>
        )}
        {customers.length > 10 && <span css={scrollUpStyle} />}
        <SelectCustomer
          customers={filteredCustomers}
          onCustomerClick={handleOnCustomerClick}
          extraCss={selectCustomerExtraCss}
        />
        {filteredCustomers.length > 10 && <span css={scrollDownStyle} />}
        <div css={logoutWrapperStyle(isSingleCustomer)}>
          <Button
            size={isSingleCustomer ? 'small' : 'medium'}
            onClick={handleOnSignOutClick}
          >
            {button_sign_out}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
