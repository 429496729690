import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';
import { TILE_WIDTH } from '../constants';

export const availabilityStatusStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const bigTextStyle = {
  fontSize: font.size.normal,
  fontWeight: font.weight.bold,
  marginBottom: baseSpacing / 2,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  lineHeight: 1.2,
};

export const mediumTextStyle = {
  fontSize: font.size.small,
  fontWeight: font.weight.normal,
  margin: 0,
};

export const smallTextStyle = {
  color: colors.klik.slate500,
  fontSize: font.size.tiny,
  fontWeight: font.weight.normal,
  margin: 0,
};

export const rowStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  marginTop: baseSpacing,
});

export const infoRowStyle = css(rowStyle, {
  borderTopColor: colors.klik.slate100,
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  marginTop: 0,
  paddingTop: baseSpacing,
  '> div': {
    flex: 1,
    display: 'block',
    '> *:first-of-type': smallTextStyle,
    '> *:last-of-type': mediumTextStyle,
  },
});

export const linkStyle = css({
  textDecoration: 'none',
  transitionProperty: 'color',
  transitionDuration: '125ms',

  '&:hover, &:active': {
    color: colors.klik.lightBlue500,

    '> img, svg': {
      transition: 'transform 300ms cubic-bezier(0,.73,.55,.55)',
      transform: 'scale(1.06)',
    },
  },
  '&:focus': {
    color: colors.klik.lightBlue500,
    outline: 'none',
  },

  '> img, svg': {
    transition: 'transform 300ms cubic-bezier(0,.73,.55,.55)',
    height: 156,
    width: 'auto',
    display: 'block',
    marginTop: baseSpacing * 2.5,
    marginBottom: baseSpacing * 2.5,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const titleStyle = css({
  height: 56,
});

export const themeStyle = css(smallTextStyle, {
  color: colors.klik.slate500,
});

export const commonProductTileStyle = css({
  width: TILE_WIDTH,
  background: colors.white,
  padding: baseSpacing,
  textAlign: 'left',
  color: designToken.text.default,
  margin: 0,
  borderRadius: 8,
  boxSizing: 'border-box',
  position: 'relative',
});
