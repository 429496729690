import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { Fragment } from 'react';

import { resetFormButtonStyle, searchInputStyle } from './styles';

/** Item numbers consist of digits and no leading zeros */
const sanitizeItemNumber = (inputValue: string) =>
  inputValue.replace(/[^\d]/, '').replace(/^0+/, '');

interface IQuickAddSearchInput {
  query: string;
  setQuery: (value: string) => void;
  isFocusable: boolean;
  onKeyDownHandler: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlurHandler: () => void;
  searchInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  resetForm: () => void;
}

export const QuickAddSearchInput = ({
  query,
  setQuery,
  isFocusable,
  onKeyDownHandler,
  onBlurHandler,
  searchInputRef,
  resetForm,
}: IQuickAddSearchInput) => {
  const { quick_order_input_placeholder } = useLabels();

  const resetFormOnClearKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      resetForm();
    }
  };

  return (
    <Fragment>
      <input
        data-arrow-keypress-navigable-index="0"
        css={searchInputStyle}
        onBlur={onBlurHandler}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        value={query}
        ref={searchInputRef}
        tabIndex={isFocusable ? -1 : 0}
        placeholder={quick_order_input_placeholder}
        aria-label="Search products by item number."
        aria-describedby="quickAddSearchFeedback"
        onKeyDown={onKeyDownHandler}
        onChange={(e) => setQuery(sanitizeItemNumber(e.target.value))}
      />
      {query && (
        <button
          css={resetFormButtonStyle}
          onClick={resetForm}
          onKeyDown={resetFormOnClearKeydown}
          aria-label="Clear search input."
        >
          <Icon type={IconType.CLEAR} />
        </button>
      )}
    </Fragment>
  );
};
