import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartSimulationDetails, Money } from '@lego/b2b-unicorn-data-access-layer';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import { FinalPriceDialogContentListItem } from './FinalPriceDialogContentListItem';

const finalPriceDetailsPopupStyle = css({
  fontSize: font.size.small,
  width: '100%',
  '> h4': {
    fontWeight: font.weight.bold,
    margin: 0,
    padding: 0,
    paddingBottom: baseSpacing,
    paddingRight: 20,
  },
});

const withTopLineStyle = css({
  display: 'inline-block',
  paddingTop: baseSpacing,
  marginTop: baseSpacing * 2,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: designToken.border.default,
});
interface FinalPriceDialogContentWithSimulationDetailsProps {
  simulationDetails?: CartSimulationDetails | null;
  simulationFinalPrice?: Money;
  simulationFinalListPrice?: Money;
  currency: string | false;
}
export const FinalPriceDialogContentWithSimulationDetails: React.FC<
  FinalPriceDialogContentWithSimulationDetailsProps
> = ({ simulationDetails, simulationFinalPrice, simulationFinalListPrice, currency }) => {
  const {
    final_price_dialog_header,
    final_price_dialog_list_price,
    final_price_dialog_discounts,
    final_price_dialog_surcharge,
    order_total,
  } = useLabels();

  return (
    <div css={finalPriceDetailsPopupStyle}>
      <h4>{final_price_dialog_header}</h4>
      <FinalPriceDialogContentListItem
        value={simulationFinalListPrice}
        label={final_price_dialog_list_price}
        currency={currency}
      />
      {simulationDetails?.totalDiscount && (
        <FinalPriceDialogContentListItem
          value={simulationDetails.totalDiscount.amount}
          label={final_price_dialog_discounts}
          currency={currency}
        />
      )}
      {simulationDetails?.totalItemSurcharge && (
        <FinalPriceDialogContentListItem
          value={simulationDetails.totalItemSurcharge.amount}
          label={final_price_dialog_surcharge}
          currency={currency}
        />
      )}
      <span css={withTopLineStyle}>
        <FinalPriceDialogContentListItem
          value={simulationFinalPrice}
          label={order_total}
          currency={currency}
        />
      </span>
    </div>
  );
};
