import React, { useEffect, useState } from 'react';

import { ITheme } from '../../types';
import { themeTileStyle } from './styles';

type ThemeTileProps = {
  theme: ITheme;
  onChange: (theme: ITheme) => void;
  isHighlighted: boolean;
};

export const ThemeTile: React.FC<ThemeTileProps> = ({ onChange, theme, isHighlighted = true }) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const loadImage = async () =>
    await import(`../../../../../../../assets/images/themes/${theme?.title.toUpperCase()}.png`)
      .then((image) => setImage(image.default))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Issue loading image for: ', theme?.title);
        return null;
      });

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <button
      css={themeTileStyle(isHighlighted)}
      tabIndex={-1}
      onClick={() => onChange(theme)}
    >
      {image ? (
        <img
          src={image}
          alt={theme?.title}
        />
      ) : (
        theme?.title
      )}
    </button>
  );
};
