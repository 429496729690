import { customAlphabet } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { DataEditorProps } from 'react-spreadsheet';

const staticInputValue: { [key: string]: string } = {};

export const BulkQuickAddDataEditor: React.FC<DataEditorProps> = ({ onChange, cell }) => {
  const [dataEditorId] = useState(() => customAlphabet('abcdefghijklmnopqrstuvwxyz')());
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(cell?.value || '');

  const handleChange = useCallback(() => {
    onChange({ ...cell, value: inputValue });
  }, [onChange, cell, inputValue]);

  const handleOnInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      staticInputValue[dataEditorId] = event.target.value;
    },
    [dataEditorId]
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          handleChange();
          break;
        case 'Escape':
          setInputValue(cell?.value);
          // Allow React to commit the value to state before blurring the input
          setTimeout(() => inputRef.current?.blur());
          break;
      }
    },
    [cell?.value, handleChange]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onChange({ ...cell, value: e.target.value });
    },
    [cell, onChange]
  );

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      staticInputValue[dataEditorId] = event.target.value;
    },
    [dataEditorId]
  );

  useEffect(() => {
    const clickOutsideHandler = (event: MouseEvent) => {
      if (containerRef.current === null && inputRef.current === null) {
        onChange({ ...cell, value: staticInputValue[dataEditorId] });
      }
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onChange({ ...cell, value: inputRef.current?.value });
      }
    };
    document.addEventListener('click', clickOutsideHandler);
    return () => {
      // Allow React to commit the value to state before removing the event listener
      setTimeout(() => {
        document.removeEventListener('click', clickOutsideHandler);
      }, 250);
    };
  }, [cell, dataEditorId, onChange]);

  return (
    <div
      className="Spreadsheet__data-editor"
      ref={containerRef}
    >
      <input
        ref={inputRef}
        type="text"
        onChange={handleOnInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyDown={handleOnKeyDown}
        value={inputValue}
        autoFocus
      />
    </div>
  );
};
