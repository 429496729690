import { css } from '@emotion/react';
import { Price } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { SummaryPrice } from '../../../SummaryPrice';
import { totalAmountStyle } from '../styles';

const warningAmountStyle = css(totalAmountStyle, {
  color: designToken.warning.default,
});

interface InitialPriceValueProps {
  sumPrice: Price;
  isMinValueMet: boolean;
}

export const InitialPriceValue: React.FC<InitialPriceValueProps> = ({
  sumPrice,
  isMinValueMet,
}) => {
  const selectedCustomer = useSelectedCustomer();

  return (
    <div
      css={
        isMinValueMet ||
        sumPrice.grossPrice === 0 ||
        sumPrice.estimatedNetInvoicedPrice === 0 ||
        sumPrice.netInvoicedPrice === 0
          ? totalAmountStyle
          : warningAmountStyle
      }
    >
      <SummaryPrice locale={selectedCustomer.locale}>{sumPrice}</SummaryPrice>
    </div>
  );
};
