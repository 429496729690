import React, { useContext } from 'react';

import { RowStatusReason } from './types';

interface IBulkQuickAddContextTranslations {
  columnLabels: {
    product: string;
    product_column_info: string;
    quantity: string;
    name: string;
    theme: string;
    filter_availability: string;
    total_pieces: string;
    price_per_piece: string;
    your_price: string;
  };
  availabilityTranslations: {
    available: string;
    low_stock: string;
    next_possible_delivery: string;
    out_of_stock: string;
    not_available: string;
  };
}

interface IBulkQuickAddContext {
  translations: IBulkQuickAddContextTranslations;
  locale: string;
  rowStatuses: Array<RowStatusReason | undefined>;
  removeRow: (rowNumber: number) => void;
}
const BulkQuickAddContext = React.createContext<IBulkQuickAddContext>({
  translations: {
    columnLabels: {
      product: '',
      product_column_info: '',
      quantity: '',
      name: '',
      theme: '',
      filter_availability: '',
      total_pieces: '',
      price_per_piece: '',
      your_price: '',
    },
    availabilityTranslations: {
      available: '',
      low_stock: '',
      next_possible_delivery: '',
      out_of_stock: '',
      not_available: '',
    },
  },
  locale: '',
  rowStatuses: [],
  removeRow: () => {},
});

type BulkQuickAddContextProviderProps = {
  children: React.ReactNode;
  translations: IBulkQuickAddContextTranslations;
  locale: string;
  rowStatuses: Array<RowStatusReason | undefined>;
  removeRow: (rowNumber: number) => void;
};
export const BulkQuickAddContextProvider: React.FC<BulkQuickAddContextProviderProps> = ({
  children,
  translations,
  locale,
  rowStatuses,
  removeRow,
}) => {
  return (
    <BulkQuickAddContext.Provider value={{ translations, locale, rowStatuses, removeRow }}>
      {children}
    </BulkQuickAddContext.Provider>
  );
};

export const useBulkQuickAddContext = () => {
  return useContext(BulkQuickAddContext);
};
