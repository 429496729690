import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartSimulationDetails, Money } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Paragraph } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { FinalPriceDialogContentWithSimulationDetails } from './FinalPriceDialogContentWithSimulationDetails';
import { MinOrderValueWarning } from './MinOrderValueWarning';
import { PriceDetailsInfo } from './PriceDetailsInfo';

interface FinalPriceDetailsProps {
  simulationDetails?: CartSimulationDetails | null;
  simulationFinalPrice?: Money;
  simulationFinalListPrice?: Money;
  minimumOrderValue: Money;
  isMinValueMet: boolean;
  currency: string | false;
}

export const FinalPriceDetails: React.FC<FinalPriceDetailsProps> = ({
  simulationDetails,
  simulationFinalPrice,
  simulationFinalListPrice,
  minimumOrderValue,
  isMinValueMet,
  currency,
}) => {
  const selectedCustomer = useSelectedCustomer();

  const { final_price, final_price_invoice_information, final_price_dialog_content } = useLabels();

  return (
    <>
      {selectedCustomer.showSimulationDetails ? (
        <PriceDetailsInfo
          popupContentRender={
            <FinalPriceDialogContentWithSimulationDetails
              simulationDetails={simulationDetails}
              simulationFinalPrice={simulationFinalPrice}
              simulationFinalListPrice={simulationFinalListPrice}
              currency={currency}
            />
          }
        >
          {final_price}
        </PriceDetailsInfo>
      ) : (
        <PriceDetailsInfo
          popupContentRender={
            <Paragraph fontSize={'small'}>{final_price_dialog_content}</Paragraph>
          }
        >
          {final_price_invoice_information}
        </PriceDetailsInfo>
      )}
      <MinOrderValueWarning
        isMinValueMet={isMinValueMet}
        minimumOrderValue={minimumOrderValue}
      />
    </>
  );
};
