import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentProduct } from '@lego/b2b-unicorn-data-access-layer';
import {
  ReplenishmentProductBillOfMaterial,
  ReplenishmentProductBox,
} from '@lego/b2b-unicorn-data-access-layer/context/Replenishment/entities';
import { useMaterialIdInCart } from '@lego/b2b-unicorn-data-access-layer/react/hooks/replenishment';
import { ContentHubButton } from '@lego/b2b-unicorn-shared/components/ContentHubButton';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AvailabilityStatus, Container, designToken } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useCallback } from 'react';

import {
  availabilityStatusStyle,
  bigTextStyle,
  commonProductTileStyle,
  mediumTextStyle,
  smallTextStyle,
  themeStyle,
} from '../../../styles';
import ProductTileBottom from '../../common/ProductTile/ProductTileBottom';
import { ProductTileTags } from '../../common/ProductTile/ProductTileTags';
import { useProductTile } from '../../common/ProductTile/useProductTile';

const productTileStyle = (open: boolean) =>
  css(commonProductTileStyle, {
    display: open ? 'block' : 'none',
    borderRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',

    'section + section': {
      borderTopColor: designToken.background.gray,
      borderTopStyle: 'solid',
      borderTopWidth: 2,
      paddingTop: baseSpacing,
      paddingBottom: baseSpacing,
    },
    '> section:last-of-type': {
      paddingBottom: 0,
    },
    [media.medium]: {
      display: 'block',
    },
  });

const infoRowStyle = css({
  marginTop: 0,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  p: {
    width: '100%',
  },
});

const infoRowListStyle = css({
  width: '48%',
  listStyleType: 'none',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  paddingInlineStart: 0,

  '> li': {
    marginBottom: baseSpacing,
    textDecoration: 'none',

    '> *:first-of-type': smallTextStyle,
    '> *:last-child': {
      ...mediumTextStyle,
    },
  },
});

const titleStyle = css({
  height: 'initial',
  marginTop: baseSpacing,
});

interface IProductDetailsTile {
  product: ReplenishmentProduct;
  locale: string;
  open: boolean;
}

export const ProductDetailsTile: React.FC<IProductDetailsTile> = ({ product, locale, open }) => {
  const {
    materialId,
    name,
    openOrderInfo,
    stockStatus,
    theme,
    lifecycles,
    tags,
    dimensions,
    ean,
    price,
    piecesPerCasePack,
    weight,
  } = product;
  const itemNumber = product instanceof ReplenishmentProductBox ? product.itemNumber : 0;

  const {
    available,
    low_stock,
    next_possible_delivery,
    out_of_stock,
    not_available,
    launch_date,
    exit_date,
    recommended_retail_price,
    price_per_piece,
    pieces_per_case_pack,
    age,
    number_of_bricks,
    ean: barcode,
    weight: gross_weight,
    dimensions: box_dimensions,
  } = useLabels();

  const { id: customerId } = useSelectedCustomer();
  const foundInCart = useMaterialIdInCart(customerId, materialId, { fetchPolicy: 'cache-only' });

  const { trackEvent } = useAnalytics();
  const beforeAddToCart = useCallback(() => {
    trackEvent({
      event: 'addToCartFromProductDetails',
      name: `Adding product ${materialId} to cart`,
    });
  }, [trackEvent, materialId]);
  const { addToCart } = useProductTile(product, beforeAddToCart);

  return (
    <div css={productTileStyle(open)}>
      <section>
        <div css={availabilityStatusStyle}>
          {stockStatus && (
            <AvailabilityStatus
              status={stockStatus.status}
              nextPossibleDeliveryDate={stockStatus?.nextPossibleDeliveryDate}
              locale={locale}
              translations={{
                available,
                low_stock,
                next_possible_delivery,
                out_of_stock,
                not_available,
              }}
            />
          )}
        </div>

        <div css={titleStyle}>
          <p
            css={bigTextStyle}
            data-test="itemNumber"
          >
            {product instanceof ReplenishmentProductBox ? product.itemNumber : '—'}
          </p>
          <p
            css={mediumTextStyle}
            dangerouslySetInnerHTML={{ __html: name || '' }}
          />
          <p css={themeStyle}>{theme}</p>
        </div>

        <ProductTileTags
          lifecycles={lifecycles}
          tags={tags}
          openOrderInfo={openOrderInfo}
          foundInCart={foundInCart.found}
          locale={locale}
          itemNumber={itemNumber}
        />
      </section>
      <section css={infoRowStyle}>
        <ul css={infoRowListStyle}>
          <li>
            <p>{launch_date}:</p>
            <p>
              <Date locale={locale}>
                {product instanceof ReplenishmentProductBox ? product.launchDate : null}
              </Date>
            </p>
          </li>
          <li>
            <p>{exit_date}:</p>
            <p>
              <Date locale={locale}>
                {product instanceof ReplenishmentProductBox ? product.exitDate : null}
              </Date>
            </p>
          </li>
          {!(product instanceof ReplenishmentProductBillOfMaterial) && (
            <li>
              <p>{recommended_retail_price}:</p>
              <p>
                <Price locale={locale}>{product.recommendedRetailPrice}</Price>
              </p>
            </li>
          )}
          <li>
            <p>{price_per_piece}:</p>
            <p>
              <Price locale={locale}>{price}</Price>
            </p>
          </li>
          <li>
            <p>{pieces_per_case_pack}:</p>
            <p>{piecesPerCasePack || '—'}</p>
          </li>
          <li>
            <p>{age}:</p>
            <p>{product instanceof ReplenishmentProductBox ? product.ageGroup : '—'}</p>
          </li>
        </ul>
        <ul css={infoRowListStyle}>
          <li>
            <p>{number_of_bricks}:</p>
            <p>{product instanceof ReplenishmentProductBox ? product.pieces : '—'}</p>
          </li>
          <li>
            <p>{barcode}:</p>
            <p>{ean}</p>
          </li>
          <li>
            <p>{gross_weight}:</p>
            {weight ? (
              <p>
                {weight.amount} {weight.unit}
              </p>
            ) : (
              <p>—</p>
            )}
          </li>
          <li>
            <p>{box_dimensions}:</p>
            <p>
              {dimensions
                ? `${dimensions.height} / ${dimensions.width} / ${dimensions.length} ${dimensions.unit}`
                : '—'}
            </p>
          </li>
        </ul>
        <Container
          flex={{ justifyContent: 'right ' }}
          margin={{ marginLeft: 'unset', marginRight: 'unset' }}
          padding={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <ContentHubButton
            materialId={materialId}
            context="productDetails"
          />
        </Container>
      </section>

      <section>
        <ProductTileBottom
          stockStatus={stockStatus}
          price={price}
          piecesPerCasePack={piecesPerCasePack}
          onAddToCart={addToCart}
          locale={locale}
        />
      </section>
    </div>
  );
};
