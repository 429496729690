import 'normalize.css';
import './../assets/cssReset.css';
import './../assets/App.css';

import { css } from '@emotion/react';
import { ApmPageTransaction } from '@lego/b2b-unicorn-apm';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { PageViewTracker } from '@lego/b2b-unicorn-ui-analyticscontext';
import { DocumentTitle, NotificationsProvider } from '@lego/b2b-unicorn-ui-components';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Snackbars } from '../shared/Components/Notifications';
import { Footer } from './components/Footer/Footer';
import { DOCUMENT_TITLE } from './constants';
import CheckoutFlowLayout from './layouts/CheckoutFlowLayout';
import InfoPageLayout from './layouts/info/InfoPageLayout';
import PageNotFoundLayout from './layouts/PageNotFoundLayout';
import { CheckoutConfirmationPage } from './pages/CheckoutConfirmationPage';
import { LaunchRouter } from './pages/launch/LaunchRouter';
import { ManageRouter } from './pages/manage/ManageRouter';
import ProductDetailsRoute from './pages/ProductDetailsRoute';
import { PromotionRouter } from './pages/promotion/PromotionRouter';
import ProductListPage from './pages/shop/ProductListPage';
import ScrollToTop from './utils/ScrollToTop';

const appContainerStyle = css({
  minHeight: '100vh',
});

const documentTitles = {
  '/': [DOCUMENT_TITLE.PRODUCT_LIST, DOCUMENT_TITLE.MAIN],
  '/catalog': [DOCUMENT_TITLE.PRODUCT_DETAILS, DOCUMENT_TITLE.MAIN],
  '/launch': [DOCUMENT_TITLE.LAUNCH.OVERVIEW, DOCUMENT_TITLE.LAUNCH.MAIN],
  '/info': [DOCUMENT_TITLE.INFO, DOCUMENT_TITLE.MAIN],
  '/manage': [DOCUMENT_TITLE.MANAGE.MAIN, DOCUMENT_TITLE.MAIN],
  '/manage/order': [
    DOCUMENT_TITLE.MANAGE.ORDER_DETAILS,
    DOCUMENT_TITLE.MANAGE.MAIN,
    DOCUMENT_TITLE.MAIN,
  ],
  '/manage/invoices': [DOCUMENT_TITLE.MANAGE.INVOICES, DOCUMENT_TITLE.MAIN],
  '/manage/invoice': [
    DOCUMENT_TITLE.MANAGE.INVOICE_DETAILS,
    DOCUMENT_TITLE.MANAGE.INVOICES,
    DOCUMENT_TITLE.MAIN,
  ],
  '/manage/upcoming-deliveries': [DOCUMENT_TITLE.MANAGE.UPCOMING_DELIVERIES, DOCUMENT_TITLE.MAIN],
  '/404-page-not-found': [DOCUMENT_TITLE.PAGE_NOT_FOUND, DOCUMENT_TITLE.MAIN],
};

const RootRouter = () => {
  useOnPreferredLanguageChange(['footer']);

  useEffect(() => {
    GlobalLoadingService.remove(window.firstLoaderId);
  }, []);

  return (
    <NotificationsProvider>
      <Snackbars />
      <BrowserRouter>
        <DocumentTitle titles={documentTitles} />
        <PageViewTracker blacklistedRoutes={['/checkout']} />
        {/* ScrollToTop is used for edge cases when using back and forth buttons in the browser. It's related to manual scrollRestoration in index.html. */}
        <div css={appContainerStyle}>
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path={'/'}
            >
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <ProductListPage />
                </ApmPageTransaction>
              )}
            </Route>
            <Route
              exact
              path={'/checkout'}
            >
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <CheckoutFlowLayout />
                </ApmPageTransaction>
              )}
            </Route>
            <Route
              exact
              path={'/checkout/:orderNumber(\\d+)/confirmation'}
            >
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <CheckoutConfirmationPage />
                </ApmPageTransaction>
              )}
            </Route>
            <Route
              exact
              path={'/catalog/:materialId(\\d+)'}
            >
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <ProductDetailsRoute />
                </ApmPageTransaction>
              )}
            </Route>
            <Route path={'/launch'}>
              <LaunchRouter />
            </Route>
            <Route path={'/promotion'}>
              <PromotionRouter />
            </Route>
            <Route path={'/manage'}>
              <ManageRouter />
            </Route>
            <Route
              exact
              path={'/info'}
            >
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <InfoPageLayout />
                </ApmPageTransaction>
              )}
            </Route>
            <Route>
              {({ match }) => (
                <ApmPageTransaction match={match}>
                  <PageNotFoundLayout />
                </ApmPageTransaction>
              )}
            </Route>
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </NotificationsProvider>
  );
};

export default RootRouter;
