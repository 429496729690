import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentProductsProduct } from '@lego/b2b-unicorn-data-access-layer';

import { QuickAddSearchResultsItem } from './';
import { MIN_SEARCH_CHARS, RESULTS_LIMIT } from './';
import { dropdownStyle, feedbackStyle } from './styles';

interface IQuickAddSearchResults {
  selectedProduct?: ReplenishmentProductsProduct;
  query: string;
  isSearchError: boolean;
  isSearchLoading: boolean;
  isSearchDebounced: boolean;
  results: ReplenishmentProductsProduct[];
  selectProduct: (product: ReplenishmentProductsProduct) => void;
}

export const QuickAddSearchResults = ({
  selectedProduct,
  query,
  isSearchError,
  isSearchLoading,
  isSearchDebounced,
  results,
  selectProduct,
}: IQuickAddSearchResults) => {
  const { search_min_chars, search_error, search_loading, quick_order_search_no_results } =
    useLabels();

  let searchFeedbackContent;

  if (query && query.length < MIN_SEARCH_CHARS) {
    searchFeedbackContent = search_min_chars;
  } else if (isSearchError) {
    searchFeedbackContent = search_error;
  } else if (isSearchLoading || isSearchDebounced) {
    searchFeedbackContent = search_loading;
  } else if (results?.length === 0) {
    searchFeedbackContent = quick_order_search_no_results;
  }
  const shouldShowFeedback = query && searchFeedbackContent && !selectedProduct;

  const shouldShowResults = !shouldShowFeedback && !selectedProduct && query && results?.length > 0;

  return query ? (
    <div css={dropdownStyle}>
      {shouldShowFeedback && (
        <div
          css={feedbackStyle}
          id="quickAddSearchFeedback"
        >
          {searchFeedbackContent}
        </div>
      )}
      {shouldShowResults &&
        results.slice(0, RESULTS_LIMIT).map((product, index) => (
          <QuickAddSearchResultsItem
            selectThisItem={() => selectProduct(product)}
            key={product.materialId}
            index={index}
            product={product}
            numberOfResults={results.length}
          />
        ))}
    </div>
  ) : null;
};
