import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItemRemoved, Money, PaymentMethod } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Button,
  Container,
  ContentSystemFeedback,
  GoBackLink,
  Spacer,
  Stepper,
  SystemFeedbackType,
  Table,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { commonSubHeaderStyle, pagePaddingDesktopStyle } from '../../common/styles';
import { CheckoutStep } from '../../common/types';
import OrderRemovedItems from '../OrderRemovedItems';
import OrderPreviewSummary from './OrderPreviewSummary';
import { ResetActionButton } from './ResetActionButton';
import { ResetActionWarning } from './ResetActionWarning';
import { SkeletonItemsTable } from './SkeletonItemsTable';

const subHeaderStyle = css(commonSubHeaderStyle, {
  fontWeight: font.weight.bold,
});

const actionsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

interface IRemoveAllPopupText {
  header: string;
  content: string;
}

interface MinimalOrderItem {
  quantity: number;
  product: {
    materialId: number;
    piecesPerCasePack: number;
    theme: string;
    price: {
      estimatedNetInvoicedPrice: number;
      netInvoicedPrice?: number | null;
      currency: string;
    };
  };
}

interface IOrderPreview<T extends MinimalOrderItem> {
  items: T[];
  itemRowRender: (item: T) => React.ReactNode;
  itemRowHeaderRender: React.ReactNode;
  emptyPreviewFeedbackRender?: React.ReactNode;
  actionsRender?: React.ReactNode;
  itemsLoading: boolean;
  orderPreviewError: boolean;
  isCheckoutDisabled: boolean;
  onGoToCheckoutClick: () => void;
  onResetAction: () => void;
  resetWarningText: IRemoveAllPopupText;
  resetActionType?: 'REMOVE_ALL' | 'RESET';
  removedItems?: CartItemRemoved[] | null;
  closeRemovedItemsPopupHandler?: () => void;
  displayEmptyPreviewFeedback?: boolean;
  readonly?: boolean;
  minimumOrderValue: Money;
}

const OrderPreview = <T extends MinimalOrderItem>({
  items,
  itemRowRender,
  itemRowHeaderRender,
  emptyPreviewFeedbackRender,
  actionsRender,
  itemsLoading,
  orderPreviewError,
  removedItems,
  isCheckoutDisabled,
  onGoToCheckoutClick,
  closeRemovedItemsPopupHandler,
  resetWarningText,
  resetActionType = 'REMOVE_ALL',
  onResetAction,
  readonly = false,
  minimumOrderValue,
}: IOrderPreview<T>) => {
  const [resetActionWarning, setResetActionWarning] = useState(false);
  const {
    button_checkout,
    button_go_back,
    order_details,
    content_system_feedback_error,
    cart,
    confirmation,
    payment,
  } = useLabels();
  const history = useHistory();

  const orderItemsLength = (items && items.length) || 0;

  const selectedCustomer = useSelectedCustomer();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const reset = () => {
    onResetAction();
    setResetActionWarning(false);
  };

  const renderResetActionWarning = () => {
    return (
      <ResetActionWarning
        open={resetActionWarning}
        translations={resetWarningText}
        onClose={() => setResetActionWarning(false)}
        onResetAction={reset}
      />
    );
  };

  const renderOrderRemovedItems = () => {
    return (
      !!removedItems &&
      removedItems.length > 0 &&
      closeRemovedItemsPopupHandler && (
        <OrderRemovedItems
          items={removedItems}
          closePopup={closeRemovedItemsPopupHandler}
        />
      )
    );
  };

  let checkoutSteps = [CheckoutStep.PREVIEW, CheckoutStep.CHECKOUT, CheckoutStep.CONFIRMATION];
  let checkoutLabels = [cart, button_checkout, confirmation];
  if (userPaysWithCard) {
    checkoutSteps = [
      CheckoutStep.PREVIEW,
      CheckoutStep.CHECKOUT,
      CheckoutStep.PAYMENT,
      CheckoutStep.CONFIRMATION,
    ];
    checkoutLabels = [cart, button_checkout, payment, confirmation];
  }

  return (
    <>
      {!orderPreviewError && (
        <>
          {renderOrderRemovedItems()}
          {!itemsLoading && orderItemsLength === 0 && emptyPreviewFeedbackRender}
          <>
            {(orderItemsLength > 0 || itemsLoading) && (
              <>
                {renderResetActionWarning()}
                {!readonly && (
                  <>
                    <Stepper
                      activeStep={CheckoutStep.PREVIEW}
                      steps={checkoutSteps}
                      stepLabels={checkoutLabels}
                    />
                    <Container
                      padding={{ paddingLeft: baseSpacing * 2, paddingRight: baseSpacing * 2 }}
                      paddingMedium={{
                        [media.medium]: {
                          paddingLeft: baseSpacing * 2,
                          paddingRight: baseSpacing * 2,
                        },
                      }}
                    >
                      <OrderPreviewSummary
                        cartItems={items}
                        minimumOrderValue={minimumOrderValue}
                        loading={itemsLoading}
                      >
                        {orderItemsLength !== undefined && (
                          <Button
                            onClick={onGoToCheckoutClick}
                            disabled={isCheckoutDisabled}
                          >
                            {button_checkout}
                          </Button>
                        )}
                      </OrderPreviewSummary>
                    </Container>
                    <Spacer />
                    <div css={[subHeaderStyle, pagePaddingDesktopStyle]}>
                      {order_details}
                      <div css={actionsWrapperStyle}>
                        {actionsRender}
                        <ResetActionButton
                          resetActionType={resetActionType}
                          onClick={() => setResetActionWarning(true)}
                          disabled={orderItemsLength === 0 || itemsLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div css={pagePaddingDesktopStyle}>
                  {itemsLoading ? (
                    <SkeletonItemsTable />
                  ) : (
                    <Table>
                      {itemRowHeaderRender}
                      <tbody>{items && items.map((i) => itemRowRender(i))}</tbody>
                    </Table>
                  )}
                </div>
              </>
            )}
          </>
        </>
      )}
      {orderPreviewError && (
        <ContentSystemFeedback
          type={SystemFeedbackType.ERROR}
          text={content_system_feedback_error}
        >
          <GoBackLink
            onClick={() => history.goBack()}
            text={button_go_back}
          />
        </ContentSystemFeedback>
      )}
    </>
  );
};

export default OrderPreview;
