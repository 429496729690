import { css, keyframes } from '@emotion/react';
import { tokens } from '@lego/core-colors';

import { designToken } from '../theme';

const bg_position = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: 50% 0;
    }
`;

export const skeletonCss = (type: keyof typeof designToken.skeleton) =>
  css({
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    backgroundClip: 'padding-box',
    cursor: 'default',
    color: 'transparent',
    pointerEvents: 'none',
    userSelect: 'none',
    borderRadius: 4,
    flexShrink: '0',
    backgroundImage: `linear-gradient(90deg,${tokens.color.core.offWhite} 0%,${designToken.skeleton[type]} 20%,${designToken.skeleton[type]} 80%,${tokens.color.core.offWhite} 100%)`,
    backgroundSize: '400% 100%',
    animationName: bg_position,
    animationTimingFunction: 'ease-in-out',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  });

const height: number = 188;
export const skeletonImageStyle = css({
  height,
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: 250,
    height,
    top: 0,
    backgroundImage: `linear-gradient(90deg,${tokens.color.core.offWhite} 0%,${designToken.transparent.default} 20%,${designToken.transparent.default} 80%,${tokens.color.core.offWhite} 100%)`,
    backgroundSize: '400% 100%',
    animationName: bg_position,
    animationTimingFunction: 'ease-in-out',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
  '> svg': {
    height,
    width: 'auto',
  },
});
