import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

import { formCommonInputStyle } from './';

export const quantityWrapperStyle = css({
  position: 'relative',
  boxShadow: `inset 0px 2px 0px rgba(0, 0, 0, 0.1)`,
  width: '100%',
  [media.small]: {
    flexBasis: `calc(50% - ${baseSpacing / 2}px)`,
  },
  [media.medium]: {
    display: 'flex',
    flexBasis: 'auto',
    width: 230,
    boxShadow: 'none',
    span: {
      lineHeight: `${baseSpacing * 7}px`,
    },
  },
});

export const quantityInputStyle = [
  formCommonInputStyle,
  css({
    borderRadius: 4,
    boxShadow: `inset 0px 2px 0px rgba(0, 0, 0, 0.1), -13px 0 0 -12px ${colors.klik.slate400}`,
    opacity: 1 /* To prevent the input field from looking 'highlighted' in iOS Safari (0.4 opacity on top of blue background). */,
    backgroundColor: designToken.background.blue,
    '&::placeholder': {
      color: designToken.text.muted,
    },
    [media.medium]: {
      borderRadius: 0,
    },
  }),
];

export const quantitySuffixStyle = css({
  position: 'absolute',
  right: baseSpacing * 3,
  top: 0,
  bottom: 0,
  width: 150,
  lineHeight: `${baseSpacing * 5}px`,
  color: designToken.text.default,
  textAlign: 'right',
  fontSize: font.size.small,
  pointerEvents: 'none',
});
