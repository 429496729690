import { Base64Url } from './base64url';

/**
 * Returns a Base64Url encoded string used for state transformation onto the payment page
 *
 * @param confirmationPath
 * @param orderNumber
 * @param customerId
 */
export default (confirmationPath: string, orderNumber: number, customerId: number) =>
  Base64Url.encode(
    JSON.stringify({
      confirmationPath,
      orderNumber,
      customerId,
    })
  );
