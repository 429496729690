import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';

import { IMAGE_CONTAINER_HEIGHT, THUMBNAIL_CONTAINER_WIDTH } from '../Constants';
import { Arrows } from './';
import { CarouselThumbnail } from './CarouselThumbnail';

const containerStyle = css({
  maxWidth: 1064,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [media.medium]: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
  },
});

const imageContainerStyle = css({
  backgroundColor: colors.white,
  borderRadius: 6,
  height: 375,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: baseSpacing * 8,
  '> img, svg': {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  [media.medium]: {
    height: IMAGE_CONTAINER_HEIGHT,
    borderRadius: '6px 0 0 6px',
  },
});

const thumbnailsStyle = css({
  marginTop: baseSpacing * 2,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  borderLeftColor: designToken.background.gray,
  borderLeftStyle: 'solid',
  borderLeftWidth: 3,
  [media.medium]: {
    flexDirection: 'row',
    alignContent: 'flex-start',
    height: IMAGE_CONTAINER_HEIGHT,
    width: THUMBNAIL_CONTAINER_WIDTH,
    overflowY: 'auto',
    marginTop: 0,
    '> button': {
      borderRadius: 0,
    },
    backgroundColor: colors.white,
  },
});

export type ImageUrl = {
  thumbnailUrl: string;
  fullSizeUrl: string;
};

type ProductCarouselProps = {
  isOfTypeBox: boolean;
  imageUrls: ImageUrl[];
};

export const ProductCarousel: React.FC<ProductCarouselProps> = ({ isOfTypeBox, imageUrls }) => {
  const [activeImgIndex, setActiveImgIndex] = useState(0);
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(
    Array<boolean>(imageUrls.length).fill(false)
  );

  const numThumbnailsLoaded = isThumbnailLoaded.filter(Boolean).length; // Filter keeps truthy values only.

  const markThumbnailLoaded = (index: number) => {
    // To avoid concurrent updates constructing a new array based on stale data, make sure to use the version of setting
    // state that depends on the previous value of the state.
    setIsThumbnailLoaded((prevState) => {
      const nextIsThumbnailLoaded = [...prevState];
      nextIsThumbnailLoaded[index] = true;
      return nextIsThumbnailLoaded;
    });
  };

  /**
   * Skip the next image if it is not loaded. If no images are loaded, don't do anything to avoid infinite looping.
   * @param direction -1 for "left", 1 for "right"
   */
  const onArrowClickSkippingUnloadedImages = (direction: number) => {
    if (numThumbnailsLoaded === 0) {
      return;
    }

    // Calculates the next index and ensures that we wrap around to start over in either direction.
    const calculateNextIndex = (current: number) => {
      const next = current + direction;
      if (next < 0) {
        return isThumbnailLoaded.length - 1;
      } else if (next >= isThumbnailLoaded.length) {
        return 0;
      } else {
        return next;
      }
    };

    // Find the next index which is loaded. Because of the check above, we are sure at least one is loaded; it might be
    // the one already selected in which case this action does nothing.
    let nextIndex = calculateNextIndex(activeImgIndex);
    while (!isThumbnailLoaded[nextIndex]) {
      nextIndex = calculateNextIndex(nextIndex);
    }

    setActiveImgIndex(nextIndex);
  };

  const handleLeftArrowClick = () => {
    onArrowClickSkippingUnloadedImages(-1);
  };

  const handleRightArrowClick = () => {
    onArrowClickSkippingUnloadedImages(1);
  };

  return (
    <div css={containerStyle}>
      <div css={imageContainerStyle}>
        <ProductImage
          src={isOfTypeBox ? imageUrls[activeImgIndex].fullSizeUrl : imageUrls[0].fullSizeUrl}
          alt="Product presentation."
          showSpinner={true}
        />
        {isOfTypeBox && numThumbnailsLoaded > 1 && (
          <Arrows
            onLeftClick={handleLeftArrowClick}
            onRightClick={handleRightArrowClick}
          />
        )}
      </div>
      {isOfTypeBox && (
        <div css={thumbnailsStyle}>
          {imageUrls.map((imageUrl, index) => (
            <CarouselThumbnail
              key={index}
              index={index}
              imageUrl={imageUrl.thumbnailUrl}
              active={activeImgIndex === index}
              onClick={setActiveImgIndex}
              onLoad={markThumbnailLoaded}
            />
          ))}
        </div>
      )}
    </div>
  );
};
