import { StockStatusTag } from '@lego/b2b-unicorn-data-access-layer';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import React from 'react';

export const isProductDisabled = (product: { stockStatus: { status: StockStatusTag } }) => {
  return (
    product.stockStatus?.status === StockStatusTag.NotAvailable ||
    product.stockStatus?.status === StockStatusTag.OutOfStock
  );
};

export const arrowKeypressNavigation = (e: React.KeyboardEvent<HTMLElement>) => {
  if (e.key === KEYCODE_STRING.ARROW_UP || e.key === KEYCODE_STRING.ARROW_DOWN) {
    const navigableArray = document.querySelectorAll('[data-arrow-keypress-navigable-index]');
    const currentIndex = parseInt(
      (e.nativeEvent.target as HTMLElement).dataset.arrowKeypressNavigableIndex || '0',
      10
    );
    if (navigableArray.length > 1) {
      if (e.key === KEYCODE_STRING.ARROW_UP && currentIndex > 0) {
        e.preventDefault();
        (navigableArray[currentIndex - 1] as HTMLElement).focus();
      } else if (e.key === KEYCODE_STRING.ARROW_DOWN && currentIndex + 1 < navigableArray.length) {
        e.preventDefault();
        (navigableArray[currentIndex - 1] as HTMLElement).focus();
      }
    }
  }
};
