import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ContentSystemFeedback, SystemFeedbackType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

export const CatalogErrorFeedback: React.FC = () => {
  const { content_system_feedback_error } = useLabels();
  return (
    <ContentSystemFeedback
      text={content_system_feedback_error}
      type={SystemFeedbackType.ERROR}
    />
  );
};
