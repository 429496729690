import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { quantityOnChangeHandler } from '@lego/b2b-unicorn-ui-utils';

import { quantityInputStyle, quantitySuffixStyle, quantityWrapperStyle } from './styles';

interface IQuickAddQuantity {
  disabled: boolean;
  pieces?: number;
  quantity: number;
  quantityInputRef: React.MutableRefObject<HTMLInputElement | null>;
  setQuantity: (quantity: number) => void;
  handleAddToCart: () => void;
}

export const QuickAddQuantity = ({
  disabled,
  quantity,
  quantityInputRef,
  setQuantity,
  pieces,
  handleAddToCart,
}: IQuickAddQuantity) => {
  const { quantity: quantity_label, pcs, cases } = useLabels();

  const addToCartImmediatelyOnEnterKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (quantity && e.key === KEYCODE_STRING.ENTER) {
      handleAddToCart();
    }
  };

  return (
    <div css={quantityWrapperStyle}>
      <input
        css={quantityInputStyle}
        disabled={disabled}
        readOnly={!pieces}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength={4}
        placeholder={pieces ? '0' : quantity_label}
        value={quantity == 0 ? '' : quantity}
        ref={quantityInputRef}
        aria-label="Quick order quantity."
        tabIndex={0}
        onFocus={(e) => e.target.select()}
        onKeyDown={addToCartImmediatelyOnEnterKeypress}
        onChange={(e) => quantityOnChangeHandler(quantity, setQuantity, e)}
      />
      {pieces && (
        <span css={quantitySuffixStyle}>
          {cases} / {pieces * quantity} {pcs}
        </span>
      )}
    </div>
  );
};
