import { css } from '@emotion/react';
import { Money } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { mapToMoneyOrNull } from '@lego/b2b-unicorn-ui-utils';
import React from 'react';

const detailsListItemStyle = css({
  width: '100%',
  margin: 0,
  padding: 0,
  paddingTop: baseSpacing / 2,
  overflow: 'auto',
  '> span:first-of-type': {
    marginRight: baseSpacing,
  },
  '> span:last-of-type': {
    float: 'right',
    textAlign: 'right',
    fontFeatureSettings: '"tnum"',
    fontVariantNumeric: 'tabular-nums',
  },
});

interface FinalPriceDialogContentListItemProps {
  value?: Money | number | null;
  label: string;
  currency: string | false;
}
export const FinalPriceDialogContentListItem: React.FC<FinalPriceDialogContentListItemProps> = ({
  label,
  value,
  currency,
}) => {
  const selectedCustomer = useSelectedCustomer();

  return (
    <p css={detailsListItemStyle}>
      <span>{label}</span>
      <span>
        {typeof value === 'number' || value === null ? (
          <Price
            locale={selectedCustomer.locale}
            fallbackValue={'-'}
          >
            {mapToMoneyOrNull(value, currency || undefined)}
          </Price>
        ) : (
          <Price
            locale={selectedCustomer.locale}
            fallbackValue={'-'}
          >
            {value}
          </Price>
        )}
      </span>
    </p>
  );
};
