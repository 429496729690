import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ShipTo } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  DateInput,
  designToken,
  FlexBox,
  Icon,
  IconType,
  SelectBox,
  Spacer,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { labelTextStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { addBusinessDays, formatISO9075, isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import React, { useEffect, useMemo } from 'react';

import { IDeliveryInfo } from '../../../common/types';

type DeliveryInformationProps = {
  disabledFromOutside: boolean;
  shipTos: ShipTo[];
  shippingAddressId?: number;
  shipToOnChangeHandler: (shippingId: number) => void;
  minDate?: Date;
  maxDate?: Date;
  requestedDeliveryDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onValidInfoHandler: (info?: IDeliveryInfo) => void;
  excludedDateIntervals: NonNullable<
    React.ComponentProps<typeof DateInput>['excludeDateIntervals']
  >;
};

const DeliveryInformation: React.FC<DeliveryInformationProps> = ({
  disabledFromOutside,
  shipTos,
  shippingAddressId,
  shipToOnChangeHandler,
  minDate,
  maxDate,
  requestedDeliveryDate: requestedDeliveryDateExternal,
  onDateChange,
  onValidInfoHandler,
  excludedDateIntervals,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    shipping_address,
    shipping_address_hint,
    shipping_address_select_placeholder,
    delivery_info_rdd_header,
    requested_delivery_date_select_placeholder,
  } = useLabels();
  const requestedDeliveryDate = useMemo(() => {
    if (requestedDeliveryDateExternal === null) {
      return null;
    }

    for (const interval of excludedDateIntervals) {
      if (
        (isAfter(requestedDeliveryDateExternal, interval.start) &&
          isBefore(requestedDeliveryDateExternal, interval.end)) ||
        isSameDay(requestedDeliveryDateExternal, interval.start) ||
        isSameDay(requestedDeliveryDateExternal, interval.end)
      ) {
        const requestedDeliveryDate = addBusinessDays(interval.end, 1);
        onDateChange(requestedDeliveryDate);
        return requestedDeliveryDate;
      }
    }

    return requestedDeliveryDateExternal;
  }, [excludedDateIntervals, onDateChange, requestedDeliveryDateExternal]);

  //client-side validation
  const hasValidShipTos = shipTos && !!shipTos.length;
  const isShippingAddressValid = !!shippingAddressId && typeof shippingAddressId !== 'string';
  const isDeliveryDateValid = requestedDeliveryDate && isValid(requestedDeliveryDate);
  const isFormValid = hasValidShipTos && isShippingAddressValid && isDeliveryDateValid;

  const deliveryInfo = useMemo(
    () =>
      isFormValid
        ? {
            customerId: selectedCustomer.id,
            shipToId: shippingAddressId,
            requestedDeliveryDate: formatISO9075(requestedDeliveryDate, {
              representation: 'date',
            }),
          }
        : undefined,
    [isFormValid, requestedDeliveryDate, selectedCustomer.id, shippingAddressId]
  );

  useEffect(() => {
    onValidInfoHandler(deliveryInfo);
  }, [deliveryInfo, onValidInfoHandler]);

  const shipToIsDisabled = !hasValidShipTos || disabledFromOutside;
  const shipToHasWarning = !shippingAddressId && shipTos?.length > 1;

  const datePickerIsDisabled =
    !hasValidShipTos || !isShippingAddressValid || disabledFromOutside || !minDate;

  return (
    <>
      <label css={labelTextStyle}>{shipping_address}</label>
      <div>
        <SelectBox
          label={shipping_address_select_placeholder}
          values={shipTos.map((shipTo) => {
            return {
              displayText: `${shipTo.id}-${shipTo.name}-${shipTo.city}`,
              value: shipTo.id,
            };
          })}
          selectedValues={shippingAddressId}
          optionsChangeHandler={(value) => shipToOnChangeHandler(value as number)}
          disabled={shipToIsDisabled}
          invalid={shipToHasWarning}
        />
        {shipToHasWarning && (
          <FlexBox alignItems={'center'}>
            <Icon
              type={IconType.INVALID}
              height={'1em'}
              style={{ flexShrink: 0 }}
              color={designToken.error.default}
            />
            &nbsp;
            <Typography designToken={'error.default'}>{shipping_address_hint}</Typography>
          </FlexBox>
        )}
      </div>
      <Spacer multiplier={2} />
      <label css={labelTextStyle}>{delivery_info_rdd_header}</label>
      <DateInput
        minDate={minDate}
        maxDate={maxDate}
        date={requestedDeliveryDate}
        onDateChange={onDateChange}
        disabled={datePickerIsDisabled}
        locale={selectedCustomer.locale}
        placeholder={requested_delivery_date_select_placeholder}
        excludeDateIntervals={excludedDateIntervals}
      />
    </>
  );
};

export default DeliveryInformation;
