import { ImageUrl } from '../../../shop/components/ProductDetailsPage/components';

export enum ImageType {
  MODEL_IMAGE = 1,
  MAIN_BOX = 3,
  MAIN_BOX_BACK = 7,
  ALT_MODEL_LINE_UP = 14,
  ALT_MODEL_PRIMARY = 15,
  ALT_MODEL_SECONDARY = 17,
  PDP_FEATURE_1 = 48,
  PDP_FEATURE_2 = 49,
  PDP_FEATURE_3 = 50,
}

const baseImageUrl = 'https://image.content.lego.com/public/image/';

export const getImageUrls = (materialId: number, imagesTypes: ImageType[]): ImageUrl[] => {
  return imagesTypes.map((imageType) => {
    return {
      thumbnailUrl: getImageUrl(materialId, imageType, 120),
      fullSizeUrl: getImageUrl(materialId, imageType, 600),
    };
  });
};

export const getImageUrl = (materialId: number, imageType: ImageType, height = 120) => {
  return baseImageUrl + `${materialId}/${imageType}.jpg?height=${height}`;
};
