import { css } from '@emotion/react';
import { Skeleton, SkeletonImage } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';

import { TILE_WIDTH } from '../../../../../../../constants';
import { skeletonBottomContainerStyle } from '../../../../../../../styles';

const tileStyle = css({
  width: TILE_WIDTH,
  height: 468,
  padding: baseSpacing,
  paddingBottom: 10,
  textAlign: 'left',
  backgroundColor: colors.white,
  borderRadius: 8,
  margin: 0,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
});

export const SkeletonShopTile = () => (
  <article css={tileStyle}>
    <p css={{ height: 24, width: 70, marginTop: 2, marginLeft: 2, '> div': { borderRadius: 12 } }}>
      <Skeleton token={'secondary'} />
    </p>
    <SkeletonImage />
    <p css={{ height: 20, width: 90, marginBottom: 10 }}>
      <Skeleton />
    </p>
    <p css={{ height: 16, width: 200, marginBottom: 70 }}>
      <Skeleton />
    </p>
    <p css={{ height: 30, width: '100%', marginBottom: 9 }}>
      <Skeleton />
    </p>
    <p css={{ height: 30, width: 140, marginBottom: 9 }}>
      <Skeleton />
    </p>
    <div css={skeletonBottomContainerStyle}>
      <p css={{ height: 42, width: '50%', marginRight: 8 }}>
        <Skeleton />
      </p>
      <p css={{ height: 42, width: '50%' }}>
        <Skeleton token={'primary'} />
      </p>
    </div>
  </article>
);
