import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

export const tableStyle = (showTable: boolean) =>
  css({
    width: '100%',
    display: showTable ? 'table' : 'none',
    paddingLeft: 0,
    paddingRight: 0,
    borderCollapse: 'collapse',
    [media.small]: {
      paddingLeft: baseSpacing * 4,
      paddingRight: baseSpacing * 4,
    },
    [media.medium]: {
      padding: 0,
      display: 'table',
    },
  });

export const tableHeadStyle = css({
  display: 'none',
  [media.medium]: {
    display: 'table-header-group',
  },
});

export const tableHeaderStyle = css({
  fontWeight: font.weight.bold,
  color: designToken.text.muted,
  background: designToken.background.gray,
  height: 50,
  padding: baseSpacing,
  textAlign: 'left',
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: designToken.border.subdued,
});

export const itemIDHeaderStyle = css(tableHeaderStyle, {
  width: 100,
});

export const tableHeaderCenterStyle = css(tableHeaderStyle, {
  textAlign: 'center',
});

export const tableHeaderRightStyle = css(tableHeaderStyle, {
  textAlign: 'right',
});

export const tableHeaderSortOptionStyle = css({
  display: 'flex',
  alignItems: 'center',
  '> button': {
    marginRight: baseSpacing / 2,
  },
});

export const tableHeaderSortOptionRightStyle = css(tableHeaderSortOptionStyle, {
  justifyContent: 'end',
});

export const tableRowStyle = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: baseSpacing * 3,
  [media.medium]: {
    display: 'table-row',
    position: 'initial',
    height: 57,
  },
});

export const tableRowCellStyle = css({
  display: 'block',
  lineHeight: 1.7,
  [media.medium]: {
    display: 'table-cell',
    verticalAlign: 'middle',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: designToken.border.muted,
    padding: baseSpacing,
    '> svg': {
      height: 18,
      width: 18,
      marginBottom: -4,
    },
  },
});

export const tableRowCellCenterStyle = css(tableRowCellStyle, {
  [media.medium]: {
    textAlign: 'center',
  },
});

export const mobileCellWithColDataStyle = css({
  minHeight: 25,
  paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
  [media.medium]: {
    paddingLeft: baseSpacing,
  },
  '&:before': {
    display: 'block',
    position: 'absolute',
    right: 'calc(70% + 4px)',
    left: baseSpacing,
    maxWidth: '30%',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    content: 'attr(data-col)',
    overflow: 'hidden',
    [media.medium]: {
      display: 'none',
    },
  },
});

export const itemIdCellStyle = css({
  width: 100,
  position: 'absolute',
  right: '70%',
  top: 20,
  backgroundColor: 'transparent',
  border: 'none',
  textAlign: 'right',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [media.medium]: {
    position: 'static',
  },
  '&:focus': {
    outline: 'none',
  },
  '> span': {
    fontFeatureSettings: '"tnum"',
    fontVariantNumeric: 'tabular-nums',
  },
});

export const imageWrapperStyle = css({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  '> img, svg': {
    height: 30,
    width: 'auto',
  },
});

export const itemIdFontStyle = css({
  fontWeight: font.weight.bold,
  marginLeft: baseSpacing / 2,
});

export const nameCellStyle = css(tableRowCellStyle, {
  paddingTop: baseSpacing * 2.5,
  paddingBottom: baseSpacing * 2.5,
  paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
  paddingRight: 40,
  marginBottom: baseSpacing,
  borderTopColor: colors.klik.lightBlue200,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  [media.small]: {
    paddingRight: 100,
  },
  [media.medium]: {
    minWidth: 200,
    padding: 0,
    margin: 0,
    borderTop: 'none',
  },
});

export const tableRowCellNumberStyle = css({
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  [media.medium]: {
    textAlign: 'right',
    paddingRight: baseSpacing * 2,
  },
  [media.large]: {
    paddingRight: 60,
  },
});

export const transition = {
  transitionDuration: '100ms',
  transitionProperty: 'all',
  transitionTimingFunction: 'ease-in-out',
};

export const removeCellStyle = css(tableRowCellCenterStyle, {
  position: 'absolute',
  top: baseSpacing * 2,
  right: baseSpacing,
  [media.medium]: {
    position: 'static',
    '> button': {
      margin: 'auto',
    },
    svg: {
      height: 18,
      width: 18,
    },
  },
});
