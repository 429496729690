import { gql } from '@apollo/client';

export const LAUNCH_WINDOW = gql`
  query launchWindow($customerId: Int!, $year: Int!, $month: Int!) {
    launchWindow(customerId: $customerId, year: $year, month: $month) {
      windowType
      orderEndInstant
      products {
        materialId
        name
        piecesPerCasePack
        price {
          currency
          grossPrice
          estimatedNetInvoicedPrice
        }
        stockStatus {
          status
          nextPossibleDeliveryDate
        }
        theme
        ean
        ... on Box {
          itemNumber
          launchDate
          recommendedRetailPrice {
            amount
            currency
          }
        }
        ... on BillOfMaterial {
          launchDate
        }
      }
      orderBlockedProducts {
        materialId
        name
        piecesPerCasePack
        price {
          currency
          grossPrice
          estimatedNetInvoicedPrice
        }
        theme
        ... on Box {
          itemNumber
          launchDate
          recommendedRetailPrice {
            amount
            currency
          }
        }
        ... on BillOfMaterial {
          launchDate
        }
      }
    }
    getCart(
      customerId: $customerId
      removeObsoleteItems: false
      cartReference: { cartType: LAUNCH, launch: { month: $month, year: $year } }
    ) {
      items {
        ...cartItem
        product {
          ... on Box {
            launchDate
          }
          ... on BillOfMaterial {
            launchDate
          }
        }
      }
      ...cartKey
      minimumOrderValue {
        amount
        currency
      }
    }
  }
`;
