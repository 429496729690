import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { CartReferenceCartType, PaymentMethod } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { CheckoutPage } from '@lego/b2b-unicorn-ui-checkout-flow';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import paymentState from '../../utils/paymentState';
import { LaunchWindowDetailsParams } from './LaunchRouter';

export const LaunchWindowCheckout: React.FC = () => {
  const selectedCustomer = useSelectedCustomer();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const [checkoutStep, setCheckoutStep] = useState(true);

  const { endTransaction } = useApmTransaction();
  const { year: rawYear, month: rawMonth } = useParams<LaunchWindowDetailsParams>();
  const year = parseInt(rawYear, 10);
  const month = parseInt(rawMonth, 10);

  const history = useHistory();
  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Launch,
      launch: {
        month,
        year,
      },
    }),
    [month, year]
  );

  const handleOnLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  const handleGoBackClick = () => {
    history.replace(`/launch/${year}/${month}`);
  };

  const onSuccessfulOrderCreation = (orderNumber: number) => {
    const confirmationPath = `/launch/${year}/${month}/checkout/${orderNumber}/confirmation`;
    const state = paymentState(confirmationPath, orderNumber, selectedCustomer.id);
    if (userPaysWithCard) {
      GlobalLoadingService.add('checkout-redirect');
      setCheckoutStep(false);
      setTimeout(() => (window.location.href = `/payment/${orderNumber}?state=${state}`));
    } else {
      history.replace(confirmationPath);
    }
  };

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      {checkoutStep && (
        <CheckoutPage
          cartReference={cartReference}
          goBackHandler={handleGoBackClick}
          cookieDomain={COOKIE_DOMAIN}
          orderCreationSuccessHandler={onSuccessfulOrderCreation}
          onEmptyCart={handleGoBackClick}
          onCheckoutLoaded={handleOnLoaded}
        />
      )}
    </>
  );
};
