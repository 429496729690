import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import {
  useOptimisticUpdateReplenishmentCart,
  useReplenishmentCartSummary,
  useReplenishmentProducts,
} from '@lego/b2b-unicorn-data-access-layer/react/hooks/replenishment';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { NotificationType } from '@lego/b2b-unicorn-ui-components';
import { useNotifications } from '@lego/b2b-unicorn-ui-components';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
  arrowKeypressNavigation,
  isProductDisabled,
  MIN_SEARCH_CHARS,
  QuickAddButton,
  QuickAddQuantity,
  QuickAddSearchInput,
  QuickAddSearchResults,
  QuickAddSelectedProduct,
} from './';
import {
  backgroundStyle,
  formContentStyle,
  formWrapperStyle,
  headerStyle,
  productFormFieldWrapperStyle,
  quantityAndButtonStyle,
  snackbarProductNameStyle,
} from './styles';

export const QuickAdd = () => {
  const [query, setQuery] = useState<string>('');
  const [quantity, setQuantity] = useState<number>();
  const [selectedProduct, setSelectedProduct] = useState<ReplenishmentProductsProduct>();
  const [isSearchDebounced, setSearchDebounced] = useState<boolean>(false);
  const [animateAddButton, setAnimateAddButton] = useState<boolean>(false);
  const [isAddButtonBlocked, setIsAddButtonBlocked] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const animationTimeout = useRef<ReturnType<typeof setTimeout>>();
  const resetTimeout = useRef<ReturnType<typeof setTimeout>>();
  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();
  const currentProduct = useRef(selectedProduct);
  const materialId = selectedProduct?.materialId;

  const selectedCustomer = useSelectedCustomer();
  const { quick_add, snackbar_add_product_error } = useLabels();

  const { addSnackbar, snackbar } = useNotifications();

  const { trackEvent } = useAnalytics();

  const {
    refetch: findProducts,
    data: searchResponse,
    error: searchError,
    loading: isSearchLoading,
  } = useReplenishmentProducts(
    selectedCustomer.id,
    undefined, // No initial filters.
    true, // Don't trigger a query upfront.
    {
      fetchPolicy: 'no-cache', // The user is most likely doing unique searches each time so caching doesn't help.
    }
  );
  const { mutate: updateCart, error: updateCartError } = useOptimisticUpdateReplenishmentCart(
    selectedCustomer.id
  );
  const { data: cartResponse, refetch: refetchCart } = useReplenishmentCartSummary(
    selectedCustomer.id
  );

  const selectProduct = (product: ReplenishmentProductsProduct) => {
    setSelectedProduct(product);
    setQuantity(1);
  };

  const onKeyDownSearchInputHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYCODE_STRING.ENTER && searchResponse?.products.products?.length === 1) {
      selectProduct(searchResponse?.products.products[0]);
    } else {
      arrowKeypressNavigation(e);
    }
  };

  const selectSingleResultOnBlur = () => {
    if (searchResponse?.products?.products.length === 1) {
      !isProductDisabled(searchResponse?.products.products[0]) &&
        selectProduct(searchResponse?.products.products[0]);
    }
  };

  const closeDropdownOnBlur = (e: React.FocusEvent<HTMLElement>) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget) && !selectedProduct) {
      e.stopPropagation();
    } else if (!selectedProduct) {
      resetForm({ isEscapingForm: true });
    }
  };

  const closeDropdownOnEscape = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ESCAPE && !selectedProduct) {
      resetForm({ isEscapingForm: true });
    }
  };

  const resetForm = ({ isEscapingForm }: { isEscapingForm?: boolean } = {}) => {
    setQuery('');
    setQuantity(undefined);
    setSelectedProduct(undefined);
    if (!isEscapingForm) {
      searchInputRef.current?.focus();
    }
  };

  const reopenResults = () => {
    setQuantity(undefined);
    setSelectedProduct(undefined);
    searchInputRef.current?.focus();
  };

  const handleAddToCart = () => {
    setAnimateAddButton(true);
    setIsAddButtonBlocked(true);
    currentProduct.current = selectedProduct;

    if (quantity === undefined || !selectedProduct) {
      return;
    }

    const item = cartResponse?.getCart.items.find((item) => item.product.materialId === materialId);

    updateCart({
      productOrMaterialId: selectedProduct,
      quantity: item ? quantity + item.quantity : quantity,
    });

    resetTimeout.current = setTimeout(() => resetForm(), 1150);

    trackEvent({
      event: 'addToCartFromReplenishCatalogUsingQuickAdd',
      name: `Adding product ${materialId} to cart with Quick Add`,
    });
  };

  // fetching search results
  useEffect(() => {
    if (selectedCustomer.id && query.length >= MIN_SEARCH_CHARS) {
      setSearchDebounced(true);
      clearTimeout(debounceTimeout.current);

      debounceTimeout.current = setTimeout(() => {
        findProducts({
          customerId: selectedCustomer.id,
          filters: {
            query, // The input field only accepts numbers, so we can just pass the query string along.
          },
        });
        setSearchDebounced(false);
      }, 500);
    } else {
      setSearchDebounced(false);
      clearTimeout(debounceTimeout.current);
    }
  }, [debounceTimeout, setSearchDebounced, query, selectedCustomer.id, findProducts]);

  // automatically sets focus on quantity when user selects product
  useEffect(() => {
    selectedProduct && quantityInputRef.current?.focus();
  }, [selectedProduct]);

  // displaying snackbar when cart update fails
  useEffect(() => {
    if (updateCartError) {
      const errorText = (
        <Fragment>
          <span css={snackbarProductNameStyle}>
            {currentProduct?.current instanceof ReplenishmentProductBox &&
              currentProduct?.current?.itemNumber}{' '}
            — {currentProduct?.current?.name}
          </span>{' '}
          {snackbar_add_product_error}
        </Fragment>
      );

      refetchCart();
      addSnackbar({
        type: NotificationType.WARNING,
        content: errorText,
        showDismissButton: true,
        isStacked: !!snackbar,
      });
    }
  }, [updateCartError, snackbar_add_product_error, currentProduct, refetchCart, snackbar]);

  // cleaning timeout functions
  useEffect(
    () => () => {
      clearTimeout(animationTimeout.current);
      clearTimeout(resetTimeout.current);
      clearTimeout(debounceTimeout.current);
    },
    []
  );

  return (
    <section css={backgroundStyle}>
      <h2 css={headerStyle}>{quick_add}</h2>
      {/* This div is catching all children ESCAPE keypress */}
      <div
        css={formWrapperStyle}
        onBlur={closeDropdownOnBlur}
        onKeyDown={closeDropdownOnEscape}
        tabIndex={-1}
      >
        <div css={formContentStyle}>
          <div css={productFormFieldWrapperStyle}>
            <QuickAddSearchInput
              query={query}
              setQuery={setQuery}
              isFocusable={!!selectedProduct}
              searchInputRef={searchInputRef}
              onKeyDownHandler={onKeyDownSearchInputHandler}
              onBlurHandler={selectSingleResultOnBlur}
              resetForm={resetForm}
            />
            <QuickAddSelectedProduct
              selectedProduct={selectedProduct}
              reopenResults={reopenResults}
            />
          </div>

          <div css={quantityAndButtonStyle}>
            <QuickAddQuantity
              disabled={!selectedProduct}
              pieces={selectedProduct?.piecesPerCasePack}
              quantity={quantity || 0}
              quantityInputRef={quantityInputRef}
              setQuantity={setQuantity}
              handleAddToCart={handleAddToCart}
            />
            <QuickAddButton
              disabled={!selectedProduct}
              handleOnClick={handleAddToCart}
              animate={animateAddButton}
              setAnimate={setAnimateAddButton}
              addingBlock={isAddButtonBlocked}
              setAddingBlock={setIsAddButtonBlocked}
            />
          </div>
        </div>

        <QuickAddSearchResults
          selectedProduct={selectedProduct}
          query={query}
          isSearchError={!!searchError}
          isSearchLoading={isSearchLoading}
          isSearchDebounced={isSearchDebounced}
          results={searchResponse?.products.products || []}
          selectProduct={selectProduct}
        />
      </div>
    </section>
  );
};
