import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { CartReferenceCartType, PaymentMethod } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { BodyWrapper, Card, Container } from '@lego/b2b-unicorn-shared/ui';
import { CheckoutPage } from '@lego/b2b-unicorn-ui-checkout-flow';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import paymentState from '../../utils/paymentState';
import { PromotionDetailsHeader } from './components/PromotionDetails/components';
import { PromotionRouterDetailsParams } from './PromotionRouter';

export const PromotionCheckout: React.FC = () => {
  const selectedCustomer = useSelectedCustomer();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const [checkoutStep, setCheckoutStep] = useState(true);

  const { promotionId: rawPromotionId } = useParams<PromotionRouterDetailsParams>();
  const { endTransaction } = useApmTransaction();
  const promotionId = parseInt(rawPromotionId);
  const history = useHistory();

  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Promotion,
      promotion: {
        promotionId,
      },
    }),
    [promotionId]
  );

  const handleOnCheckoutLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  const handleGoBackClick = () => {
    history.replace(`/promotion/${promotionId}`);
  };

  const handleOrderCreationSuccess = (orderNumber: number) => {
    const confirmationPath = `/promotion/${promotionId}/checkout/${orderNumber}/confirmation`;
    const state = paymentState(confirmationPath, orderNumber, selectedCustomer.id);
    if (userPaysWithCard) {
      GlobalLoadingService.add('checkout-redirect');
      setCheckoutStep(false);
      setTimeout(() => (window.location.href = `/payment/${orderNumber}?state=${state}`));
    } else {
      history.replace(confirmationPath);
    }
  };

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      <BodyWrapper>
        {checkoutStep && (
          <>
            <PromotionDetailsHeader promotionId={promotionId} />
            <Container padding={{ paddingBottom: baseSpacing * 15 }}>
              <Card>
                <CheckoutPage
                  cartReference={cartReference}
                  goBackHandler={handleGoBackClick}
                  cookieDomain={COOKIE_DOMAIN}
                  orderCreationSuccessHandler={handleOrderCreationSuccess}
                  onEmptyCart={handleGoBackClick}
                  onCheckoutLoaded={handleOnCheckoutLoaded}
                />
              </Card>
            </Container>
          </>
        )}
      </BodyWrapper>
    </>
  );
};
