import { PromotionProduct } from '@lego/b2b-unicorn-data-access-layer';
import { GraphQLFormattedError } from 'graphql/index';
import { useEffect, useMemo, useState } from 'react';

import { useDataAccessLayer } from '../';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

export const usePromotions = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.promotions.getPromotions(customerId);
  }, [customerId, dataAccessLayer.promotions]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const useUpdatePromotionProductInCart = (
  customerId: number,
  promotionId: number,
  productOrMaterialId: number | PromotionProduct,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.promotions.updatePromotionProductInCart(
      customerId,
      promotionId,
      productOrMaterialId,
      true,
      onError
    );
  }, [dataAccessLayer.promotions, customerId, promotionId, productOrMaterialId, onError]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useIsUpdatingPromotionCart = () => {
  const dataAccessLayer = useDataAccessLayer();
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  useEffect(() => {
    const subscription = dataAccessLayer.promotions.isUpdatingPromotionCart.subscribe(
      (isUpdatingCart) => {
        setIsUpdatingCart(isUpdatingCart);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [dataAccessLayer.promotions.isUpdatingPromotionCart]);

  return isUpdatingCart;
};

export const useEmptyPromotionCart = (customerId: number, promotionId: number) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.promotions.emptyPromotionCart(customerId, promotionId, true);
  }, [customerId, dataAccessLayer.promotions, promotionId]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useOptimisticUpdatePromotionWithMultiple = (
  customerId: number,
  promotionId: number,
  optimistic?: boolean,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.promotions.updatePromotionWithMultipleItems(
      customerId,
      promotionId,
      optimistic,
      onError
    );
  }, [customerId, dataAccessLayer.promotions, promotionId, onError, optimistic]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};
