import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useEffect, useRef } from 'react';

import { THUMBNAIL_CONTAINER_HEIGHT, THUMBNAIL_CONTAINER_WIDTH } from '../Constants';

const thumbStyle = css({
  width: baseSpacing * 7,
  height: baseSpacing * 7,
  borderRadius: 8,
  padding: 2,
  backgroundColor: colors.white,
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  marginRight: baseSpacing,
  marginLeft: baseSpacing,
  marginBottom: baseSpacing,
  '> img, svg': {
    display: 'block',
    maxHeight: '98%',
    maxWidth: '98%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 2,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:hover > img, &:hover > svg': {
    transition: 'transform 125ms ease-in',
    transform: 'scale(1.05)',
  },
  [media.medium]: {
    width: THUMBNAIL_CONTAINER_WIDTH,
    height: THUMBNAIL_CONTAINER_HEIGHT,
    margin: 0,
    '> img, svg': {
      display: 'block',
      maxHeight: '90%',
      maxWidth: '90%',
    },
  },
});

const activeThumbStyle = css(thumbStyle, {
  borderColor: designToken.secondary.default,
  borderStyle: 'solid',
  borderWidth: 3,
  '> img, svg': {
    transform: 'scale(1.1)',
  },
});

type CarouselThumbnailProps = {
  index: number;
  imageUrl: string;
  active: boolean;
  onClick: (index: number) => void;
  onLoad: (index: number) => void;
};

export const CarouselThumbnail: React.FC<CarouselThumbnailProps> = ({
  index,
  imageUrl,
  active,
  onClick,
  onLoad,
}) => {
  const [error, setError] = React.useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOnClick = () => {
    onClick(index);
  };

  const handleOnLoad = () => {
    onLoad(index);
  };

  const handleError = () => {
    setError(true);
  };

  // Make sure the thumbnail is visible in the list of thumbnails when it is selected (via the arrows in the carousel).
  useEffect(() => {
    if (active && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [active]);

  if (error) {
    return null;
  }

  return (
    <>
      <button
        ref={buttonRef}
        css={active ? activeThumbStyle : thumbStyle}
        onClick={handleOnClick}
        aria-label="thumbnail"
      >
        <ProductImage
          src={imageUrl}
          alt={`Product thumbnail ${index + 1}.`}
          onLoad={handleOnLoad}
          onError={handleError}
          showSpinner={true}
        />
      </button>
    </>
  );
};
