import { css } from '@emotion/react';
import type { CSSInterpolation } from '@emotion/serialize';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import type { CheckoutRecommendationItem } from '@lego/b2b-unicorn-data-access-layer';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { AvailabilityStatus, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator, ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  availabilityStatusStyle,
  commonProductTileStyle,
  linkStyle,
  mediumTextStyle,
} from '../../../styles';
import ProductTileBottom from '../../common/ProductTile/ProductTileBottom';

const TILE_HEIGHT = 273;

const recommendationProductTileStyle = css(commonProductTileStyle, {
  height: TILE_HEIGHT,
  scrollSnapAlign: 'start',
});

const titleStyle = css(mediumTextStyle, {
  marginBottom: 2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const localAvailabilityStatusStyle = css(availabilityStatusStyle, {
  margin: 0,
  height: 24,
});

const localLinkStyle = css(linkStyle, {
  '> img, svg': {
    height: 130,
    marginTop: baseSpacing / 2,
    marginBottom: baseSpacing / 2,
  },
});

const openOrderIndicatorStyle = css({
  position: 'absolute',
  right: baseSpacing,
  transform: 'translateY(-4px)',
  zIndex: 1001,
});

type RecommendationProductTileProps = {
  product: CheckoutRecommendationItem;
  locale: string;
  onAddToCart: (quantity: number) => void;
  onGotoProductDetails: (product: CheckoutRecommendationItem) => void;
  onTransitionEnd: () => void;
  cssStyle: CSSInterpolation;
  buttonType?: ButtonType.PRIMARY | ButtonType.SECONDARY;
};

export const RecommendationProductTile: React.FC<RecommendationProductTileProps> = ({
  product,
  locale,
  onAddToCart,
  buttonType = ButtonType.SECONDARY,
  onGotoProductDetails,
  cssStyle,
  onTransitionEnd,
}) => {
  const { materialId, itemNumber, name, stockStatus, piecesPerCasePack, price, openOrderInfo } =
    product;
  const {
    available,
    low_stock,
    next_possible_delivery,
    out_of_stock,
    not_available,
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
  } = useLabels();
  const history = useHistory();

  const handleOnGotoProductDetails = () => {
    onGotoProductDetails(product);
  };

  const handleOOIClick = () => {
    if (!itemNumber) {
      return;
    }
    const upcomingDeliveryUrl = new URL('/manage/upcoming-deliveries', window.location.origin);
    upcomingDeliveryUrl.searchParams.append('itemQuery', itemNumber.toString());

    history.push(`${upcomingDeliveryUrl.pathname}${upcomingDeliveryUrl.search}`);
  };

  const imageUrl = getImageUrl(materialId, ImageType.MAIN_BOX, 130);

  return (
    <article
      css={css([recommendationProductTileStyle, cssStyle])}
      onTransitionEnd={onTransitionEnd}
      tabIndex={-1}
    >
      <section>
        <div css={localAvailabilityStatusStyle}>
          {stockStatus && (
            <AvailabilityStatus
              status={stockStatus.status}
              nextPossibleDeliveryDate={stockStatus.nextPossibleDeliveryDate}
              locale={locale}
              translations={{
                available,
                low_stock,
                next_possible_delivery,
                out_of_stock,
                not_available,
              }}
            />
          )}
        </div>

        {/* specifying url to indicate it's a product details page viewed via recommendations */}
        <AppLink
          to={`/catalog/${materialId}?from=recommendations`}
          app={'shop'}
          css={localLinkStyle}
          onClick={handleOnGotoProductDetails}
        >
          <ProductImage
            src={imageUrl}
            alt={`${name} box.`}
          />
          <p css={titleStyle}>
            {itemNumber} - <span dangerouslySetInnerHTML={{ __html: name || '' }} />
          </p>
        </AppLink>
        {openOrderInfo && (
          <div css={openOrderIndicatorStyle}>
            <OpenOrderIndicator
              shippingDate={openOrderInfo.shippingDate}
              shippingPieces={openOrderInfo.shippingPieces}
              totalPendingPieces={openOrderInfo.totalPendingPieces}
              locale={locale}
              translations={{
                open_order_dialog_header,
                delivery_date_expected_next,
                total_pending,
                pcs,
                pcs_by,
              }}
              onClick={handleOOIClick}
            />
          </div>
        )}
      </section>
      <ProductTileBottom
        piecesPerCasePack={piecesPerCasePack}
        stockStatus={stockStatus}
        price={price}
        locale={locale}
        withInfo={false}
        onAddToCart={onAddToCart}
        buttonType={buttonType}
      />
    </article>
  );
};
