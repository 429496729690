import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Money, Price as IPrice } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Paragraph } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { MinOrderValueWarning } from './MinOrderValueWarning';
import { PriceDetailsInfo } from './PriceDetailsInfo';

interface InitialPriceDetailsProps {
  minimumOrderValue: Money;
  sumPrice: IPrice;
  isMinValueMet: boolean;
}

export const InitialPriceDetails: React.FC<InitialPriceDetailsProps> = ({
  minimumOrderValue,
  sumPrice,
  isMinValueMet,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const { reference_price, reference_price_dialog_content } = useLabels();
  return (
    <>
      {selectedCustomer.showSimulationDetails && (
        <PriceDetailsInfo
          popupContentRender={
            <Paragraph fontSize={'small'}>{reference_price_dialog_content}</Paragraph>
          }
        >
          {reference_price}
        </PriceDetailsInfo>
      )}
      {(sumPrice.grossPrice !== 0 ||
        sumPrice.netInvoicedPrice !== 0 ||
        sumPrice.estimatedNetInvoicedPrice !== 0) && (
        <MinOrderValueWarning
          isMinValueMet={isMinValueMet}
          minimumOrderValue={minimumOrderValue}
        />
      )}
    </>
  );
};
