import React from 'react';

import { ObjectPathLeavesOnly } from '../../utils';
import { designToken } from '../theme';
import { skeletonCss } from './skeletonCss';

interface SkeletonProps {
  token?: ObjectPathLeavesOnly<typeof designToken.skeleton>;
  children?: React.ReactNode;
}
export const Skeleton: React.FC<SkeletonProps> = ({
  token = 'default' as ObjectPathLeavesOnly<typeof designToken.skeleton>,
  children,
}) => {
  return <div css={skeletonCss(token)}>{children}</div>;
};
