import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

const topbarButtonStyle = {
  width: baseSpacing * 4,
  height: baseSpacing * 4,
  border: 0,
  padding: 0,
  marginRight: 5, //adjusted for search input magglas
  marginLeft: baseSpacing,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  transitionDuration: '150ms',
  position: 'relative',
  boxShadow: 'none',
  outline: 0,
  borderRadius: baseSpacing * 2,
  '&:active': {
    outline: 'none',
  },
};

export default topbarButtonStyle;
