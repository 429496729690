import { css } from '@emotion/react';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import {
  fourColMedia,
  fourColWidth,
  threeColMedia,
  threeColWidth,
} from '../../../../../../constants';

const adjustedWidthStyle = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: `calc(100% - ${baseSpacing * 4}px)`,
  [`@media (min-width: ${threeColMedia}px)`]: {
    width: threeColWidth,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    width: fourColWidth,
  },
});

interface IProductListContainerProps {
  children: React.ReactNode;
}
export const ProductListContainer: React.FC<IProductListContainerProps> = ({ children }) => {
  return <div css={adjustedWidthStyle}>{children}</div>;
};
