import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { AvailabilityStatus, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator } from '@lego/b2b-unicorn-ui-components';

import { nameStyle, selectedItemOverlayStyle } from './styles';

interface IQuickAddSelectedProduct {
  selectedProduct?: ReplenishmentProductsProduct;
  reopenResults: () => void;
}

export const QuickAddSelectedProduct = ({
  selectedProduct,
  reopenResults,
}: IQuickAddSelectedProduct) => {
  const {
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
    available,
    low_stock,
    next_possible_delivery,
    out_of_stock,
    not_available,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      reopenResults();
    }
  };

  return selectedProduct ? (
    <div
      role="button"
      css={selectedItemOverlayStyle}
      onClick={reopenResults}
      onKeyDown={onKeyDownHandler}
      tabIndex={0}
    >
      {!!selectedProduct.openOrderInfo && (
        // this onClick allows opening open order indicator without calling 'reopenResults' fn
        <span
          css={{ marginRight: 8, marginTop: 4 }}
          onClick={(e) => e.stopPropagation()}
        >
          <OpenOrderIndicator
            isSmall
            direction={ToolTipDirection.Down}
            shippingDate={selectedProduct.openOrderInfo.shippingDate}
            shippingPieces={selectedProduct.openOrderInfo.shippingPieces}
            totalPendingPieces={selectedProduct.openOrderInfo.totalPendingPieces}
            locale={selectedCustomer.locale}
            translations={{
              open_order_dialog_header,
              delivery_date_expected_next,
              total_pending,
              pcs,
              pcs_by,
            }}
          />
        </span>
      )}
      <span css={nameStyle}>
        {selectedProduct instanceof ReplenishmentProductBox && selectedProduct.itemNumber}{' '}
        {selectedProduct.name}
      </span>
      {selectedProduct.stockStatus && (
        <span css={{ marginLeft: 5 }}>
          <AvailabilityStatus
            status={selectedProduct.stockStatus.status}
            nextPossibleDeliveryDate={selectedProduct.stockStatus.nextPossibleDeliveryDate}
            translations={{
              available,
              low_stock,
              next_possible_delivery,
              out_of_stock,
              not_available,
            }}
            locale={selectedCustomer.locale}
            type="simple"
          />
        </span>
      )}
    </div>
  ) : null;
};
