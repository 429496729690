import {
  CartReferenceCartType,
  GenericDataAccessError,
  Promotion,
  PromotionCartReferenceInput,
} from '@lego/b2b-unicorn-data-access-layer';
import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ProductConditionResult, PromotionDetailsModel } from '../model/PromotionDetailsModel';

export const usePromotionDetails = (promotionId: number) => {
  const selectedCustomer = useSelectedCustomer();
  const dataAccessLayerClient = useDataAccessLayer();

  // Get model instance
  const promotionDetailsModelRef = useRef(
    PromotionDetailsModel.instance(dataAccessLayerClient, selectedCustomer.id, promotionId)
  );

  // States
  const [promotionData, setPromotionData] = useState<Promotion | null>();
  const [productConditionsResults, setProductConditionsResults] = useState<
    ProductConditionResult[]
  >([]);
  const [checkoutPossible, setCheckoutPossible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<GenericDataAccessError>();
  const [
    initialCartDoesNotFulfillProductConditions,
    setInitialCartDoesNotFulfillProductConditions,
  ] = useState<boolean>(false);

  // Subscribe
  useEffect(() => {
    const promotionDetailsModel = promotionDetailsModelRef.current;
    promotionDetailsModel.subscribe();
    const resultSubscription = promotionDetailsModel.result.subscribe((result) => {
      setPromotionData(result.promotion);
      setProductConditionsResults(result.productConditionsResults);
      setCheckoutPossible(result.checkoutPossible);
      setIsLoading(result.isLoading);
      setError(result.error);
      setInitialCartDoesNotFulfillProductConditions(
        result.initialCartDoesNotFulfillProductConditions
      );
    });

    return () => {
      resultSubscription.unsubscribe();
      promotionDetailsModel.dispose();
    };
  }, []);

  const productConditions = useMemo(() => {
    if (!promotionData) {
      return [];
    }
    return promotionData.promotion.productConditions;
  }, [promotionData]);

  const cartReference = useMemo<PromotionCartReferenceInput>(() => {
    return {
      cartType: CartReferenceCartType.Promotion,
      promotion: { promotionId },
    };
  }, [promotionId]);

  return useMemo(
    () => ({
      promotionId,
      promotionDetailsModel: promotionDetailsModelRef.current,
      promotionData,
      productConditionsResults,
      checkoutPossible,
      isLoading,
      productConditions,
      cartReference,
      error,
      initialCartDoesNotFulfillProductConditions,
    }),
    [
      promotionId,
      promotionDetailsModelRef,
      promotionData,
      productConditionsResults,
      checkoutPossible,
      isLoading,
      productConditions,
      cartReference,
      error,
      initialCartDoesNotFulfillProductConditions,
    ]
  );
};
