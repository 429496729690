import {
  ReplenishmentProductsBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';

import { ProductListSortingOption } from '../../../../../../../constants';

const sortByNullableNumberAsc = (a: number | null | undefined, b: number | null | undefined) => {
  // Historically, we've treated undefined as greater than not undefined. To keep that, we replace undefined with a very
  // large number before performing a normal number sort. A separate check must be done for 0 as that is a falsy value,
  // so it would otherwise be treated as MAX_VALUE.
  a = a === 0 ? 0 : a || Number.MAX_VALUE;
  b = b === 0 ? 0 : b || Number.MAX_VALUE;
  return a - b;
};

const sortByNullableStringAsc = (a: string | null | undefined, b: string | null | undefined) => {
  // Replace undefined strings with empty string before performing an alphabetical sort.
  a = a || '';
  b = b || '';
  return a.localeCompare(b);
};

// When sorting two products, a and b, relative to each other, we get a number: negative if a < b, positive if a > b,
// and zero if a == b. This gives a sort in ascending order. To keep that direction, we can multiply by 1. To reverse
// the direction, we can multiply by -1 (so 0 stays 0, 1 becomes -1 and -1 becomes 1).
const DIRECTION_ASC = 1;
const DIRECTION_DESC = -1;
const directions: { [key in ProductListSortingOption]: number } = {
  [ProductListSortingOption.DATE_ASC]: DIRECTION_ASC,
  [ProductListSortingOption.DATE_DESC]: DIRECTION_DESC,
  [ProductListSortingOption.ITEM_NUMBER_ASC]: DIRECTION_ASC,
  [ProductListSortingOption.ITEM_NUMBER_DESC]: DIRECTION_DESC,
  [ProductListSortingOption.PRICE_PER_PIECE_ASC]: DIRECTION_ASC,
  [ProductListSortingOption.PRICE_PER_PIECE_DESC]: DIRECTION_DESC,
  [ProductListSortingOption.EXIT_DATE_ASC]: DIRECTION_ASC,
  [ProductListSortingOption.EXIT_DATE_DESC]: DIRECTION_DESC,
};

// To make sure we don't miss a sorting option, for each sorting option, define the method used to sort in an exhaustive
// "[key in ProductListSortingOption]" map. This is used in combination with the directions defined above, so these
// sorting functions should not take direction into account. As a result, the function for e.g. the item numbers are the
// same for ascending and descending direction but that is on purpose.
const sortFunctionsIgnoringDirection: {
  [key in ProductListSortingOption]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ) => number;
} = {
  [ProductListSortingOption.DATE_ASC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const launchDateA = a instanceof ReplenishmentProductsBox ? a.launchDate : null;
    const launchDateB = b instanceof ReplenishmentProductsBox ? b.launchDate : null;

    return sortByNullableStringAsc(launchDateA, launchDateB);
  },
  [ProductListSortingOption.DATE_DESC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const launchDateA = a instanceof ReplenishmentProductsBox ? a.launchDate : null;
    const launchDateB = b instanceof ReplenishmentProductsBox ? b.launchDate : null;

    return sortByNullableStringAsc(launchDateA, launchDateB);
  },
  [ProductListSortingOption.EXIT_DATE_DESC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const exitDateA = a instanceof ReplenishmentProductsBox ? a.exitDate : null;
    const exitDateB = b instanceof ReplenishmentProductsBox ? b.exitDate : null;

    return sortByNullableStringAsc(exitDateA, exitDateB);
  },
  [ProductListSortingOption.EXIT_DATE_ASC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const exitDateA = a instanceof ReplenishmentProductsBox ? a.exitDate : null;
    const exitDateB = b instanceof ReplenishmentProductsBox ? b.exitDate : null;

    return sortByNullableStringAsc(exitDateA, exitDateB);
  },
  [ProductListSortingOption.ITEM_NUMBER_ASC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const itemNumberA = a instanceof ReplenishmentProductsBox ? a.itemNumber : null;
    const itemNumberB = b instanceof ReplenishmentProductsBox ? b.itemNumber : null;

    return sortByNullableNumberAsc(itemNumberA, itemNumberB);
  },
  [ProductListSortingOption.ITEM_NUMBER_DESC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    const itemNumberA = a instanceof ReplenishmentProductsBox ? a.itemNumber : null;
    const itemNumberB = b instanceof ReplenishmentProductsBox ? b.itemNumber : null;

    return sortByNullableNumberAsc(itemNumberA, itemNumberB);
  },
  [ProductListSortingOption.PRICE_PER_PIECE_ASC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    return sortByNullableNumberAsc(
      a.price.estimatedNetInvoicedPrice,
      b.price.estimatedNetInvoicedPrice
    );
  },
  [ProductListSortingOption.PRICE_PER_PIECE_DESC]: (
    a: ReplenishmentProductsProduct,
    b: ReplenishmentProductsProduct
  ): number => {
    return sortByNullableNumberAsc(
      a.price.estimatedNetInvoicedPrice,
      b.price.estimatedNetInvoicedPrice
    );
  },
};

/**
 * Sort products according to a {@link ProductListSortingOption}. Ties are handled by item numbers ascending and ties
 * within that are not defined.
 */
export const sortProductList = (
  items: ReplenishmentProductsProduct[],
  sortOption: ProductListSortingOption
) => {
  const productList = [...items];

  const direction = directions[sortOption];
  const sortingFunction = sortFunctionsIgnoringDirection[sortOption];

  return productList.sort((a, b) => {
    // Perform the initial sort according to e.g. theme or launch date.
    const sortResult = sortingFunction(a, b);

    // Ties are handled by item numbers ascending, so two products with the same theme will be ordered by the lowest
    // item number first. If the sort is already done according to item number, and we have identical item numbers
    // (which can happen if multiple versions of the same material are in a customer's catalog), this extra sort is
    // redundant, and will produce 0 again, but it is not a lot of extra work.
    if (sortResult === 0) {
      const itemNumberA = a instanceof ReplenishmentProductsBox ? a.itemNumber : null;
      const itemNumberB = b instanceof ReplenishmentProductsBox ? b.itemNumber : null;
      // Don't apply the direction here as it is the "secondary" sort by item numbers.
      return sortByNullableNumberAsc(itemNumberA, itemNumberB);
    }

    return sortResult * direction;
  });
};
