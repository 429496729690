import { Lifecycle, ProductTag, StockStatusTag } from '@lego/b2b-unicorn-data-access-layer';

import { ProductListSortingOption } from '../../../../../constants';
import { IProductSearchAndFilterModel } from '../types';

// Generic function to validate if a value is an array of enum values
const isValidEnumArray = <T extends object>(value: unknown, enumObject: T): boolean => {
  if (!Array.isArray(value)) {
    return false;
  }

  const enumValues = Object.values(enumObject);

  return value.every((v) => enumValues.includes(v));
};

// Map IProductSearchAndFilterModel keys to their corresponding enums
const keyToEnumMapping: Partial<Record<keyof IProductSearchAndFilterModel, object>> = {
  availabilities: StockStatusTag,
  lifecycles: Lifecycle,
  tags: ProductTag,
  sort: ProductListSortingOption,
};

export const validateProductSearchParam = (
  key: keyof IProductSearchAndFilterModel,
  value: unknown
): boolean => {
  const enumObject = keyToEnumMapping[key];
  if (enumObject) {
    return isValidEnumArray(value, enumObject);
  }
  return true;
};
