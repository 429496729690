import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { SelectBox } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { DefaultProductListSorting, ProductListSortingOption } from '../../../../../../constants';
import { useProductListContext } from '../../ProductListContext';
import { inputContainerStyle, sortingContainerStyle } from './styles';

export const ProductListSorting: React.FC = () => {
  const {
    filter_sort_by_date_asc,
    filter_sort_by_date_desc,
    filter_sort_by_item_number_asc,
    filter_sort_by_item_number_desc,
    filter_sort_by_price_per_piece_asc,
    filter_sort_by_price_per_piece_desc,
    filter_sort_by_exit_date_asc,
    filter_sort_by_exit_date_desc,
  } = useLabels();
  const { productsResponse, selectedFilters, handleSortingChange } = useProductListContext();
  const selectedSortingOption = selectedFilters?.sort || DefaultProductListSorting;

  const optionDisplayValue: { [key in ProductListSortingOption]: string } = {
    [ProductListSortingOption.DATE_ASC]: filter_sort_by_date_asc,
    [ProductListSortingOption.DATE_DESC]: filter_sort_by_date_desc,
    [ProductListSortingOption.ITEM_NUMBER_ASC]: filter_sort_by_item_number_asc,
    [ProductListSortingOption.ITEM_NUMBER_DESC]: filter_sort_by_item_number_desc,
    [ProductListSortingOption.PRICE_PER_PIECE_ASC]: filter_sort_by_price_per_piece_asc,
    [ProductListSortingOption.PRICE_PER_PIECE_DESC]: filter_sort_by_price_per_piece_desc,
    [ProductListSortingOption.EXIT_DATE_ASC]: filter_sort_by_exit_date_asc,
    [ProductListSortingOption.EXIT_DATE_DESC]: filter_sort_by_exit_date_desc,
  };

  return (
    <div css={sortingContainerStyle}>
      <div css={inputContainerStyle}>
        <SelectBox
          label={optionDisplayValue[selectedSortingOption]}
          values={(Object.keys(ProductListSortingOption) as Array<ProductListSortingOption>).map(
            (option) => {
              return {
                displayText: optionDisplayValue[option],
                value: option,
              };
            }
          )}
          selectedValues={selectedSortingOption}
          optionsChangeHandler={handleSortingChange}
          hideBorder={true}
          disabled={!productsResponse}
        />
      </div>
    </div>
  );
};
