import type { FetchResult } from '@apollo/client/link/core';
import { GraphQLFormattedError } from 'graphql/index';

import { LaunchCartProduct } from '../../';
import {
  EmptyLaunchWindowMutation,
  EmptyLaunchWindowMutationVariables,
  LaunchWindowQuery,
  LaunchWindowQueryVariables,
  LaunchWindowsOverviewQuery,
  LaunchWindowsOverviewQueryVariables,
  UpdateCartInWindowMutation,
  UpdateCartInWindowMutationVariables,
  UpdateCartMutation,
  UpdateCartMutationVariables,
  UpdateLaunchWindowWithMultipleItemsMutation,
  UpdateLaunchWindowWithMultipleItemsMutationVariables,
} from '../../generated-types/graphql';
import { CartReferenceCartType } from '../../generated-types/types';
import { UPDATE_CART } from '../../type-policies/CartPolicy';
import { ExtractElementType, MarkRequired } from '../../utils/TypeScriptHelpers';
import { ContextAbstract, MutationObservable } from '../ContextAbstract';
import { ExtraOptions } from '../GenericContextTypes';
import { EMPTY_LAUNCH_WINDOW } from './mutations/emptyLaunchWindow';
import { UPDATE_CART_IN_WINDOW } from './mutations/updateCartInWindow';
import { UPDATE_LAUNCH_WINDOW_WITH_MULTIPLE_ITEMS } from './mutations/updateLaunchWindowWithMultipleItems';
import { LAUNCH_WINDOW } from './queries/launchWindow';
import { LAUNCH_WINDOWS_OVERVIEW } from './queries/launchWindowsOverview';

export type OpenLaunchWindow = ExtractElementType<
  LaunchWindowsOverviewQuery['launchWindows']['openWindows']
>;
export type PreviousLaunchWindow = ExtractElementType<
  LaunchWindowsOverviewQuery['launchWindows']['previousWindows']
>;
export type FutureLaunchWindow = ExtractElementType<
  LaunchWindowsOverviewQuery['launchWindows']['futureWindows']
>;

export type LaunchCartReferenceInput = {
  cartType: CartReferenceCartType.Launch;
  launch: {
    year: number;
    month: number;
  };
};

type CartItem = ExtractElementType<UpdateLaunchWindowWithMultipleItemsMutationVariables['items']>;
export type CartItemWithLaunchProduct = Omit<CartItem, 'materialId'> & {
  product: LaunchCartProduct;
};

export type UpdateCartInMultipleWindowsItems = {
  cartReference: {
    cartType: CartReferenceCartType.Launch;
    launch: MarkRequired<
      NonNullable<UpdateCartInWindowMutationVariables['cartReference']['launch']>
    >;
  };
  items: UpdateCartInWindowMutationVariables['items'];
}[];

export type UpdateCartInMultipleWindowsResult = Promise<FetchResult<UpdateCartInWindowMutation>[]>;
export type UpdateCartInMultipleWindowsResultItem = ExtractElementType<
  NonNullable<
    ExtractElementType<Awaited<UpdateCartInMultipleWindowsResult>>['data']
  >['updateCartWithMultipleItems']['items']
>;

export class LaunchWindowsDataContext extends ContextAbstract {
  public getWindowsOverview(customerId: number, extraOptions?: ExtraOptions) {
    return this.queryObservable<LaunchWindowsOverviewQuery, LaunchWindowsOverviewQueryVariables>(
      LAUNCH_WINDOWS_OVERVIEW,
      { customerId },
      extraOptions
    );
  }

  public getLaunchWindow(
    customerId: number,
    year: number,
    month: number,
    extraOptions?: ExtraOptions
  ) {
    return this.queryObservable<LaunchWindowQuery, LaunchWindowQueryVariables>(
      LAUNCH_WINDOW,
      { customerId, year, month },
      extraOptions
    );
  }

  public updateLaunchWindow(
    customerId: number,
    year: number,
    month: number,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<
    UpdateCartMutation,
    { quantity: number; productOrMaterialId: number | LaunchCartProduct }
  > {
    if (!this.cartPolicy) {
      throw new Error('LaunchWindowsDataContext has not been created with a type policy!');
    }

    const observableKey = `updateLaunchWindow-${customerId}-${CartReferenceCartType.Launch}-${year}-${month}`;

    const variables = {
      customerId,
      year,
      month,
      cartReference: {
        cartType: CartReferenceCartType.Launch,
        launch: { year, month },
      },
    };

    const [mutate, reset, results] = this.mutationObservable<
      UpdateCartMutation,
      UpdateCartMutationVariables
    >(observableKey, UPDATE_CART, 'materialId', onError);
    const updateCartOptimistic = this.cartPolicy.updateCartOptimistic.bind(this);

    return [
      ({ quantity, productOrMaterialId }) => {
        if (optimistic && typeof productOrMaterialId !== 'number') {
          updateCartOptimistic(productOrMaterialId, quantity, {
            query: LAUNCH_WINDOW,
            variables: {
              customerId,
              year,
              month,
            },
          });
        }

        return mutate({
          ...variables,
          materialId:
            typeof productOrMaterialId === 'number'
              ? productOrMaterialId
              : productOrMaterialId.materialId,
          quantity,
        });
      },
      reset,
      results,
    ];
  }

  public emptyLaunchWindow(
    customerId: number,
    year: number,
    month: number,
    optimistic: boolean = false
  ): MutationObservable<EmptyLaunchWindowMutation> {
    if (!this.cartPolicy) {
      throw new Error('LaunchWindowsDataContext has not been created with a type policy!');
    }

    const observableKey = `emptyLaunchWindow-${customerId}-${year}-${month}`;

    const [mutate, reset, results] = this.mutationObservable<
      EmptyLaunchWindowMutation,
      EmptyLaunchWindowMutationVariables
    >(observableKey, EMPTY_LAUNCH_WINDOW);
    const emptyCartOptimistic = this.cartPolicy.emptyCartOptimistic.bind(this);

    return [
      () => {
        if (optimistic) {
          emptyCartOptimistic({
            query: LAUNCH_WINDOW,
            variables: {
              customerId,
              year,
              month,
            },
          });
        }

        return mutate({
          customerId,
          year,
          month,
        });
      },
      reset,
      results,
    ];
  }

  public updateCartInMultipleWindows(
    customerId: number,
    items: UpdateCartInMultipleWindowsItems
  ): UpdateCartInMultipleWindowsResult {
    const mutations: Array<
      ReturnType<
        typeof this._apolloClient.mutate<
          UpdateCartInWindowMutation,
          UpdateCartInWindowMutationVariables
        >
      >
    > = [];

    for (const windowToUpdate of items) {
      mutations.push(
        this._apolloClient.mutate<UpdateCartInWindowMutation, UpdateCartInWindowMutationVariables>({
          mutation: UPDATE_CART_IN_WINDOW,
          variables: {
            customerId,
            cartReference: windowToUpdate.cartReference,
            items: windowToUpdate.items,
          },
        })
      );
    }

    return Promise.all(mutations);
  }

  public updateLaunchWindowWithMultipleItems(
    customerId: number,
    year: number,
    month: number,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<
    UpdateLaunchWindowWithMultipleItemsMutation,
    { items: CartItemWithLaunchProduct[] }
  > {
    if (!this.cartPolicy) {
      throw new Error('LaunchWindowsDataContext has not been created with a type policy!');
    }

    const observableKey = `updateLaunchWindowWithMultipleItems-${customerId}-${year}-${month}`;
    const [mutate, reset, results] = this.mutationObservable<
      UpdateLaunchWindowWithMultipleItemsMutation,
      UpdateLaunchWindowWithMultipleItemsMutationVariables
    >(observableKey, UPDATE_LAUNCH_WINDOW_WITH_MULTIPLE_ITEMS, undefined, onError);

    const updateCartWithMultipleOptimistic =
      this.cartPolicy.updateCartWithMultipleOptimistic.bind(this);

    return [
      ({ items }) => {
        if (optimistic) {
          updateCartWithMultipleOptimistic(items, {
            query: LAUNCH_WINDOW,
            variables: {
              customerId,
              year,
              month,
            },
          });
        }

        return mutate({
          customerId,
          year,
          month,
          items: items.map((item) => ({
            materialId: item.product.materialId,
            quantity: item.quantity,
          })),
        });
      },
      reset,
      results,
    ];
  }
}
