import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { SelectBox } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { useProductListContext } from '../../../ProductListContext';
import { inputContainerStyle } from '../styles';
import { getProductsPriceRangeOptionName } from '../utils';

export const PriceRangesFilter: React.FC = () => {
  const labelsContext = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const {
    filtersList: { priceRanges },
    selectedFilters,
    setSelectedFilters,
  } = useProductListContext();

  const handlePriceRangeSelect = (value: string | string[]) => {
    if (Array.isArray(value)) {
      let updatedFilters = { ...selectedFilters };
      if (value.length) {
        updatedFilters = {
          ...selectedFilters,
          priceRanges: value as string[],
        };
      } else {
        delete updatedFilters.priceRanges;
      }
      setSelectedFilters(updatedFilters);
    }
  };

  return (
    <div css={inputContainerStyle}>
      <SelectBox
        label={labelsContext.filter_price_range}
        values={priceRanges.map((option) => {
          const hitsCount = `[${option.hitsCount}]`;
          return {
            displayText: `${getProductsPriceRangeOptionName(option, selectedCustomer)} ${
              option.hitsCount > 0 ? hitsCount : ''
            }`,
            value: option.id,
          };
        })}
        selectedValues={selectedFilters?.priceRanges || []}
        optionsChangeHandler={handlePriceRangeSelect}
        allowMultiple={true}
      />
    </div>
  );
};
