import { css } from '@emotion/react';
import { designToken, Icon, IconType, Typography } from '@lego/b2b-unicorn-shared/ui';
import { DialogPopup, DialogWrapper } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const infoIconStyle = css({
  display: 'inline-block',
  height: 14,
  width: 14,
  marginLeft: baseSpacing / 2,
  '> svg': {
    marginTop: 3,
    height: 14,
    width: 14,
    fill: designToken.text.muted,
  },
});

interface PriceDetailsInfoProps {
  popupContentRender: React.ReactNode;
  children: React.ReactNode;
}

export const PriceDetailsInfo: React.FC<PriceDetailsInfoProps> = ({
  popupContentRender,
  children,
}) => {
  return (
    <div>
      <Typography
        size={'tiny'}
        designToken={'text.muted'}
      >
        {children}
        <DialogWrapper styles={infoIconStyle}>
          <Icon type={IconType.INFO} />
          <DialogPopup
            icon
            direction="bottom"
          >
            {popupContentRender}
          </DialogPopup>
        </DialogWrapper>
      </Typography>
    </div>
  );
};
