import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AvailabilityStatus, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator, ProductImage } from '@lego/b2b-unicorn-ui-components';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';

import { arrowKeypressNavigation, isProductDisabled } from './';
import {
  availabilityStatusStyle,
  productDataStyle,
  productImgWrapperStyle,
  productNameStyle,
  productTextStyle,
  productWrapStyle,
} from './styles';

interface IQuickAddSearchResultsItem {
  selectThisItem: () => void;
  numberOfResults: number;
  index: number;
  product: ReplenishmentProductsProduct;
}

export const QuickAddSearchResultsItem = ({
  selectThisItem,
  numberOfResults,
  index,
  product,
}: IQuickAddSearchResultsItem) => {
  const {
    available,
    low_stock,
    next_possible_delivery,
    out_of_stock,
    not_available,
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const isDisabled = isProductDisabled(product);
  const materialId = product.materialId;

  const imageUrl = getImageUrl(materialId, ImageType.MAIN_BOX, 40);

  return (
    <button
      aria-disabled={isDisabled}
      css={productWrapStyle(isDisabled)}
      onClick={() => {
        !isDisabled && selectThisItem();
      }}
      tabIndex={numberOfResults === 1 ? -1 : 0}
      onKeyDown={arrowKeypressNavigation}
      data-arrow-keypress-navigable-index={index + 1}
    >
      <div css={productImgWrapperStyle}>
        <ProductImage
          src={imageUrl}
          alt={product.name || ''}
        />
      </div>
      <div css={productDataStyle}>
        <div css={productTextStyle}>
          <span>{product instanceof ReplenishmentProductBox && product.itemNumber}</span>

          <span css={availabilityStatusStyle}>
            {product.stockStatus && (
              <AvailabilityStatus
                status={product.stockStatus?.status}
                nextPossibleDeliveryDate={product.stockStatus?.nextPossibleDeliveryDate}
                locale={selectedCustomer.locale}
                translations={{
                  available,
                  low_stock,
                  next_possible_delivery,
                  out_of_stock,
                  not_available,
                }}
              />
            )}
          </span>

          {product.openOrderInfo?.totalPendingPieces && (
            <OpenOrderIndicator
              direction={ToolTipDirection.Down}
              locale={selectedCustomer.locale}
              shippingDate={product.openOrderInfo.shippingDate}
              shippingPieces={product.openOrderInfo.shippingPieces}
              totalPendingPieces={product.openOrderInfo.totalPendingPieces}
              translations={{
                open_order_dialog_header,
                delivery_date_expected_next,
                total_pending,
                pcs,
                pcs_by,
              }}
            />
          )}
        </div>
        <div
          css={productNameStyle}
          dangerouslySetInnerHTML={{ __html: product.name || '' }}
        />
      </div>
    </button>
  );
};
