import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';

import { baseSpacing, colors, designToken, font, media } from '../theme';

const activeColor = designToken.primary.default;
const inactivePrimaryColor = tokens.color.core.gray[400];
const inactiveSecondaryColor = tokens.color.core.gray[100];
export const stepsContainerStyle = css({
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  width: '100%',
  minHeight: 120,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
});

export const numberStyle = {
  color: inactivePrimaryColor,
  borderRadius: '50%',
  height: baseSpacing * 4,
  width: baseSpacing * 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
};

export const defaultStepsItemStyle = css({
  fontSize: font.size.normal,
  fontWeight: font.weight.medium,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '> span:first-of-type': {
    ...numberStyle,
    color: inactivePrimaryColor,
    backgroundColor: designToken.background.offWhite,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: inactiveSecondaryColor,
    marginRight: 0,
    '> svg': {
      height: 20,
      width: 20,
      fill: colors.white,
    },

    [media.medium]: {
      marginRight: baseSpacing,
    },
  },
  '> span:last-of-type': {
    display: 'none',
  },

  [media.medium]: {
    '> span:last-of-type': {
      display: 'block',
      color: inactivePrimaryColor,
    },
  },
});

export const activeStepsItemStyle = css(defaultStepsItemStyle, {
  '> span: first-of-type': {
    ...numberStyle,
    color: activeColor,
    borderColor: activeColor,
    marginRight: baseSpacing,
  },
  '> span:last-of-type': {
    display: 'block',
    color: activeColor,
  },
});

export const finishedStepsItemStyle = css(defaultStepsItemStyle, {
  '> span: first-of-type': {
    ...numberStyle,
    color: colors.white,
    backgroundColor: activeColor,
    border: 'none',
    marginRight: baseSpacing,
  },
});

export const dashStyle = css({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: inactiveSecondaryColor,
  width: 290,
  height: 0,
  display: 'block',
  marginLeft: baseSpacing,
  marginRight: baseSpacing,
  borderRadius: 4,

  [media.small]: {
    marginLeft: baseSpacing * 4,
    marginRight: baseSpacing * 4,
  },
});

export const activeDashStyle = css(dashStyle, {
  borderColor: activeColor,
});
