import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { useEffect, useRef } from 'react';

import {
  buttonAnimatedStyle,
  disabledButtonStyle,
  iconAnimatedStyle,
  iconStyle,
  quickAddButtonStyle,
} from './styles';

interface IQuickAddButtonProps {
  disabled: boolean;
  handleOnClick: () => void;
  animate: boolean;
  setAnimate: (value: boolean) => void;
  addingBlock: boolean;
  setAddingBlock: (value: boolean) => void;
}

export const QuickAddButton = ({
  disabled,
  handleOnClick,
  animate,
  setAnimate,
  addingBlock,
  setAddingBlock,
}: IQuickAddButtonProps) => {
  const { button_add } = useLabels();
  const addingBlockTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    clearTimeout(addingBlockTimeout.current);
    addingBlockTimeout.current = setTimeout(() => {
      setAddingBlock(false);
    }, 1000);
  }, [addingBlock]);

  useEffect(() => {
    disabled && setAnimate(false);
  }, [disabled]);

  return disabled ? (
    <button
      data-test="addToCartButtonDisabled"
      css={disabledButtonStyle}
      disabled
    >
      {button_add}
    </button>
  ) : (
    <button
      data-test="addToCartButton"
      css={animate ? buttonAnimatedStyle : quickAddButtonStyle}
      type="submit"
      onClick={handleOnClick}
      onAnimationEnd={() => {
        setAnimate(false);
      }}
      disabled={addingBlock}
    >
      <span>{button_add}</span>
      <span css={animate ? iconAnimatedStyle : iconStyle}>
        <Icon type={IconType.CHECK} />
      </span>
    </button>
  );
};
