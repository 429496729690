import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { OrderConfirmation } from '@lego/b2b-unicorn-ui-checkout-flow';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React from 'react';
import { useParams } from 'react-router';

const backToShopStyle = css({
  textDecoration: 'none',
  display: 'contents',
});

export const PromotionCheckoutConfirmationPage = () => {
  const { button_back_to_promotions } = useLabels();
  const { orderNumber } = useParams<{ orderNumber: string }>();

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      <OrderConfirmation
        orderNumber={parseInt(orderNumber, 10)}
        orderType={CartReferenceCartType.Promotion}
      >
        <div css={backToShopStyle}>
          <AppLink
            app="shop"
            to="/promotion"
            css={backToShopStyle}
          >
            <Button type={ButtonType.PRIMARY_INVERTED}>{button_back_to_promotions}</Button>
          </AppLink>
        </div>
      </OrderConfirmation>
    </>
  );
};
