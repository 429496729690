import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductsBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useMaterialIdInCart } from '@lego/b2b-unicorn-data-access-layer/react/hooks/replenishment';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AvailabilityStatus } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import React, { useCallback, useLayoutEffect, useRef } from 'react';

import ProductTileBottom from '../../../../../../components/common/ProductTile/ProductTileBottom';
import { ProductTileTags } from '../../../../../../components/common/ProductTile/ProductTileTags';
import { useProductTile } from '../../../../../../components/common/ProductTile/useProductTile';
import {
  availabilityStatusStyle,
  bigTextStyle,
  commonProductTileStyle,
  infoRowStyle,
  linkStyle,
  mediumTextStyle,
  themeStyle,
  titleStyle,
} from '../../../../../../styles';

const TILE_HEIGHT = 468;

const productTileStyle = css(commonProductTileStyle, {
  height: TILE_HEIGHT,
  backgroundColor: colors.white,
});

interface IProductListTile {
  product: ReplenishmentProductsProduct;
  locale: string;
  onRendered?: (node: HTMLDivElement | null) => void;
}

export const ProductListTile = React.memo(({ product, locale, onRendered }: IProductListTile) => {
  const { price, materialId, name, openOrderInfo, stockStatus, theme, lifecycles, tags } = product;
  const itemNumber = product instanceof ReplenishmentProductsBox ? product.itemNumber : undefined;
  const {
    launch_date,
    exit_date,
    price_per_piece,
    available,
    low_stock,
    next_possible_delivery,
    out_of_stock,
    not_available,
  } = useLabels();
  const { id: customerId } = useSelectedCustomer();
  const inCart = useMaterialIdInCart(customerId, materialId, {
    fetchPolicy: 'cache-only',
  });
  const { trackEvent } = useAnalytics();

  const beforeAddToCart = useCallback(() => {
    trackEvent({
      event: 'addToCartFromReplenishCatalog',
      name: `Adding product ${materialId} to cart`,
    });
  }, [trackEvent, materialId]);
  const { addToCart } = useProductTile(product, beforeAddToCart);

  const tileRef = useRef<HTMLDivElement>(null);
  const imageUrl = getImageUrl(materialId, ImageType.MAIN_BOX, 160);

  useLayoutEffect(() => {
    onRendered && onRendered(tileRef.current);
  }, []);

  return (
    <article
      css={productTileStyle}
      ref={tileRef}
    >
      <section>
        <div css={availabilityStatusStyle}>
          {stockStatus && (
            <AvailabilityStatus
              status={stockStatus?.status}
              nextPossibleDeliveryDate={stockStatus.nextPossibleDeliveryDate}
              locale={locale}
              translations={{
                available,
                low_stock,
                next_possible_delivery,
                out_of_stock,
                not_available,
              }}
            />
          )}
        </div>

        <AppLink
          to={`/catalog/${materialId}`}
          app={'shop'}
          css={linkStyle}
        >
          <ProductImage
            src={imageUrl}
            alt={`${name} box.`}
          />
          <div css={titleStyle}>
            <p
              css={bigTextStyle}
              data-e2e="itemNumber"
            >
              {(product instanceof ReplenishmentProductsBox && product.itemNumber) || '—'}
            </p>
            <p
              css={mediumTextStyle}
              dangerouslySetInnerHTML={{ __html: name || '' }}
            />
            <p css={themeStyle}>{theme}</p>
          </div>
        </AppLink>
      </section>
      <ProductTileTags
        lifecycles={lifecycles}
        tags={tags}
        openOrderInfo={openOrderInfo}
        foundInCart={inCart.found}
        locale={locale}
        itemNumber={itemNumber}
      />
      <section css={infoRowStyle}>
        <div>
          <p>{launch_date}:</p>
          <p>
            {product instanceof ReplenishmentProductsBox && (
              <Date locale={locale}>{product.launchDate}</Date>
            )}
            {!(product instanceof ReplenishmentProductsBox) && '-'}
          </p>
        </div>
        <div>
          <p>{exit_date}:</p>
          <p>
            {product instanceof ReplenishmentProductsBox && (
              <Date locale={locale}>{product.exitDate}</Date>
            )}
            {!(product instanceof ReplenishmentProductsBox) && '-'}
          </p>
        </div>
        <div>
          <p>{price_per_piece}:</p>
          <p>
            <Price locale={locale}>{price}</Price>
          </p>
        </div>
      </section>

      <ProductTileBottom
        piecesPerCasePack={product.piecesPerCasePack}
        price={price}
        stockStatus={stockStatus}
        locale={locale}
        onAddToCart={addToCart}
      />
    </article>
  );
});
ProductListTile.displayName = 'ProductListTile';
