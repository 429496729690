import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Container, designToken, Skeleton, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment } from 'react';

import { ContainerHeader } from '../../../../shop/components/ContainerHeader';
import { formWrapperStyle, pagePaddingStyle } from '../../common/styles';
import { paymentBackgroundStyle, skeletonSummaryStyle } from './PaymentAndDelivery/styles';

const inputStyle = css({
  height: 40,
  width: '100%',
  borderRadius: 6,
  margin: 0,
});
const productRowStyle = css({
  width: '50%',
  marginLeft: '20%',
  marginTop: 10,
  marginBottom: 10,
  height: baseSpacing * 2,
  backgroundColor: designToken.skeleton.secondary,
  [media.medium]: {
    borderBottomWidth: 2,
    borderBottomColor: designToken.white,
    borderBottomStyle: 'solid',
    width: '100%',
    height: 57,
    margin: 0,
  },
});
const secondaryTableStyle = css({
  width: '100%',
  display: 'table',
  marginBottom: baseSpacing * 4,
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    [media.medium]: {
      zIndex: 10,
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100vw',
      height: 335,
      top: 0,

      background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.background.offWhite} 80%, ${designToken.background.offWhite} 100%)`,
    },
  },
  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
  [media.medium]: {
    padding: 0,
  },
});

const SkeletonCheckoutPage = () => {
  const { order_delivery_information, order_summary, order_details } = useLabels();

  return (
    <Fragment>
      <div
        css={[
          pagePaddingStyle,
          {
            marginTop: baseSpacing * 3,
            marginBottom: baseSpacing * 9,
            [media.medium]: { marginTop: baseSpacing * 9, marginBottom: 0 },
          },
        ]}
      >
        <div css={formWrapperStyle}>
          <div css={paymentBackgroundStyle(true)}>
            <ContainerHeader
              open={true}
              text={order_delivery_information}
              withLargeText={false}
            />
            <Spacer multiplier={4} />
            <div css={inputStyle}>
              <Skeleton />
            </div>
            <Spacer multiplier={5} />
            <div css={inputStyle}>
              <Skeleton />
            </div>
            <Spacer multiplier={5} />
            <div css={inputStyle}>
              <Skeleton />
            </div>
          </div>
          <div css={skeletonSummaryStyle}>
            <Skeleton>
              <Container
                padding={{
                  paddingTop: baseSpacing * 2,
                  paddingRight: baseSpacing * 2,
                  paddingLeft: baseSpacing * 2,
                }}
                paddingMedium={{
                  [media.small]: {
                    paddingRight: baseSpacing * 6,
                    paddingLeft: baseSpacing * 6,
                  },
                }}
              >
                <ContainerHeader
                  open={true}
                  text={order_summary}
                  withLargeText={false}
                />
              </Container>
            </Skeleton>
          </div>
        </div>
        <Spacer multiplier={6} />
        <div>
          <ContainerHeader
            text={order_details}
            open={true}
            withLargeText={false}
          />
          <div css={secondaryTableStyle}>
            {[...Array(5)].map((_, i) => {
              return (
                <div
                  css={productRowStyle}
                  key={i}
                >
                  <Skeleton />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SkeletonCheckoutPage;
