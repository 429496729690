import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { OrderConfirmation } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React from 'react';
import { useParams } from 'react-router';

const backgroundStyle = css({
  color: colors.klik.slate700,
  width: '100%',
  height: '100%',
});
const backToShopStyle = {
  marginBottom: baseSpacing * 5,
};

export const CheckoutConfirmationPage: React.FC = () => {
  const { button_back_to_shop } = useLabels();
  const { orderNumber } = useParams<{ orderNumber: string }>();

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <div css={backgroundStyle}>
        <OrderConfirmation
          orderNumber={parseInt(orderNumber, 10)}
          orderType={CartReferenceCartType.Replenish}
        >
          <div css={backToShopStyle}>
            <AppLink
              app="shop"
              to="/"
            >
              <Button type={ButtonType.SECONDARY}>{button_back_to_shop}</Button>
            </AppLink>
          </div>
        </OrderConfirmation>
      </div>
    </>
  );
};
