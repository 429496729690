import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItem, CartSimulationDetails, Money } from '@lego/b2b-unicorn-data-access-layer';
import { AnimatedDots, Spacer, Typography } from '@lego/b2b-unicorn-shared/ui';
import { mobileCollapseStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { CartMath } from '@lego/b2b-unicorn-ui-utils';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { ContainerHeader } from '../../../../../shop/components/ContainerHeader';
import { FinalPriceDetails } from './components/FinalPriceDetails';
import { FinalPriceValue } from './components/FinalPriceValue';
import { InitialPriceDetails } from './components/InitialPriceDetails';
import { InitialPriceValue } from './components/InitialPriceValue';
import { SummaryItem } from './components/SummaryItem';
import { summaryBackgroundStyle } from './styles';

const calculatingStyle = css({
  maxHeight: 22,
  color: tokens.color.core.azur[800],
  fontWeight: font.weight.bold,
  letterSpacing: 0,
  display: 'flex',
  alignItems: 'center',
});
const actionAlignmentStyle = css({
  position: 'absolute',
  bottom: baseSpacing * 3,
});

interface CheckoutOrderSummaryProps {
  children: React.ReactNode;
  hasValidShipTos: boolean;
  simulationRunning?: boolean;
  simulationDetails?: CartSimulationDetails | null;
  simulationFinalPrice?: Money;
  simulationFinalListPrice?: Money;
  minimumOrderValue: Money;
  cartItems: CartItem[];
}

const CheckoutOrderSummary: React.FC<CheckoutOrderSummaryProps> = ({
  children,
  hasValidShipTos,
  simulationRunning,
  simulationDetails,
  simulationFinalPrice,
  simulationFinalListPrice,
  minimumOrderValue,
  cartItems,
}) => {
  // There can only be one currency in a cart, that's why we just take the first one
  const currency = cartItems.length > 0 && cartItems[0].product.price.currency;

  const sumPrice = CartMath.sumPrice(cartItems);
  const sumGrossPrice = CartMath.sumGrossPrice(cartItems, true);
  const sumNetPrice =
    CartMath.sumNetInvoicedPrice(cartItems, true) ||
    CartMath.sumEstimatedNetInvoicedPrice(cartItems);
  const isMinValueMet = sumNetPrice.netInvoicedPrice
    ? sumNetPrice.netInvoicedPrice > minimumOrderValue.amount
    : sumGrossPrice.grossPrice > minimumOrderValue.amount;

  const sumCases = CartMath.sumCases(cartItems);
  const sumUnits = CartMath.sumUnits(cartItems);

  const {
    order_summary,
    total_cases,
    total_pieces,
    total_order_price,
    order_submit_disclaimer,
    order_blocked_disclaimer,
    calculating,
  } = useLabels();

  return (
    <section css={summaryBackgroundStyle(true)}>
      <ContainerHeader
        open={true}
        text={order_summary}
        withLargeText={false}
      />
      <Spacer multiplier={2} />
      <div css={mobileCollapseStyle(true)}>
        <SummaryItem label={total_cases}>{sumCases}</SummaryItem>
        <Spacer multiplier={2} />
        <SummaryItem label={total_pieces}>{sumUnits}</SummaryItem>
        <Spacer multiplier={2} />
        <SummaryItem label={total_order_price}>
          {!simulationRunning ? (
            !simulationDetails ? (
              <InitialPriceValue
                isMinValueMet={isMinValueMet}
                sumPrice={sumPrice}
              />
            ) : (
              <FinalPriceValue simulationFinalPrice={simulationFinalPrice} />
            )
          ) : (
            <div css={calculatingStyle}>
              {calculating}
              <AnimatedDots
                lightDesignToken={'information.subdued'}
                darkDesignToken={'information.default'}
                mediumSize
              />
            </div>
          )}
        </SummaryItem>
        <div css={{ height: 40 }}>
          {!simulationRunning &&
            (!simulationDetails ? (
              <InitialPriceDetails
                sumPrice={sumPrice}
                minimumOrderValue={minimumOrderValue}
                isMinValueMet={isMinValueMet}
              />
            ) : (
              <FinalPriceDetails
                currency={currency}
                isMinValueMet={isMinValueMet}
                simulationFinalPrice={simulationFinalPrice}
                simulationDetails={simulationDetails}
                simulationFinalListPrice={simulationFinalListPrice}
                minimumOrderValue={minimumOrderValue}
              />
            ))}
        </div>
        <div css={actionAlignmentStyle}>
          {children}
          {!simulationRunning && hasValidShipTos && (
            <Typography
              size={'tiny'}
              designToken={'text.informative'}
            >
              {order_submit_disclaimer}
            </Typography>
          )}
          {!hasValidShipTos && (
            <Typography
              size={'tiny'}
              designToken={'error.emphasis'}
            >
              {order_blocked_disclaimer}
            </Typography>
          )}
        </div>
      </div>
    </section>
  );
};

export default CheckoutOrderSummary;
