import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const containerHeaderStyle = (display: boolean, mobileOnlyToggle: boolean) =>
  css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: baseSpacing,
    marginBottom: baseSpacing,
    gap: baseSpacing * 2,
    '> button': {
      width: 'auto',
    },
    [media.medium]: {
      display: display ? 'flex' : 'none',
      justifyContent: 'initial',
      alignItems: 'baseline',
      '> button': {
        display: mobileOnlyToggle ? 'none' : 'block',
      },
    },
  });

const containerHeaderTextStyle = (withBigText: boolean) =>
  css({
    color: designToken.text.default,
    fontSize: withBigText ? font.size.large : font.size.medium,
    fontWeight: withBigText ? font.weight.medium : font.weight.bold,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [media.medium]: {
      fontSize: withBigText ? font.size.large : font.size.medium,
    },
    '> small': {
      display: 'block',
      color: designToken.text.subdued,
      fontSize: '0.7em',
      fontWeight: font.weight.normal,
    },
  });

interface IContainerHeaderProps {
  text: string;
  subText?: string;
  open: boolean;
  setOpen?: (open: boolean) => void;
  display?: boolean;
  withDisplayToggle?: boolean;
  mobileOnlyToggle?: boolean;
  withLargeText?: boolean;
  extraCss?: SerializedStyles;
}

export const ContainerHeader: React.FC<IContainerHeaderProps> = ({
  text,
  subText,
  open,
  setOpen,
  display = true,
  withDisplayToggle = true,
  mobileOnlyToggle = true,
  withLargeText = true,
  extraCss,
}) => {
  const { button_show_more, button_show_less } = useLabels();
  return (
    <div css={[containerHeaderStyle(display, mobileOnlyToggle), extraCss]}>
      <h4 css={containerHeaderTextStyle(withLargeText)}>
        {text}
        {subText && <small>{subText}</small>}
      </h4>
      {withDisplayToggle && setOpen && (
        <Button
          size={'tiny'}
          type={ButtonType.GHOST}
          onClick={() => setOpen(!open)}
        >
          {open ? button_show_less : button_show_more}
        </Button>
      )}
    </div>
  );
};
