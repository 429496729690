import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

import { formCommonInputStyle } from './';

// search input
export const searchInputStyle = [
  formCommonInputStyle,
  css({
    borderRadius: 4,
    boxShadow: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
    paddingRight: 70,
    flexGrow: 1,
    zIndex: 549,
    backgroundColor: designToken.background.blue,
    '&::placeholder': {
      color: designToken.text.subdued,
    },
    [media.medium]: {
      order: -20,
      borderRadius: '4px 0 0 4px',
    },
  }),
];

export const resetFormButtonStyle = css({
  position: 'absolute',
  top: 0,
  right: 0,
  lineHeight: 0,
  border: 'none',
  background: designToken.transparent.default,
  padding: 10,
  order: -10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
  zIndex: 10,
  cursor: 'pointer',
  [media.medium]: {
    padding: 18,
  },
  svg: {
    fill: designToken.text.default,
    width: 20,
    height: 20,
    transitionProperty: 'fill',
    transitionDuration: '150ms',
  },
  '&:hover, &:focus': {
    svg: {
      fill: designToken.interactive.hover,
    },
  },
});

// search result
export const dropdownStyle = css({
  position: 'absolute',
  top: 0,
  paddingTop: baseSpacing * 5,
  borderRadius: 4,
  left: 0,
  right: 0,
  zIndex: 100,
  backgroundColor: colors.white,
  boxShadow: '0 0 10px 2px rgba(0,0,0,0.2)',
  '&:empty': {
    display: 'none',
  },
  [media.medium]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 934,
    paddingTop: baseSpacing * 7,
  },
});

export const feedbackStyle = css({
  fontSize: font.size.small,
  color: designToken.text.default,
  padding: baseSpacing * 2,
  paddingTop: baseSpacing * 2.5,
  '&:empty': {
    display: 'none',
  },
});

// results item
export const productWrapStyle = (isDisabled: boolean) =>
  css({
    cursor: !isDisabled ? 'pointer' : 'default',
    fontSize: font.size.small,
    textAlign: 'left',
    display: 'flex',
    color: isDisabled ? colors.klik.slate500 : colors.klik.lightBlue400,
    padding: baseSpacing,
    marginTop: 0,
    marginRight: baseSpacing,
    marginBottom: 0,
    marginLeft: baseSpacing,
    border: 0,
    background: 'none',
    width: `calc(100% - ${baseSpacing * 2}px)`,
    borderBottomWidth: 1,
    borderBottomColor: colors.klik.slate100,
    borderBottomStyle: 'solid',
    minHeight: baseSpacing * 8,
    textDecoration: 'none',
    outline: 'none',
    scrollSnapAlign: 'start',
    scrollMarginTop: 62,
    position: 'relative',
    '&:first-of-type': {
      paddingTop: baseSpacing * 2,
    },
    '&:last-of-type': {
      borderBottomColor: 'transparent',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: isDisabled ? colors.klik.slate500 : colors.klik.lightBlue400,
      top: 0,
      bottom: -1,
      left: -baseSpacing,
      right: -baseSpacing,
      opacity: 0,
    },

    '&:hover, &:focus': {
      '&:before': {
        opacity: 0.15,
      },
    },
  });

export const productImgWrapperStyle = css({
  width: '20%',
  minWidth: 40,
  maxWidth: 60,
  display: 'flex',
  justifyContent: 'center',
  paddingRight: baseSpacing,
  overflow: 'hidden',
  svg: {
    width: '100%',
    height: 'auto',
  },
  img: {
    maxHeight: 40,
  },
});

export const productDataStyle = css({
  width: '80%',
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: baseSpacing,
});

export const productTextStyle = css({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

export const productNameStyle = css({
  width: '100%',
  marginTop: baseSpacing / 2,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const availabilityStatusStyle = css({
  marginLeft: baseSpacing / 2,
  lineHeight: 1.3,
});
