import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

export const backgroundStyle = css({
  color: colors.white,
  padding: baseSpacing * 3,
  height: 280,
  [media.small]: {
    height: 240,
  },
  [media.medium]: {
    height: 'auto',
  },
});

export const headerStyle = css({
  marginRight: 'auto',
  marginLeft: 'auto',
  fontSize: font.size.normal,
  fontWeight: font.weight.normal,
  marginBottom: baseSpacing / 2,
  [media.small]: {
    maxWidth: 700,
  },
  [media.medium]: {
    width: '90%',
    maxWidth: 934,
  },
});

export const formWrapperStyle = css({
  position: 'relative',
  outline: 0,
  display: 'flex',
  justifyContent: 'center',
  [media.small]: {
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [media.medium]: {
    maxWidth: 934,
  },
});

export const formContentStyle = css({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  [media.medium]: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: baseSpacing * 7,
    backgroundColor: colors.white,
    borderRadius: 4,
    zIndex: 110,
  },
});

export const productFormFieldWrapperStyle = css({
  width: '100%',
  position: 'relative',
  zIndex: 110,
});

export const quantityAndButtonStyle = css({
  marginTop: baseSpacing * 2,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 2,
  backgroundColor: designToken.background.blue,
  [media.small]: {
    display: 'flex',
  },
  [media.medium]: {
    boxShadow: `inset 0px 2px 0px rgba(0, 0, 0, 0.1)`,
    marginTop: 0,
    zIndex: 120,
  },
});

export const snackbarProductNameStyle = css({
  fontWeight: font.weight.bold,
  display: '-webkit-box',
  WebkitLineClamp: '1',
  overflow: 'hidden',
});

export const formCommonInputStyle = css({
  border: 0,
  outline: 0,
  color: designToken.text.default,
  fontSize: font.size.small,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: baseSpacing * 3,
  lineHeight: `${baseSpacing * 2}px`,
  height: baseSpacing * 5,
  backgroundColor: colors.white,
  borderRadius: 4,
  width: '100%',
  [media.medium]: {
    width: '100%',
    height: baseSpacing * 7,
  },
});

export const selectedItemOverlayStyle = [
  formCommonInputStyle,
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingRight: baseSpacing * 3,
    boxShadow: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    lineHeight: `${baseSpacing * 5}px`,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&:focus,&:hover': {
      backgroundColor: designToken.background.blue,
    },
    [media.medium]: {
      lineHeight: `${baseSpacing * 7}px`,
    },
  }),
];

export const nameStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
