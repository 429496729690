import { css } from '@emotion/react';
import { baseSpacing, colors, font, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

import {
  fourColMedia,
  fourColWidth,
  threeColMedia,
  threeColWidth,
  TILE_WIDTH,
  twoColMedia,
  twoColWidth,
} from '../../../../../../constants';

export const wrapperStyle = css({
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  width: '100%',
  background: colors.klik.slate30,
});

export const productListStyle = css({
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: baseSpacing * 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: baseSpacing,
  paddingRight: baseSpacing,
  display: 'grid',
  gap: baseSpacing * 2,
  justifyContent: 'center',
  gridTemplateColumns: `repeat(auto-fill, ${TILE_WIDTH}px)`,
});

export const productListContainerStyle = (loadMoreVisible: boolean) =>
  css({
    marginTop: baseSpacing * 2,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: loadMoreVisible ? 50 : 0,
  });

export const loadButtonMediaAdjustmentStyle = css({
  width: TILE_WIDTH,
  maxWidth: 1420,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  [`@media (min-width: ${twoColMedia}px)`]: {
    width: twoColWidth,
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    width: threeColWidth,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    width: fourColWidth,
  },
});

export const sortingAndSwitchWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const switchWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: baseSpacing * 2,
  marginRight: baseSpacing,
  marginBottom: baseSpacing,
  '> label': {
    flexDirection: 'row-reverse',
    '> input': {
      marginLeft: baseSpacing,
    },
  },
  [media.medium]: {
    flexDirection: 'row',
    gap: baseSpacing * 3,
    margin: 0,
    '> label': {
      flexDirection: 'row',
      '> input': {
        marginLeft: 0,
      },
    },
  },
});

export const sortingContainerStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  height: 40,
  '> div': {
    width: 'auto',
  },
});

export const inputContainerStyle = css({
  position: 'relative',
  marginBottom: baseSpacing * 3,
  width: '100%',
  fontSize: font.size.small,
  fontWeight: font.weight.normal,
  [media.medium]: {
    marginBottom: 0,
  },
});
