import { css } from '@emotion/react';
import { Money } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { totalAmountStyle } from '../styles';

const finalAmountStyle = css(totalAmountStyle, {
  color: designToken.success.default,
});

interface FinalPriceValueProps {
  simulationFinalPrice?: Money;
}
export const FinalPriceValue: React.FC<FinalPriceValueProps> = ({ simulationFinalPrice }) => {
  const selectedCustomer = useSelectedCustomer();

  return (
    <div css={finalAmountStyle}>
      <Price
        locale={selectedCustomer.locale}
        fallbackValue={'0'}
        wrappers={{
          nipPrice: ({ children }) => <p css={{ lineHeight: 1 }}>{children}</p>,
          grossPrice: ({ children }) => <p css={{ lineHeight: 1 }}>{children}</p>,
        }}
      >
        {simulationFinalPrice}
      </Price>
    </div>
  );
};
