import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

import {
  fourColMedia,
  fourColWidth,
  oneColWidth,
  threeColMedia,
  threeColWidth,
  TILE_WIDTH,
  twoColMedia,
  twoColWidth,
} from '../../../../../../constants';
import { commonProductTileStyle } from '../../../../../../styles';

export const THEME_TILE_HEIGHT = 100;
const TILES_ROWS = 2;
const paddingBottom = baseSpacing * 6;
const calculateNumberOfRows = (tilesCount: number, numberOfColumns: number) =>
  Math.ceil(tilesCount / numberOfColumns);
const numberOfRows = (showAll: boolean, numberOfColumns: number, tilesCount?: number) =>
  showAll && tilesCount ? calculateNumberOfRows(tilesCount, numberOfColumns) : TILES_ROWS;

export const adjustedWidthStyle = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: oneColWidth,
  [`@media (min-width: ${twoColMedia}px)`]: {
    width: twoColWidth,
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    width: threeColWidth,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    width: fourColWidth,
  },
});

export const themeTileStyle = (isHighlighted: boolean) =>
  css(commonProductTileStyle, {
    height: THEME_TILE_HEIGHT,
    fontSize: font.size.large,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: font.weight.medium,
    color: designToken.primary.default,
    backgroundColor: tokens.color.core.blue[100],
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isHighlighted ? 1 : 0.35,
    position: 'relative',
    '> img': {
      maxHeight: THEME_TILE_HEIGHT - 24,
      maxWidth: 240,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: tokens.color.core.blue[100],
      opacity: 0,
    },
    '&:hover': {
      color: designToken.interactive.hover,
      opacity: 1,
      transition: 'all 200ms cubic-bezier(0,.73,.55,.55)',

      '> img': {
        transition: 'transform 200ms cubic-bezier(0,.73,.55,.55)',
        transform: 'scale(1.08)',
      },
      '&:after': {
        opacity: 0.1,
        transition: 'opacity 75ms cubic-bezier(0,.73,.55,.55)',
      },
    },
    '&:active': {
      color: designToken.interactive.active,
      transition: 'transform 75ms ease-in-out',
      '> img': {
        transform: 'scale(1.04)',
        transition: 'transform 75ms ease-in-out',
      },
    },
  });

export const backgroundStyle = (showAll: boolean, tilesCount?: number) => {
  const localRowCalculation = (numberOfColumns: number) =>
    numberOfRows(showAll, numberOfColumns, tilesCount);
  const calculateHeightBasedOnColumns = (numberOfColumns: number) =>
    THEME_TILE_HEIGHT * localRowCalculation(numberOfColumns) +
    baseSpacing * 2 * localRowCalculation(numberOfColumns) +
    paddingBottom;
  return css({
    width: '100%',
    height: calculateHeightBasedOnColumns(1),
    paddingTop: baseSpacing * 3,
    background: designToken.background.blue,
    [`@media (min-width: ${twoColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(2),
    },
    [`@media (min-width: ${threeColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(3),
    },
    [`@media (min-width: ${fourColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(4),
    },
  });
};

export const wrapperStyle = (showAll: boolean, tilesCount?: number) => {
  const localRowCalculation = (numberOfColumns: number) =>
    numberOfRows(showAll, numberOfColumns, tilesCount);
  const calculateHeightBasedOnColumns = (numberOfColumns: number) =>
    THEME_TILE_HEIGHT * localRowCalculation(numberOfColumns) +
    baseSpacing * 2 * (localRowCalculation(numberOfColumns) - 1);
  return css(adjustedWidthStyle, {
    height: calculateHeightBasedOnColumns(1),
    display: 'grid',
    gap: baseSpacing * 2,
    justifyContent: 'center',
    gridTemplateColumns: `repeat(auto-fill, ${TILE_WIDTH}px)`,
    overflow: 'hidden',
    [`@media (min-width: ${twoColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(2),
    },
    [`@media (min-width: ${threeColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(3),
    },
    [`@media (min-width: ${fourColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(4),
    },
  });
};

export const buttonWrapperStyle = css(adjustedWidthStyle, {
  '> button': {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
});
