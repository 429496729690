/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NetworkStatus } from '@apollo/client';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  CartReferenceInput,
  PaymentMethod,
} from '@lego/b2b-unicorn-data-access-layer';
import { useCreateOrder, useOptimisticEmptyCart } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  AnimatedDots,
  Button,
  ContentSystemFeedback,
  GoBackLink,
  Spacer,
  Stepper,
  SystemFeedbackType,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { InlineNotification, NotificationType } from '@lego/b2b-unicorn-ui-components';
import { ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import { formatISO9075, isAfter } from 'date-fns';
import { deepEqual } from 'fast-equals';
import Cookies from 'js-cookie';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ContainerHeader } from '../../../../shop/components/ContainerHeader';
import { makeStateWithCookieHook, useCheckoutOperation } from '../../common/hooks';
import {
  actionsStyle,
  bodyStyle,
  formWrapperStyle,
  mobileCollapseStyle,
  pagePaddingDesktopStyle,
  pagePaddingStyle,
  submittingAnimationStyle,
  tableStyle,
} from '../../common/styles';
import { CheckoutStep, ICheckoutCookie, IDeliveryInfo } from '../../common/types';
import { convertDateStringToDate, convertDateStringToDateOrNull } from '../../common/utils';
import { createCheckoutCookieName } from '../../common/utils/createCheckoutCookieName';
import { ensureBusinessDay, todayPlusLeadTime } from '../../common/utils/leadTimeHelper.js';
import OrderRemovedItems from '../OrderRemovedItems';
import CheckoutItemRow from './CheckoutItemRow';
import CheckoutOrderSummary from './PaymentAndDelivery/CheckoutOrderSummary';
import DeliveryInformation from './PaymentAndDelivery/DeliveryInformation';
import OrderName from './PaymentAndDelivery/OrderName';
import { paymentBackgroundStyle } from './PaymentAndDelivery/styles';
import SkeletonCheckoutPage from './SkeletonCheckoutPage';
import TableHeader from './TableHeader';

interface ICheckoutPage {
  goBackHandler: () => void;
  orderCreationSuccessHandler: (orderNumber: number, orderType: CartReferenceCartType) => void;
  cookieDomain: string;
  cartReference: CartReferenceInput;
  onEmptyCart?: () => void;
  onCheckoutLoaded?: () => void;
}

const CheckoutPage: React.FC<ICheckoutPage> = ({
  goBackHandler,
  orderCreationSuccessHandler,
  cookieDomain,
  cartReference,
  onEmptyCart,
  onCheckoutLoaded,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    order_details,
    order_delivery_information,
    order_edit,
    button_submit_order,
    button_submitting,
    content_system_feedback_error,
    snackbar_order_simulation_error,
    snackbar_submit_order_error,
    payment_method_credit_card,
    payment_method_credits_placeholder,
    cart,
    button_checkout,
    confirmation,
    payment,
  } = useLabels();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const [showForm, setShowForm] = useState(true); // Used for mobile
  const [showTable, setShowTable] = useState(true); // Used for mobile
  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>(
    ProductTableColumnType.ITEM_ID
  );
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [emptyCart] = useOptimisticEmptyCart(selectedCustomer.id, cartReference);

  const {
    items,
    removedItems,
    deliveryDates,
    shipTos,
    shippingAddressId,
    setShippingAddressId,
    requestedDeliveryDate: requestedDeliveryDateFromHook,
    setRequestedDeliveryDate,
    simulationLoading,
    simulationError,
    simulationDetails,
    simulationNetworkStatus,
    checkoutLoading,
    checkoutError,
    minimumOrderValue,
    totalSimulationPrice,
    totalListPrice,
  } = useCheckoutOperation(selectedCustomer.id, {
    simulation: true,
    cartReference,
    removeObsoleteItems: cartReference.cartType === CartReferenceCartType.Replenish,
    cookieDomain,
  });

  const creatingOrder = useRef(false);
  const [
    createOrder,
    { error: submittingError, loading: isSubmittingOrder, data: createdOrderDetails },
  ] = useCreateOrder(selectedCustomer.id);

  useEffect(() => {
    if (!submittingError) {
      return;
    }

    creatingOrder.current = false;
  }, [submittingError]);

  const sortedItems = useMemo(() => {
    if (!items || creatingOrder.current) {
      return;
    }

    onCheckoutLoaded && onCheckoutLoaded();

    if (items.length === 0 && onEmptyCart) {
      onEmptyCart();
      return;
    }

    return isAscending
      ? // @ts-ignore - TypeScript fails to infer the type of the items array
        sortAscending(items, activeSortingField)
      : // @ts-ignore - TypeScript fails to infer the type of the items array
        sortDescending(items, activeSortingField);
  }, [activeSortingField, isAscending, items, onEmptyCart, onCheckoutLoaded]);

  const useStateWithCookie = useMemo(() => makeStateWithCookieHook(cookieDomain), [cookieDomain]);
  const checkoutCookieName = createCheckoutCookieName(selectedCustomer.id, cartReference);

  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInfo | undefined>();
  const [hasValidOrderName, setHasValidOrderName] = useState<boolean>(true);
  const [simulationWarning, setSimulationWarning] = useState<boolean>(false);
  const [showRemovedItems, setShowRemovedItems] = useState<boolean>(false);
  const prevRemovedItems = useRef(false);

  useEffect(() => {
    if (showRemovedItems) {
      return;
    }

    if (
      removedItems &&
      removedItems.length > 0 &&
      !deepEqual(removedItems, prevRemovedItems.current)
    ) {
      prevRemovedItems.current = JSON.parse(JSON.stringify(removedItems));
      setShowRemovedItems(true);
    }
  }, [removedItems, showRemovedItems]);

  useEffect(() => {
    if (simulationNetworkStatus === NetworkStatus.refetch || simulationLoading) {
      return;
    }

    if (
      simulationError ||
      (simulationDetails &&
        sortedItems &&
        sortedItems.length > 0 &&
        sortedItems[0] &&
        sortedItems[0].__typename === 'CartItemWithSimulationDetails' &&
        !sortedItems[0].product.price.netInvoicedPrice)
    ) {
      setSimulationWarning(true);
    } else {
      setSimulationWarning(false);
    }
  }, [simulationError, sortedItems, simulationNetworkStatus, simulationLoading, simulationDetails]);

  const [orderName, setOrderName] = useStateWithCookie<ICheckoutCookie, 'orderName'>(
    checkoutCookieName,
    'orderName'
  );

  const leadTime = useMemo(() => {
    if (!shipTos || !shippingAddressId) {
      return null;
    }

    const shipTo = shipTos.find((s) => s.id === shippingAddressId);

    return shipTo?.leadtimeInDays || 0;
  }, [shipTos, shippingAddressId]);

  const firstSelectableDate: Date | undefined = useMemo(() => {
    if (!deliveryDates || leadTime === null) {
      return;
    }

    const resultThatMayBeWeekend = deliveryDates.earliestDate || todayPlusLeadTime(leadTime);

    return ensureBusinessDay(resultThatMayBeWeekend);
  }, [deliveryDates, leadTime]);

  const lastSelectableDate: Date | undefined = useMemo(() => {
    if (!deliveryDates?.latestDate) {
      return;
    }

    return ensureBusinessDay(deliveryDates.latestDate, deliveryDates.latestDate);
  }, [deliveryDates]);

  useEffect(() => {
    if (
      (requestedDeliveryDateFromHook &&
        firstSelectableDate &&
        !isAfter(firstSelectableDate, convertDateStringToDate(requestedDeliveryDateFromHook))) ||
      !firstSelectableDate
    ) {
      return;
    }

    setRequestedDeliveryDate(
      formatISO9075(firstSelectableDate, {
        representation: 'date',
      })
    );
  }, [firstSelectableDate, requestedDeliveryDateFromHook, setRequestedDeliveryDate]);

  const handleShipToOnChange = (shippingId: number) => {
    if (shippingId === shippingAddressId) {
      return;
    }

    setShippingAddressId(shippingId);
  };

  const handleRequestedDeliveryDateChange = (date: Date | null) => {
    if (date && shippingAddressId) {
      setRequestedDeliveryDate(formatISO9075(date, { representation: 'date' }));
    }
  };

  const handleOrderNameChange = (isValid: boolean, value: string) => {
    setOrderName(value);
    setHasValidOrderName(isValid);
  };

  const handleCloseRemovedItemsPopup = () => {
    if (sortedItems?.length === 0) {
      goBackHandler();
    } else {
      setShowRemovedItems(false);
    }
  };

  const handleValidDeliveryInfo = useCallback((info?: IDeliveryInfo) => {
    setDeliveryInfo(info);
  }, []);

  const hasValidShipTos = shipTos && shipTos.length > 0;

  const submitButtonIsDisabled =
    !deliveryInfo ||
    !hasValidShipTos ||
    !hasValidOrderName ||
    simulationLoading ||
    checkoutLoading ||
    isSubmittingOrder;

  useEffect(() => {
    if (createdOrderDetails) {
      Cookies.remove(checkoutCookieName, { domain: cookieDomain });
      orderCreationSuccessHandler(createdOrderDetails.orderNumber, cartReference.cartType);
    }
  }, [
    cartReference.cartType,
    checkoutCookieName,
    cookieDomain,
    createdOrderDetails,
    orderCreationSuccessHandler,
  ]);

  const handleOrderSubmitClick = () => {
    if (shippingAddressId && requestedDeliveryDateFromHook) {
      const requestedDeliveryDate = convertDateStringToDate(requestedDeliveryDateFromHook);
      creatingOrder.current = true;
      if (userPaysWithCard) {
        createOrder(shippingAddressId, requestedDeliveryDate, cartReference, orderName || '');
      } else {
        createOrder(shippingAddressId, requestedDeliveryDate, cartReference, orderName || '').then(
          () => {
            emptyCart();
          }
        );
      }
    }
  };

  // @TODO: We should figure out how to handle Dates from GraphQL in general, as we convert and/or format the string dates into Date objects all the time.
  const deliveryInformationRequestedDeliveryDate = useMemo(
    () => convertDateStringToDateOrNull(requestedDeliveryDateFromHook),
    [requestedDeliveryDateFromHook]
  );

  let checkoutSteps = [CheckoutStep.PREVIEW, CheckoutStep.CHECKOUT, CheckoutStep.CONFIRMATION];
  let checkoutLabels = [cart, button_checkout, confirmation];
  if (userPaysWithCard) {
    checkoutSteps = [
      CheckoutStep.PREVIEW,
      CheckoutStep.CHECKOUT,
      CheckoutStep.PAYMENT,
      CheckoutStep.CONFIRMATION,
    ];
    checkoutLabels = [cart, button_checkout, payment, confirmation];
  }

  const renderCheckout = () => {
    return (
      shipTos &&
      items &&
      !checkoutError && (
        <Fragment>
          {removedItems && showRemovedItems && (
            <OrderRemovedItems
              items={removedItems}
              closePopup={handleCloseRemovedItemsPopup}
            />
          )}
          <div css={pagePaddingStyle}>
            <Spacer multiplier={2} />
            <GoBackLink
              onClick={goBackHandler}
              text={order_edit}
              withBorder
              disabled={simulationLoading}
            />
            <Spacer multiplier={3} />
            <div css={formWrapperStyle}>
              <section css={paymentBackgroundStyle(showForm)}>
                <ContainerHeader
                  text={order_delivery_information}
                  open={showForm}
                  setOpen={setShowForm}
                  withLargeText={false}
                />
                <Spacer multiplier={2} />
                <div css={mobileCollapseStyle(showForm)}>
                  <OrderName
                    value={orderName}
                    onChange={handleOrderNameChange}
                  />
                  <Spacer multiplier={2} />
                  <DeliveryInformation
                    shipTos={shipTos}
                    shippingAddressId={shippingAddressId}
                    shipToOnChangeHandler={handleShipToOnChange}
                    minDate={firstSelectableDate}
                    maxDate={lastSelectableDate}
                    requestedDeliveryDate={deliveryInformationRequestedDeliveryDate}
                    onDateChange={handleRequestedDeliveryDateChange}
                    onValidInfoHandler={handleValidDeliveryInfo}
                    disabledFromOutside={isSubmittingOrder || simulationLoading}
                    excludedDateIntervals={deliveryDates?.rddNotAvailableDates || []}
                  />
                  <Spacer multiplier={2} />
                  <p>
                    {userPaysWithCard ? (
                      <Typography>{payment_method_credit_card}</Typography>
                    ) : (
                      <Typography>{payment_method_credits_placeholder}</Typography>
                    )}
                  </p>
                </div>
              </section>
              <CheckoutOrderSummary
                hasValidShipTos={!!hasValidShipTos}
                cartItems={items}
                minimumOrderValue={minimumOrderValue}
                simulationRunning={simulationLoading}
                simulationDetails={simulationDetails}
                simulationFinalListPrice={totalListPrice}
                simulationFinalPrice={totalSimulationPrice}
              >
                <div css={actionsStyle}>
                  <Button
                    disabled={submitButtonIsDisabled}
                    onClick={handleOrderSubmitClick}
                    warning={!!submittingError}
                  >
                    {isSubmittingOrder ? (
                      <div css={submittingAnimationStyle}>
                        {userPaysWithCard ? payment : button_submitting}
                        &nbsp;
                        <AnimatedDots />
                      </div>
                    ) : userPaysWithCard ? (
                      payment
                    ) : (
                      button_submit_order
                    )}
                  </Button>
                  {submittingError && (
                    <InlineNotification
                      message={snackbar_submit_order_error}
                      type={NotificationType.WARNING}
                      outlineIcon
                    />
                  )}
                  {simulationWarning && (
                    <InlineNotification
                      message={snackbar_order_simulation_error}
                      type={NotificationType.WARNING}
                      outlineIcon
                    />
                  )}
                </div>
              </CheckoutOrderSummary>
              <Spacer multiplier={2} />
            </div>
          </div>
          <Spacer multiplier={6} />
          <div css={pagePaddingDesktopStyle}>
            <ContainerHeader
              text={order_details}
              open={showTable}
              setOpen={setShowTable}
              withLargeText={false}
            />
            {sortedItems && sortedItems.length > 0 && (
              <>
                <table css={tableStyle(showTable)}>
                  <TableHeader
                    activeSortingField={activeSortingField}
                    setActiveSortingField={setActiveSortingField}
                    isAscending={isAscending}
                    setIsAscending={setIsAscending}
                  />
                  <tbody>
                    {sortedItems.map((item) => {
                      const expectedDeliveryDate =
                        item.__typename === 'CartItemWithSimulationDetails'
                          ? item.expectedDeliveryDate
                          : undefined;
                      const simulationPrice =
                        item.__typename === 'CartItemWithSimulationDetails'
                          ? item.product.price
                          : undefined;
                      /*
                       * For Launch orders we don't want expected delivery date to be compared to requested delivery date,
                       * as we do not want to highlight it as later than requested delivery date
                       */
                      const requestedDeliveryDate =
                        cartReference.cartType === CartReferenceCartType.Replenish
                          ? requestedDeliveryDateFromHook
                          : undefined;

                      return (
                        <CheckoutItemRow
                          key={item.product.materialId}
                          product={item.product}
                          quantity={item.quantity}
                          simulationRunning={simulationLoading}
                          requestedDeliveryDate={requestedDeliveryDate}
                          expectedDeliveryDate={expectedDeliveryDate}
                          simulationPricePerPiece={simulationPrice}
                          isLaunchProduct={cartReference.cartType === CartReferenceCartType.Launch}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Spacer />
              </>
            )}
            {(!sortedItems || sortedItems.length === 0) &&
              !simulationLoading &&
              !checkoutLoading && (
                <div css={css({ '> div': { marginTop: 0 } })}>
                  <ContentSystemFeedback
                    type={SystemFeedbackType.ERROR}
                    text={content_system_feedback_error}
                  >
                    <GoBackLink
                      onClick={goBackHandler}
                      text={order_edit}
                    />
                  </ContentSystemFeedback>
                </div>
              )}
          </div>
        </Fragment>
      )
    );
  };

  return (
    <div css={bodyStyle}>
      <>
        <Stepper
          activeStep={CheckoutStep.CHECKOUT}
          steps={checkoutSteps}
          stepLabels={checkoutLabels}
        />
        {checkoutLoading && !checkoutError && <SkeletonCheckoutPage />}
        {renderCheckout()}
        {checkoutError && (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={content_system_feedback_error}
          >
            <GoBackLink
              onClick={goBackHandler}
              text={order_edit}
            />
          </ContentSystemFeedback>
        )}
      </>
    </div>
  );
};

export default CheckoutPage;
