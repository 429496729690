import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

type LaunchWindowsClearAllProps = {
  onCleared: () => void;
  windows: Array<{ year: number; month: number }>;
};
export const LaunchWindowsClearAll: React.FC<LaunchWindowsClearAllProps> = ({
  onCleared,
  windows,
}) => {
  const { id: customerId } = useSelectedCustomer();
  const dataAccessLayer = useDataAccessLayer();

  const handleClearAllClick = () => {
    const loaderId = GlobalLoadingService.add('clearAll');
    const mutationPromises = windows.reduce<Promise<unknown>[]>((promises, window) => {
      const [mutate] = dataAccessLayer.launchWindows.emptyLaunchWindow(
        customerId,
        window.year,
        window.month
      );
      promises.push(mutate());

      return promises;
    }, [] as Promise<void>[]);

    Promise.all(mutationPromises).finally(() => {
      GlobalLoadingService.remove(loaderId);
      onCleared();
    });
  };

  return (
    <Button
      type={ButtonType.SECONDARY}
      size={'small'}
      onClick={handleClearAllClick}
    >
      Clear all
    </Button>
  );
};
