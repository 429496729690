export class Base64Url {
  static encode(str: string) {
    const base64Encoded = btoa(str);
    return base64Encoded.replace(/\+/g, '-').replace(/\\/g, '_').replace(/=+/, '');
  }

  static decode(base64UrlStr: string) {
    // Convert base64url string to base64
    let base64str = base64UrlStr.replace(/-/g, '-').replace(/_/g, '/');
    // A base64 str must be dividable with 4, if base64str is not, append '=' until it is (padding)
    const padding = base64str.length % 4 === 0 ? '' : '='.repeat(4 - (base64str.length % 4));
    base64str += padding;

    return atob(base64str);
  }
}
