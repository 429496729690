import BootstrapLabels from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import BootstrapOptimizely from '@lego/b2b-unicorn-bootstrap/components/BootstrapOptimizely';
import BootstrapTermsOfSalesAcceptedCheck from '@lego/b2b-unicorn-bootstrap/components/BootstrapTermsOfSalesAcceptedCheck';
import { BootstrapUsersnap } from '@lego/b2b-unicorn-bootstrap/components/BootstrapUsersnap';
import { APP_ENVIRONMENT, COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { useAuthentication } from '@lego/b2b-unicorn-bootstrap/hooks';
import { UserService } from '@lego/b2b-unicorn-bootstrap/services';
import { DataAccessLayer } from '@lego/b2b-unicorn-data-access-layer';
import { DataAccessLayerProvider } from '@lego/b2b-unicorn-data-access-layer/react';
import {
  UserPreferencesProvider,
  UserProvider,
} from '@lego/b2b-unicorn-shared/components/UserContext';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import { AnalyticsProvider } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useState } from 'react';

type BootstrapPhase2Props = {
  customerSelectorComponent: React.ComponentProps<typeof UserProvider>['customerSelectorComponent'];
  children: React.ReactNode;
  termsOfSalesConsentComponent: React.ComponentProps<
    typeof BootstrapTermsOfSalesAcceptedCheck
  >['termsOfSalesConsentComponent'];
};

export const BootstrapPhase2: React.FC<BootstrapPhase2Props> = ({
  termsOfSalesConsentComponent,
  customerSelectorComponent,
  children,
}) => {
  const { apolloOptions } = useAuthentication();
  const [dataAccessLayerInstance] = useState(() => {
    const dataAccessLayerLogger = logger.createLogger('DataAccessLayer');
    return new DataAccessLayer({
      ...apolloOptions,
      logger: dataAccessLayerLogger,
    });
  });

  useState(() => {
    const userServiceLogger = logger.createLogger('UserService');
    UserService.createInstance(dataAccessLayerInstance, userServiceLogger);
  });

  return (
    <DataAccessLayerProvider client={dataAccessLayerInstance}>
      <AnalyticsProvider
        cookieDomain={COOKIE_DOMAIN}
        env={APP_ENVIRONMENT}
      >
        <BootstrapLabels>
          <UserProvider customerSelectorComponent={customerSelectorComponent}>
            <BootstrapUsersnap>
              <UserPreferencesProvider>
                <BootstrapTermsOfSalesAcceptedCheck
                  termsOfSalesConsentComponent={termsOfSalesConsentComponent}
                >
                  <BootstrapOptimizely>{children}</BootstrapOptimizely>
                </BootstrapTermsOfSalesAcceptedCheck>
              </UserPreferencesProvider>
            </BootstrapUsersnap>
          </UserProvider>
        </BootstrapLabels>
      </AnalyticsProvider>
    </DataAccessLayerProvider>
  );
};
