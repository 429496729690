import { css } from '@emotion/react';
import { designToken, Typography } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const summaryItemStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: baseSpacing,
  color: designToken.text.default,
  marginTop: 0,
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  height: 30,
});

interface SummaryItemProps {
  children: React.ReactNode;
  label: string;
}

export const SummaryItem: React.FC<SummaryItemProps> = ({ children, label }) => {
  return (
    <div css={summaryItemStyle}>
      <Typography
        size={'small'}
        weight={'normal'}
      >
        {label}
      </Typography>
      <Typography
        size={'medium'}
        weight={'bold'}
      >
        {children}
      </Typography>
    </div>
  );
};
