import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Button,
  ButtonType,
  Card,
  Container,
  Icon,
  IconType,
  Paragraph,
  Spacer,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment, useMemo, useState } from 'react';

import { usePromotionDetails } from '../hooks/usePromotionDetails';

type PromotionConditionsSummaryProps = {
  promotionConditions: NonNullable<ReturnType<typeof usePromotionDetails>['productConditions']>;
  promotionData: NonNullable<ReturnType<typeof usePromotionDetails>['promotionData']>;
};
export const PromotionConditionsSummary: React.FC<PromotionConditionsSummaryProps> = ({
  promotionConditions,
  promotionData,
}) => {
  const {
    promotions_conditions_header,
    product,
    promotions_conditions_minimum,
    button_show_details,
    button_hide_details,
  } = useLabels();

  const [open, setOpen] = useState(true);

  const promotionConditionsWithProductDetails = useMemo(() => {
    const products = promotionData.promotion.products;

    return promotionConditions.map((condition) => {
      const foundProduct = products.find((product) => product.materialId === condition?.materialId);
      return {
        ...condition,
        product: foundProduct,
      };
    });
  }, [promotionData, promotionConditions]);

  return promotionConditionsWithProductDetails?.length ? (
    <Card
      padding={{
        left: baseSpacing * 2,
        right: baseSpacing * 2,
        top: baseSpacing,
        bottom: baseSpacing * 2,
      }}
      backgroundColor={'background.gray'}
      borderType={'solid'}
      borderColor={'border.information'}
      height={'max-content'}
    >
      <Container
        flex={{ flexDirection: 'row', alignItems: 'center', gap: baseSpacing / 2 }}
        paddingMedium={{
          [media.medium]: { paddingRight: baseSpacing },
        }}
      >
        <Typography
          size={'large'}
          designToken={'text.informative'}
        >
          <Icon type={IconType.INFO_OUTLINE} />
        </Typography>
        <Typography
          weight={'medium'}
          designToken={'text.informative'}
          size={'small'}
        >
          {promotions_conditions_header}
        </Typography>
      </Container>
      {open && (
        <Container
          padding={{
            paddingTop: baseSpacing / 2,
          }}
          paddingMedium={{
            [media.medium]: {
              paddingRight: baseSpacing,
              paddingLeft: baseSpacing,
            },
          }}
        >
          {promotionConditionsWithProductDetails.map((condition, index) => (
            <Fragment key={index}>
              <Paragraph
                fontWeight={'normal'}
                designToken={'text.muted'}
                fontSize={'small'}
              >
                <Container
                  flex={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: baseSpacing / 2,
                  }}
                >
                  <div>
                    {product}{' '}
                    <Typography weight={'bold'}>
                      {condition.product?.__typename === 'Box'
                        ? condition.product.itemNumber
                        : condition.materialId}{' '}
                    </Typography>
                  </div>
                  <div>
                    {promotions_conditions_minimum}:{' '}
                    <Typography weight={'bold'}>{condition?.minimumCasePacks}</Typography>
                  </div>
                </Container>
              </Paragraph>
              <Spacer multiplier={1} />
            </Fragment>
          ))}
        </Container>
      )}
      {promotionConditionsWithProductDetails.length > 2 && (
        <Button
          type={ButtonType.GHOST}
          size={'tiny'}
          onClick={() => setOpen(!open)}
        >
          {open ? button_hide_details : button_show_details}
        </Button>
      )}
    </Card>
  ) : null;
};
