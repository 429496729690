import React, { Fragment } from 'react';

import { ExtractElementType } from '../../helpers';
import { Icon, IconType } from '../Icon';
import {
  activeDashStyle,
  activeStepsItemStyle,
  dashStyle,
  defaultStepsItemStyle,
  finishedStepsItemStyle,
  stepsContainerStyle,
} from './styles';

type StepperProps<Steps extends Array<unknown>> = {
  /*
   * Array of Checkout steps to show, the order determines in which order they should be shown
   */
  steps: Steps;
  /*
   * Array of labels to show for each step, the order of the labels must match the order of the steps Array
   */
  stepLabels: string[];
  activeStep: ExtractElementType<Steps>;
};

function Stepper<Steps extends Array<unknown>>(props: StepperProps<Steps>) {
  const { activeStep, steps, stepLabels } = props;

  const activeStepPosition = steps.indexOf(activeStep);
  if (activeStepPosition === -1) {
    throw Error(`activeStep '${activeStep}' not included in step`);
  }

  return (
    <div css={stepsContainerStyle}>
      {steps.map((step, i) => {
        const stepNumber = i + 1;
        let containerCss = defaultStepsItemStyle;
        if (activeStep === step) {
          containerCss = activeStepsItemStyle;
        } else if (i < activeStepPosition) {
          containerCss = finishedStepsItemStyle;
        }

        let dashCss = activeDashStyle;
        if (activeStep === step || i > activeStepPosition) {
          dashCss = dashStyle;
        }
        return (
          <Fragment key={`${step}-${i}`}>
            <div css={containerCss}>
              <span>
                {activeStep === step || i > activeStepPosition ? (
                  `${stepNumber}`
                ) : (
                  <Icon type={IconType.CONTROL_CHECK} />
                )}
              </span>
              <span>{stepLabels[i]}</span>
            </div>
            {stepNumber !== steps.length && <span css={dashCss}></span>}
          </Fragment>
        );
      })}
    </div>
  );
}

export default Stepper;
