import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Money } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { font } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const warningStyle = css({
  color: designToken.warning.default,
  fontSize: font.size.tiny,
  fontWeight: font.weight.bold,
  margin: 0,
});

interface MinOrderValueWarningProps {
  minimumOrderValue: Money;
  isMinValueMet: boolean;
}

export const MinOrderValueWarning: React.FC<MinOrderValueWarningProps> = ({
  minimumOrderValue,
  isMinValueMet,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const { total_order_price_minimum_value } = useLabels();

  return !isMinValueMet ? (
    <p css={warningStyle}>
      {total_order_price_minimum_value + ' '}
      <Price
        locale={selectedCustomer.locale}
        fallbackValue={''}
      >
        {minimumOrderValue}
      </Price>
    </p>
  ) : null;
};
