import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React, { ReactElement, useCallback, useContext } from 'react';

import { colors } from '../theme';
import { PopoverOpenContext, PopoverRefContext } from './Popover';

const popoverTriggerStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  border: 0,
  background: 'none',
  outline: 0,
  cursor: 'pointer',
  transitionProperty: 'color, fill',
  transitionDuration: '150ms',
  color: colors.klik.slate10,
  '> div div svg': {
    transitionProperty: 'color, fill',
    transitionDuration: '150ms',
  },
  '&:hover, &:focus': {
    color: tokens.color.core.azur['100'],
    svg: {
      fill: tokens.color.core.azur['100'],
    },
  },
});

type PopoverTriggerProps = {
  children: ReactElement | string;
};
export const PopoverTrigger: React.FC<PopoverTriggerProps> = ({ children }) => {
  const { triggerRef } = useContext(PopoverRefContext);
  const { setPopoverOpen, popoverOpen, setFocusedElementBeforeOpen } =
    useContext(PopoverOpenContext);

  const handleOnMouseDown = useCallback(() => {
    const activeElement = document.activeElement;
    setFocusedElementBeforeOpen(activeElement);
  }, [setFocusedElementBeforeOpen]);

  return (
    <button
      ref={triggerRef}
      onClick={() => setPopoverOpen(!popoverOpen)}
      css={popoverTriggerStyle}
      onMouseDown={handleOnMouseDown}
    >
      {children}
    </button>
  );
};
