import { css } from '@emotion/react';
import { Spacer, ToolTip, ToolTipDirection, Typography } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

interface IInfoWithAdditionalLinesProps {
  /** Headline for popup dialog */
  headline: string;
  /** All info lines. First will be shown initially. All will be in popup dialog. */
  lines: string[] | React.ReactElement[];
}

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'inherit',
  h3: {
    marginTop: baseSpacing / 2,
    marginBottom: baseSpacing * 2,
  },
  p: {
    marginTop: baseSpacing / 2,
    marginBottom: baseSpacing / 2,
    marginLeft: baseSpacing / 2,
  },
});

const popupOpenerCircleStyle = css({
  width: 24,
  height: 24,
  backgroundColor: colors.klik.lightBlue400,
  color: colors.white,
  display: 'inline-block',
  lineHeight: '24px',
  textAlign: 'center',
  fontSize: '11px',
  borderRadius: '50%',
  marginLeft: baseSpacing,
});

const InfoWithAdditionalLines = ({ headline, lines }: IInfoWithAdditionalLinesProps) => {
  return (
    <div css={containerStyle}>
      {lines[0]}
      {lines.length > 1 && (
        <ToolTip
          direction={ToolTipDirection.Down}
          message={
            <>
              <Typography weight={'medium'}>{headline}:</Typography>
              <Spacer multiplier={2} />
              {lines.map((line, idx) => (
                <p key={line + '-' + idx}>{line}</p>
              ))}
            </>
          }
        >
          <span css={popupOpenerCircleStyle}>+{lines.length - 1}</span>
        </ToolTip>
      )}
    </div>
  );
};

export default InfoWithAdditionalLines;
