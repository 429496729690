import '@lego/b2b-unicorn-shared/ui/web-components/lego-logo';

import { useFooter } from '@lego/b2b-unicorn-data-access-layer/react';
import { ContentstackLink } from '@lego/b2b-unicorn-shared/components/ContentstackLink';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Spacer } from '@lego/b2b-unicorn-shared/ui';
import { FunctionComponent } from 'react';

import FooterSection from './FooterSection';
import {
  containerStyle,
  contentStyle,
  footerStyle,
  legalLinkListStyle,
  legalsStyle,
  linkStyle,
  listStyle,
} from './footerStyles';

export const Footer: FunctionComponent = () => {
  const { id } = useSelectedCustomer();
  const { data: footerContent } = useFooter(id);

  return id && footerContent?.getFooter ? (
    <footer css={footerStyle}>
      <div css={containerStyle}>
        <lego-logo
          width={48}
          height={48}
        ></lego-logo>
        <Spacer multiplier={2} />
        <div css={contentStyle}>
          {footerContent.getFooter.legalInformation && (
            <div css={legalsStyle}>
              <p>
                {footerContent.getFooter.legalInformation.bottomText}
                <a
                  css={linkStyle}
                  href={footerContent.getFooter.legalInformation?.reachLink?.url || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {footerContent.getFooter.legalInformation?.reachLink?.title || ''}
                </a>
              </p>
              {footerContent.getFooter.legalInformation.pricingDisclaimer && (
                <p>{footerContent.getFooter.legalInformation.pricingDisclaimer}</p>
              )}
              <div css={legalLinkListStyle}>
                {footerContent.getFooter.legalInformation.links.map((link) => {
                  return (
                    <li key={link.title}>
                      <ContentstackLink
                        key={link.title}
                        url={link.url || ''}
                        title={link.title}
                        assetUid={link.assetUid || ''}
                      />
                    </li>
                  );
                })}
              </div>
              <p>{footerContent.getFooter.legalInformation.topText}</p>
            </div>
          )}

          {footerContent.getFooter.sections && (
            <ul css={listStyle}>
              {footerContent.getFooter.sections.map((section) => {
                return (
                  <FooterSection
                    title={section.title}
                    key={section.title}
                  >
                    {section.items.map((item) => {
                      return (
                        <ContentstackLink
                          key={item.title}
                          url={item.url || ''}
                          title={item.title}
                          assetUid={item.assetUid || ''}
                        />
                      );
                    })}
                  </FooterSection>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </footer>
  ) : null;
};
