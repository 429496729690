import { css, keyframes } from '@emotion/react';
import { designToken, Icon, IconType, Spinner } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { topbarButtonStyle } from '../../styles';

const cartWrapperStyle = css({
  height: 32,
  display: 'flex',
  alignItems: 'center',
  color: designToken.text.inverse,
  '&:hover, &:focus': {
    outline: 'none',
    color: tokens.color.core.azur['100'],
    transitionDuration: '150ms',
    '> div': {
      outline: 'none',
    },
  },
});

const cartStyle = css({
  ...topbarButtonStyle,
  marginRight: 0,
  position: 'relative',
  boxShadow: 'none',
  '> svg': {
    height: 20,
    width: 20,
    transform: 'translate(-1px, 1px)',
  },
});

const amountWrapperStyle = css({
  paddingLeft: baseSpacing,
  display: 'none',
  fontWeight: font.weight.medium,
  fontSize: font.size.small,
  height: 33,
  textAlign: 'left',
  '> div:last-of-type': {
    fontWeight: font.weight.medium,
  },
  [media.medium]: {
    display: 'block',
  },
});

const cartLabelStyle = css({
  fontSize: 11,
});

const border = keyframes`
    0% {
        background-color: ${designToken.transparent.default}
    }
    50% {
        background-color: ${designToken.success.default}
    }
    100% {
        background-color: ${designToken.transparent.default}
    }
`;

const cartAnimationStyle = css({
  animationName: border,
  animationDuration: '1000ms',
  animationIterationCount: 1,
  animationTimingFunction: 'cubic-bezier(.6,0,.99,.65))',
});

interface ICartIndicatorProps {
  cartUpdated: boolean;
  hasProductsInCart: boolean;
  price: React.ReactNode;
  translations: {
    cart: string;
  };

  onAnimationEnd: () => void;
  onClick: () => void;
  isLoading: boolean;
}

const CartIndicator = ({
  cartUpdated,
  hasProductsInCart,
  price,
  translations,
  onAnimationEnd,
  onClick,
  isLoading,
}: ICartIndicatorProps) => {
  return (
    <button
      css={cartWrapperStyle}
      onClick={onClick}
    >
      <div
        css={[cartStyle, cartUpdated && cartAnimationStyle]}
        onAnimationEnd={onAnimationEnd}
      >
        {isLoading && <Spinner color={designToken.white} />}
        {!isLoading && (
          <>
            {' '}
            {hasProductsInCart ? (
              <Icon type={IconType.SHOPPING_CART} />
            ) : (
              <Icon type={IconType.SHOPPING_CART_OUTLINE} />
            )}
          </>
        )}
      </div>
      {!isLoading && hasProductsInCart && translations && price && (
        <div css={amountWrapperStyle}>
          <div css={cartLabelStyle}>{translations.cart}</div>
          <div>{price}</div>
        </div>
      )}
    </button>
  );
};

export default CartIndicator;
